import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Grid } from '@material-ui/core';

import {
  createDocumentSupportAction,
} from '../../../actions/documentSupportAction';
import { cleanListReteRenta } from '../../../actions/invoiceActions';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import { validateDocument } from '../../../helpers/documentValidate';
import {
  generateRequestSupport,
} from '../../../helpers/generateRequestDocument';

const ButtonsCart = ({
  handleChangeStep,
  setActiveStep,
  readErrorSendInvoice,
  readResponseSendDocumentSupport,
  setLoading,
}) => {
  const dispatch = useDispatch();
  const { documentData, favorite } = useSelector(
    (state) => state.documentSupportReducer
  );

  const totals = useSelector((state) => state.documentCartReducer);

  const handleSaveDocument = () => {
    if (validateDocument(totals, totals?.cartDocumentSupport)) {
      setLoading(true);
      let document = generateRequestSupport(totals, documentData, favorite);
      dispatch(
        createDocumentSupportAction(
          document,
          readResponseSendDocumentSupport,
          readErrorSendInvoice,
          setActiveStep
        )
      );

      dispatch(cleanListReteRenta());
    }
    // ConfirmAlert(
    // 	`¿Estás seguro de guardar y enviar la factura?`,
    // 	deleteData,
    // 	MESSAGE_NEXT_CONFIRM
    // );
  };

  return (
    <Grid container style={{ marginTop: 20 }}>
      <Grid item xl={11} lg={12} sm={12} xs={12}>
        <Grid container spacing={1} justify='flex-end'>
          <Grid item lg={6} xs={12}>
            <ButtonPrimary
              type='button'
              onClick={() => handleChangeStep(2)}
              text={'Revisar Documento'}
            />
          </Grid>
          <Grid item lg={6} xs={12}>
            <ButtonPrimary
              type='button'
              onClick={handleSaveDocument}
              text={'Enviar Documento'}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ButtonsCart;
