import { types } from '../types/types';

const initialState = {
  invoices: [],
  sucess: {},
  document: {},
  create: {},
  resend: {},
  createquotation: {},
  aditionaldataenterprise: {
    advendedor: [],
    adsucursalesempresa: [],
    adnumeracion: [],
    adobservaciones: [],
  },
  sendmail: {},
  showCatalog: true,
  totaldocs: [],
  quotation: {},
  reasons: [],
  file: {},
  openModalItem: false,
  licenseBase: null,
  informe: null,
  showInvoiceCreationStatus: false,
  loadingGetdocuments: false,
  informationBulkLoad: null,
  responseResult: false,
  stepChange: 0,
  showBulk: true,
  totaldocuments: 0,
  loadingGetDetail: false,
  loadingUpdateStatus: false,
  loadingUpdateFavourite: false,
  loadingFavouriteFromList: 0,
  loadingDeleteDocument: false,
  documentsReferenceInvoice: [],
  getChangeState: {},
  validateInformation: null,
  resultData: {},
  typeNote: '',
  loadingGetNoteId: 0,
  loadingReportDocument: [],
  listReteFuente: [],
  listReteRenta: [],
  listReteICA: [],
  listReteIVA: [],
  stateBulkLoad: null,
  loadingGetReasons: false,
  defaultFilter: null,
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_INVOICES:
      return {
        ...state,
        invoices: action.payload,
        totaldocuments: action.payload1,
      };
    case types.SUCESS_DOCUMENT_UPDATE:
      return {
        ...state,
        sucess: action.payload,
      };

    case types.GET_DOCUMENT:
      return {
        ...state,
        document: action.payload,
      };
    case types.CLEAN_DOCUMENT_DETAIL:
      return {
        ...state,
        document: {},
      };

    case types.SUCESS_DOCUMENT_CREATE:
      return {
        ...state,
        create: action.payload,
      };

    case types.RESEND_ELECTRONIC_INVOICE:
      return {
        ...state,
        resend: action.payload,
      };

    case types.SUCESS_QUOTATION_CREATE:
      return {
        ...state,
        createquotation: action.payload,
      };

    case types.SUCESS_SEND_INVOICE:
      return {
        ...state,
        sendmail: action.payload,
      };

    case types.GET_ADITIONAL_DATA_INVOICE:
      return {
        ...state,
        aditionaldataenterprise: action.payload,
      };
    case types.CLEAN_ADITIONAL_DATA_INVOICE:
      return {
        ...state,
        aditionaldataenterprise: {
          advendedor: [],
          adsucursalesempresa: [],
          adnumeracion: [],
          adobservaciones: [],
        },
      };
    case types.GET_TOTAL_DOCUMENTS:
      return {
        ...state,
        totaldocs: action.payload,
      };
    case types.GET_QUOTATION:
      return {
        ...state,
        quotation: action.payload,
      };
    case types.GET_REASONS_NOTES:
      return {
        ...state,
        reasons: action.payload,
      };
    case types.LOADING_REASONS_NOTES:
      return {
        ...state,
        loadingGetReasons: action.payload,
      };
    case types.GET_FILE_INVOICE:
      return {
        ...state,
        file: action.payload,
      };
    case types.ACTIVE_STEP_INVOICE:
      return {
        ...state,
        activeStepInvoice: action.payload,
      };
    case types.INFO_DOCUMENT_CREATE:
      return {
        ...state,
        openModalItem: action.payload,
      };
    case types.INFO_LICENSE_BASE:
      return {
        ...state,
        licenseBase: action.payload,
      };
    case types.GET_INFORME:
      return {
        ...state,
        informe: action.payload,
      };
    case types.SHOW_INVOICE_CREATION:
      return {
        ...state,
        showInvoiceCreationStatus: action.payload,
      };
    case types.LOADING_GET_DOCUMENTS:
      return {
        ...state,
        loadingGetdocuments: action.payload,
      };
    case types.GET_LIST_RETEFUENTE:
      return {
        ...state,
        listReteFuente: action.payload,
      };
    case types.GET_LIST_RETERENTA:
      return {
        ...state,
        listReteRenta: action.payload,
      };
    case types.ADD_CUSTOM_RETERENTA:
      return {
        ...state,
        listReteRenta: [action.payload, ...state.listReteRenta],
      };
    case types.CLEAN_LIST_RETERENTA:
      return {
        ...state,
        listReteRenta: [],
      };
    case types.GET_LIST_RETEICA:
      return {
        ...state,
        listReteICA: action.payload,
      };

    case types.GET_LIST_RETEIVA:
      return {
        ...state,
        listReteIVA: action.payload,
      };
    case types.GET_VALIDATION_BULK_LOAD:
      return {
        ...state,
        validateInformation: action.payload,
      };

    case types.SAVE_REGISTER_BULK_LOAD:
      return {
        ...state,
        saveRegister: action.payload,
      };
    case types.GET_INFORMATION_BULK_LOAD:
      return {
        ...state,
        informationBulkLoad: action.payload,
      };

    case types.STATUS_GET_INFORMATION_BULK_LOAD:
      return {
        ...state,
        statusGetBulk: action.payload,
      };

    case types.UPDATE_STATE_BULK:
      return {
        ...state,
        stateBulkLoad: action.payload,
        statusUpdate: action.payload2,
        resultUpdate: action.payload3,
      };
    case types.CHANGE_STEP_BULK:
      return {
        ...state,
        stepChange: action.payload,
      };
    case types.INFORMATION_INVOICE_BULK:
      return {
        ...state,
        invoiceBulk: action.payload,
      };
    case types.SHOW_BULK: {
      return {
        ...state,
        showBulk: action.payload,
      };
    }
    case types.LOADING_GET_DETAIL_DOCUMENT:
      return {
        ...state,
        loadingGetDetail: action.payload,
      };

    case types.LOADING_UPDATE_STATUS_DOCUMENT:
      return {
        ...state,
        loadingUpdateStatus: action.payload,
      };
    case types.LOADING_UPDATE_FAVOURITE_DOCUMENT:
      return {
        ...state,
        loadingUpdateFavourite: action.payload,
      };
    case types.LOADING_UPDATE_FROM_LIST:
      return {
        ...state,
        loadingFavouriteFromList: action.payload,
      };
    case types.LOADING_DELETE_DOCUMENT:
      return {
        ...state,
        loadingDeleteDocument: action.payload,
      };
    case types.GET_DOCUMENTS_REFERENCE:
      return {
        ...state,
        documentsReferenceInvoice: action.payload,
      };
    case types.GET_CHANGE_STATE:
      return {
        ...state,
        getChangeState: action.payload,
      };
    case types.GET_RESULT_SIGNAL:
      return {
        ...state,
        resultData: action.payload,
      };
    case types.SET_TYPE_NOTE:
      return {
        ...state,
        typeNote: action.payload,
      };
    case types.LOADING_GET_NOTE_DOCUMENT:
      return {
        ...state,
        loadingGetNoteId: action.payload,
      };
    case types.LOADING_REPORT_DOCUMENT:
      return {
        ...state,
        loadingReportDocument: action.payload,
      };
    case types.GET_DATA_EMAIL_REPORT:
      return {
        ...state,
        dataEmailReport: action.payload,
      };
    case types.SET_DEFAULT_FILTER:
      return {
        ...state,
        defaultFilter: action.payload,
      };
    default:
      return state;
  }
}
