import { types } from '../types/types';

const initialState = {
  menu: [],
  menuComponent: [],
  getCitiesStateResponse: [],
  responseSendReport: false,
  allCitiesCountry: [],
  allPostalCodes: [],
  allUvt: [],
  getPostalCodesState: [],
  stateDepartament: [],
  getTemplate: null,
  loadingTemplateCustomer: false,
  flagConnection: true,
  connectionSignal: null,
  reasonList: [],
  getStatesResponse: [],
  getCountriesResponse: [],
  taxList: [],
  taxIvaList: [],
  taxIncList: [],
  optionsIbuaList: [],
  optionsIcuiList: [],
  optionsIclList: [],
  optionsInppList: [],
  optionsAdvList: [],
  getCurrencyList: [],
  getSupportDocumentType: [],
  getDocumentTypesResponse: [],
  getDocumentTypesCodeResponse: [],
  getDocumentTypesCodeReceptionResponse: [],
  getRegimeTypesResponse: [],
  getTaxResponsibility: [],
  getFiscalResponsibility: [],
  requestTransmissionType: [],
  frequentQuestion: [],
  issuesList: [],
  typesDocumentReference: [],
  getPaymentMethodsResponse: [],
  defaultPaymentMethods: [],
  othersPaymentMethods: [],
  typesHealthServices: [],
  typesCoverage: [],
  modalitiesContracting: [],
  typesIdentificationHealth: [],
  typesUserHealth: [],
  typesEquivalentDocument: [],
};

export default function(state = initialState, action) {
  switch (action.type) {
    case types.GET_COUNTRIES:
      return {
        ...state,
        getCountriesResponse: action.payload,
      };
    case types.GET_STATES:
      return {
        ...state,
        getStatesResponse: action.payload,
      };
    case types.GET_CITIES_STATE:
      return {
        ...state,
        getCitiesStateResponse: action.payload,
      };
    case types.GET_DOCUMENT_TYPES:
      return {
        ...state,
        getDocumentTypesResponse: action.payload,
      };
    case types.GET_DOCUMENT_TYPES_CODE:
      return {
        ...state,
        getDocumentTypesCodeResponse: action.payload,
      };
    case types.GET_DOCUMENT_TYPES_CODE_RECEPTION:
      return {
        ...state,
        getDocumentTypesCodeReceptionResponse: action.payload,
      };
    case types.GET_REGIME_TYPES:
      return {
        ...state,
        getRegimeTypesResponse: action.payload,
      };
    case types.GET_TAX_RESPONSIBILITY_TYPES:
      return {
        ...state,
        getTaxResponsibility: action.payload,
      };
    case types.GET_FISCAL_RESPONSIBILITY_TYPES:
      return {
        ...state,
        getFiscalResponsibility: action.payload,
      };
    case types.GET_MENU:
      return {
        ...state,
        menu: action.payload,
      };
    case types.GET_COMPONENTS:
      return {
        ...state,
        components: action.payload,
      };
    case types.GET_PAYMENT_METHODS:
      return {
        ...state,
        getPaymentMethodsResponse: action.payload,
      };
    case types.GET_CITIES_COUNTRY:
      return {
        ...state,
        allCitiesCountry: action.payload,
      };
    case types.GET_POSTAL_CODES:
      return {
        ...state,
        allPostalCodes: action.payload,
      };
    case types.GET_UVT:
      return {
        ...state,
        allUvt: action.payload,
      };
    case types.GET_POSTAL_CODES_STATE:
      return {
        ...state,
        getPostalCodesState: action.payload,
      };
    case types.GET_MENU_COMPONENT:
      return {
        ...state,
        menuComponent: !!action.payload ? action.payload : [],
      };
    case types.GET_FREQUENT_QUESTIONS:
      return {
        ...state,
        frequentQuestion: action.payload,
      };

    case types.GET_SECURITY_POLICY:
      return {
        ...state,
        policy: action.payload,
      };

    case types.GET_QUESTION_FILTER:
      return {
        ...state,
        getFilterQuestion: action.payload,
      };
    case types.GET_REPORT_PROBLEM:
      return {
        ...state,
        dataIssue: action.payload,
        issue: action.payload1,
        fullName: action.payload2,
        emailUserProblem: action.payload3,
        idUser: action.payload4,
        businessName: action.payload5,
        nitCompany: action.payload6,
      };

    case types.GET_REASON:
      return {
        ...state,
        reasonData: action.payload,
        data: action.payload1,
        emailUser: action.payload2,
      };
    case types.SEND_MAIL:
      return {
        ...state,
        responseSendReportProblem: action.payload,
      };
    case types.SEND_MAIL_CONTACT:
      return {
        ...state,
        responseDataMailContact: action.payload,
      };
    case types.GET_TEMPLATE_BULK_LOAD_CLIENT:
      return {
        ...state,
        getTemplate: action.payload,
      };
    case types.LOADING_GET_TEMPLATE_CUSTOMER:
      return {
        ...state,
        loadingTemplateCustomer: action.payload,
      };
    case types.GET_TEMPLATE_BULK_LOAD:
      return {
        ...state,
        getTemplate: action.payload,
      };
    case types.GET_TYPE_DOCUMENT_REFERENCE:
      return {
        ...state,
        typesDocumentReference: action.payload,
      };
    case types.GET_CONNECTION_SIGNAL:
      return {
        ...state,
        connectionSignal: action.payload,
      };
    case types.GET_ISSUES_LIST:
      return {
        ...state,
        issuesList: action.payload,
      };
    case types.CHANGE_FLAG_CONNECTION:
      return {
        ...state,
        flagConnection: action.payload,
      };

    case types.DEFAULT_PAYMENT_METHODS:
      return {
        ...state,
        defaultPaymentMethods: action.payload,
      };
    case types.OTHERS_PAYMENT_METHODS:
      return {
        ...state,
        othersPaymentMethods: action.payload,
      };
    case types.GET_REASON_LIST:
      return {
        ...state,
        reasonList: action.payload,
      };
    case types.GET_TAX_LIST:
      return {
        ...state,
        taxList: action.payload,
      };
    case types.GET_IVA_LIST:
      return {
        ...state,
        taxIvaList: action.payload,
      };
    case types.GET_INC_LIST:
      return {
        ...state,
        taxIncList: action.payload,
      };
    case types.GET_IBUA_LIST:
      return {
        ...state,
        optionsIbuaList: action.payload,
      };
    case types.GET_ICUI_LIST:
      return {
        ...state,
        optionsIcuiList: action.payload,
      };
    case types.GET_ICL_LIST:
      return {
        ...state,
        optionsIclList: action.payload,
      };
    case types.GET_INPP_LIST:
      return {
        ...state,
        optionsInppList: action.payload,
      };
    case types.GET_ADV_LIST:
      return {
        ...state,
        optionsAdvList: action.payload,
      };
    case types.GET_TYPES_HEALTH_SERVICE:
      return {
        ...state,
        typesHealthServices: action.payload,
      };
    case types.GET_TYPES_COVERAGE:
      return {
        ...state,
        typesCoverage: action.payload,
      };
    case types.GET_MODALITIES_CONTRACTING:
      return {
        ...state,
        modalitiesContracting: action.payload,
      };
    case types.GET_TYPES_IDENTIFICATION_HEALTH:
      return {
        ...state,
        typesIdentificationHealth: action.payload,
      };
    case types.GET_TYPES_USER_HEALTH:
      return {
        ...state,
        typesUserHealth: action.payload,
      };
    case types.GET_TYPES_SUPPORT_DOCUMENT:
      return {
        ...state,
        getSupportDocumentType: action.payload,
      };
    case types.CLEAN_CODEPOSTAL_STATE:
      return {
        ...state,
        getPostalCodesState: [],
      };
    case types.CLEAN_CITIES_STATE:
      return {
        ...state,
        getCitiesStateResponse: [],
      };
    case types.TYPES_EQUIVALENT_DOCUMENT:
      return {
        ...state,
        typesEquivalentDocument: action.payload,
      };
    case types.GET_REQUEST_TRANSMISSION_TYPE:
      return {
        ...state,
        requestTransmissionType: action.payload,
      };
    case types.GET_CURRENCY_EXCHANGE_LIST:
      return {
        ...state,
        getCurrencyList: action.payload,
      };
    default:
      return state;
  }
}
