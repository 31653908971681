/* eslint-disable array-callback-return */
import { axiosApiInstance } from '../config/axios-instance';
import {
  API_ENDPOINT_CONFIGURATION,
  defaultHeaders,
} from '../config/config';
import { errorToast } from '../helpers/toast.helpers';
import { types } from '../types/types';
import { filterByIdState } from '../utils/filterByIdState';
import {
  businessName,
  emailUser,
  fullNameUser,
  nitCompany,
  roleUser,
} from './authActions';

const urlConfiguration = `${API_ENDPOINT_CONFIGURATION}/config/api`;

/**
 * Obtiene listado de departamentos por identificador de pais,
 * valida si ya se ha consultado información de api, evitando rescosultas inecesarias
 */
export const getStatesAction = () => async (dispatch, getState) => {
  try {
    if (getState().configReducer.getStatesResponse.length > 0) return;

    const url = `${urlConfiguration}${"/DaneDepartamento/GetDepartamentoByPaisId"}`;
    let config = defaultHeaders();
    Object.assign(config.headers, {
      paisid: 1,
    });

    var res = await axiosApiInstance.get(url, config);

    let statesList = res.data.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion.toUpperCase(),
    }));

    dispatch({
      type: types.GET_STATES,
      payload: statesList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de departamentos.",
        error: err,
      },
    });
  }
};

/**
 * * Obtiene listado de Paises por identificador de pais,
 * valida si ya se ha consultado información de api, evitando rescosultas inecesarias
 */
export const getCountriesAction = () => async (dispatch, getState) => {
  try {
    const { getCountriesResponse } = getState().configReducer;
    if (getCountriesResponse.length > 0) return;

    const url = `${urlConfiguration}/DianPais`;
    const res = await axiosApiInstance.get(url, defaultHeaders());

    const contriesFilterActive = filterByIdState(res.data, 1);

    const countriesList = contriesFilterActive.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion.toUpperCase(),
    }));

    dispatch({
      type: types.GET_COUNTRIES,
      payload: countriesList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de paises.",
        error: err,
      },
    });
  }
};

/**
 * Obtiene listado de ciudades de un pais
 */
export const getAllCitiesAction = () => async (dispatch, getState) => {
  try {
    const { allCitiesCountry } = getState().configReducer;
    if (allCitiesCountry.length > 0) return;

    const url = `${urlConfiguration}${"/DaneCiudad"}`;
    var res = await axiosApiInstance.get(url, defaultHeaders());

    let cities = res.data.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion.toUpperCase(),
      departamentoid: item.departamentoid,
    }));

    dispatch({
      type: types.GET_CITIES_COUNTRY,
      payload: cities,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de ciudades.",
        error: err,
      },
    });
  }
};

export const getCities = (departamentoid) => async (dispatch, getState) => {
  try {
    const { allCitiesCountry } = getState().configReducer;
    if (allCitiesCountry.length > 0) {
      dispatch({
        type: types.GET_CITIES_STATE,
        payload: allCitiesCountry.filter(
          (c) => c.departamentoid === parseInt(departamentoid)
        ),
      });
    } else {
      const url = `${urlConfiguration}${"/DaneCiudad"}`;
      var res = await axiosApiInstance.get(url, defaultHeaders());

      let cities = res.data.map((item) => ({
        ...item,
        value: item.id.toString(),
        text: item.descripcion,
        departamentoid: item.departamentoid,
      }));

      dispatch({
        type: types.GET_CITIES_COUNTRY,
        payload: cities,
      });

      dispatch({
        type: types.GET_CITIES_STATE,
        payload: cities.filter(
          (c) => c.departamentoid === parseInt(departamentoid)
        ),
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de ciudades.",
        error: err,
      },
    });
  }
};

/**
 * *Action para obtener todos los codigos postales
 * *filtra por ciudadid
 * @param {*} ciudadid
 */

export const getPostalCode = (ciudadid) => async (dispatch, getState) => {
  try {
    const { allPostalCodes } = getState().configReducer;
    if (allPostalCodes.length > 0) {
      dispatch({
        type: types.GET_POSTAL_CODES_STATE,
        payload: allPostalCodes.filter(
          (c) => c.ciudadid === parseInt(ciudadid)
        ),
      });
    } else {
      const url = `${urlConfiguration}${"/Danecodigopostal/GetPostalCode"}`;
      var res = await axiosApiInstance.get(url, defaultHeaders());
      let postalCodes = res.data.result.map((item) => ({
        ...item,
        value: item.id.toString(),
        text: `${item.nombremunicipio} (${item.codigopostal})`,
        ciudadid: item.ciudadid,
      }));

      dispatch({
        type: types.GET_POSTAL_CODES,
        payload: postalCodes,
      });

      dispatch({
        type: types.GET_POSTAL_CODES_STATE,
        payload: postalCodes.filter((c) => c.ciudadid === parseInt(ciudadid)),
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de Codigos Postales.",
        error: err,
      },
    });
  }
};

/**
 * *Action para obtener todos los UVT desde el 2020
 */
export const getUvtAction = () => async (dispatch, getState) => {
  try {
    const { allUvt } = getState().configReducer;
    if (allUvt.length > 0) return;

    const url = `${urlConfiguration}${"/Danecodigopostal/GetUVT"}`;
    var res = await axiosApiInstance.get(url, defaultHeaders());
    let uvt = res.data.result.map((item) => ({
      anio: item.anio,
      valor: item.valor,
    }));

    dispatch({
      type: types.GET_UVT,
      payload: uvt,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado de UVT.",
        error: err,
      },
    });
  }
};

/**
 * Consulta listado tipos de identificación disponibles,
 * sobrecarga consulta API
 */
export const getDocumentTypesAction = () => async (dispatch, getState) => {
  const { getDocumentTypesResponse } = getState().configReducer;
  if (getDocumentTypesResponse.length > 0) return;

  const url = `${urlConfiguration}${"/DianTipDocIdentidad/documentType"}`;
  await axiosApiInstance
    .get(url, defaultHeaders())
    .then((res) => {
      let documentList = res.data.map((item) => ({
        ...item,
        value: item.id.toString(),
        text: item.descripcion,
      }));

      dispatch({
        type: types.GET_DOCUMENT_TYPES,
        payload: documentList,
      });

      let documentListCode = documentList.map((item) => ({
        value: item.codigo,
        text: item.descripcion,
      }));

      let documentListCertificate = documentListCode.filter(
        (item) =>
          item.value === "13" || item.value === "22" || item.value === "41"
      );

      dispatch({
        type: types.GET_DOCUMENT_TYPES_CODE,
        payload: documentListCertificate,
      });

      documentListCode = documentListCode.filter((d) => d.value !== "41");

      dispatch({
        type: types.GET_DOCUMENT_TYPES_CODE_RECEPTION,
        payload: documentListCode,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado tipos de identificación.",
          error: err,
        },
      });
    });
};

export const getRegimeTypesAction = () => async (dispatch, getState) => {
  const { getRegimeTypesResponse } = getState().configReducer;
  if (getRegimeTypesResponse.length > 0) return;

  const url = `${urlConfiguration}${"/DianTipoRegimen"}`;
  await axiosApiInstance
    .get(url, defaultHeaders())
    .then((res) => {
      let regimeTypeList = res.data
        .filter((item) => item.id !== 5)
        .map((item) => ({
          ...item,
          value: item.id.toString(),
          text: item.descripcion,
        }));

      dispatch({
        type: types.GET_REGIME_TYPES,
        payload: regimeTypeList,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado tipos de regimen.",
          error: err,
        },
      });
    });
};

export const getTaxResponsibilityAction = () => async (dispatch, getState) => {
  const { getTaxResponsibility } = getState().configReducer;
  if (getTaxResponsibility.length > 0) return;

  const url = `${urlConfiguration}${"/GetResponsabilidadTributaria"}`;
  await axiosApiInstance
    .get(url, defaultHeaders())
    .then((res) => {
      let taxResponsibilityList = res.data.result
        .filter((item) => item.id !== 5 && item.idstate !== 1)
        .map((item) => ({
          ...item,
          value: item.id.toString(),
          text: item.codigo + " - " + item.descripcion,
        }));

      dispatch({
        type: types.GET_TAX_RESPONSIBILITY_TYPES,
        payload: taxResponsibilityList,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message:
            "Error consultando listado tipos de Responsabilidad Tributaria.",
          error: err,
        },
      });
    });
};

export const getFiscalResponsibilityAction = () => async (
  dispatch,
  getState
) => {
  const { getFiscalResponsibility } = getState().configReducer;
  if (getFiscalResponsibility.length > 0) return;

  const url = `${urlConfiguration}${"/GetResponsabilidadFiscal"}`;
  await axiosApiInstance
    .get(url, defaultHeaders())
    .then((res) => {
      let fiscalResponsibilityList = res.data.result
        .filter((item) => item.id !== 6 && item.idstate !== 1)
        .map((item) => ({
          ...item,
          value: item.id.toString(),
          text: item.codigo + " - " + item.descripcion,
        }));

      dispatch({
        type: types.GET_FISCAL_RESPONSIBILITY_TYPES,
        payload: fiscalResponsibilityList,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado tipos de Responsabilidad Fiscal.",
          error: err,
        },
      });
    });
};

export const getMenusAction = () => (dispatch) => {
  axiosApiInstance
    .get(`${urlConfiguration}${"/menu"}`, defaultHeaders())
    .then((response) => {
      dispatch({
        type: types.GET_MENU,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado menus configurables.",
          error: err,
        },
      });
    });
};

export const getComponentsAction = (id) => (dispatch, getState) => {
  const { menu } = getState().configReducer;
  const components = menu.find((element) => element.id === id);
  if (!!components) {
    dispatch({
      type: types.GET_COMPONENTS,
      payload: components.adcomponente,
    });
  }
};

/**
 * Consulta información de menus configurables y filtra por componente
 * @param {*} id Identificador de componente
 */
export const getMenuComponentAction = (id) => async (dispatch, getState) => {
  try {
    const { menuComponent } = getState().configReducer;
    if (menuComponent.length > 0) {
      let component = menuComponent.find((e) => e.id === id)?.adcomponente;
      dispatch({
        type: types.GET_MENU_COMPONENT,
        payload: component,
      });
    } else {
      const response = await axiosApiInstance.get(
        `${urlConfiguration}${"/menu"}`,
        defaultHeaders()
      );

      const component = response.data.find((e) => e.id === id)?.adcomponente;
      dispatch({
        type: types.GET_MENU_COMPONENT,
        payload: component,
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Error consultando listado menus configurables, por componente.",
        error: err,
      },
    });
  }
};

export const cleanMenuComponentAction = () => async (dispatch) => {
  dispatch({
    type: types.GET_MENU_COMPONENT,
    payload: [],
  });
};

export const ClearGetComponentsAction = () => (dispatch) => {
  dispatch({
    type: types.GET_COMPONENTS,
    payload: [],
  });
};

/**
 * Consulta listado metodos de pago DIAN
 * @returns
 */
export const getPaymentMethodsAction = () => async (dispatch, getState) => {
  const { getPaymentMethodsResponse } = getState().configReducer;
  if (getPaymentMethodsResponse.length > 0) return;

  const url = `${urlConfiguration}${"/DianMedioPago"}`;
  await axiosApiInstance
    .get(url, defaultHeaders())
    .then((res) => {
      //Items diferentes a borrados
      let methodsList = res?.data?.filter((c) => c.idstate !== 2) ?? [];
      methodsList = methodsList.map((item) => ({
        ...item,
        value: item.id,
        text: item.descripcion,
        defecto: item.defecto,
      }));

      dispatch({
        type: types.GET_PAYMENT_METHODS,
        payload: methodsList,
      });

      dispatch({
        type: types.DEFAULT_PAYMENT_METHODS,
        payload: methodsList.filter((c) => c.defecto === true),
      });

      dispatch({
        type: types.OTHERS_PAYMENT_METHODS,
        payload: methodsList.filter((c) => c.defecto !== true),
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado medios de pago.",
          error: err,
        },
      });
    });
};

export const filterByPlace = (ciudadid, filter) => async (getState) => {
  const { stateList, allCitiesCountry } = getState().configReducer;
  let city = allCitiesCountry.find((city) => city.id === ciudadid);
  let state = !!city
    ? stateList.find(
        (state) => Number.parseInt(state.value) === city.departamentoid
      )
    : null;
  let result = -1;
  if (!!city && result < 0)
    result = city.descripcion.toLowerCase().indexOf(filter);
  if (!!state && result < 0) result = state.text.toLowerCase().indexOf(filter);
  return result;
};

export const getFrequentQuestions = () => (dispatch, getState) => {
  const { frequentQuestion } = getState().configReducer;
  if (frequentQuestion.length > 0) return;

  axiosApiInstance
    .get(`${urlConfiguration}${"/menu/faq"}`, defaultHeaders())
    .then((response) => {
      dispatch({
        type: types.GET_FREQUENT_QUESTIONS,
        payload: response.data.result,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado de preguntas frecuentes.",
          error: err,
        },
      });
    });
};

export const getReportProblem = () => (dispatch, getState) => {
  const { issuesList } = getState().configReducer;
  let data = issuesList.map((item) => ({
    ...item,
    value: item.id,
    text: item.descripcion,
  }));

  dispatch({
    type: types.GET_REPORT_PROBLEM,
    payload: data,
    payload1: issuesList,
    payload2: fullNameUser(),
    payload3: emailUser(),
    payload4: roleUser(),
    payload5: businessName(),
    payload6: nitCompany(),
  });
};

export const getreason = () => async (dispatch, getState) => {
  const { reasonList } = getState().configReducer;
  let reasonData = reasonList.map((item) => ({
    ...item,
    value: item.id,
    text: item.descripcion,
  }));

  dispatch({
    type: types.GET_REASON,
    payload: reasonData,
    payload1: reasonList,
    payload2: emailUser(),
  });
};

export const getSecurityPolicy = () => (dispatch) => {
  axiosApiInstance
    .get(`${urlConfiguration}${"/menu/securityPoli"}`, defaultHeaders())
    .then((response) => {
      let policy = "";

      // eslint-disable-next-line array-callback-return
      response.data.result.map((item) => {
        policy = item.description;
      });

      dispatch({
        type: types.GET_SECURITY_POLICY,
        payload: policy,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando listado de preguntas frecuentes.",
          error: err,
        },
      });
    });
};

export const sendMailReport = (body, value) => (dispatch) => {
  axiosApiInstance
    .post(`${urlConfiguration}${"/menu/send"}`, body, defaultHeaders())
    .then((response) => {
      dispatch({
        type: types.SEND_MAIL,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message:
            "No hemos podido enviar el correo electrónico, por favor contacta al administrador.",
          error: err,
        },
      });
    });
};

export const sendMailContact = (body) => (dispatch) => {
  axiosApiInstance
    .post(`${urlConfiguration}${"/menu/send"}`, body, defaultHeaders())
    .then((response) => {
      dispatch({
        type: types.SEND_MAIL_CONTACT,
        payload: response.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: types.showError,
        payload: {
          message:
            "No se ha podido enviar el correo , par la sección de contactenos.",
          error: err,
        },
      });
    });
};

export const getFrequentQuestionsFilter = (filter = "") => (
  dispatch,
  getState
) => {
  const { frequentQuestion } = getState().configReducer;
  dispatch({
    type: types.GET_QUESTION_FILTER,
    payload: filerList(frequentQuestion, filter),
  });
};

function filerList(list, filter) {
  try {
    if (!list) return list;

    const data = list.filter((x) => {
      const question = x.question.toLowerCase();
      const answer = x.answer.toLowerCase();
      const query = `${question} ${answer}`;
      const modValue = filter.toLowerCase();
      return query.indexOf(modValue) > -1;
    });
    return data;
  } catch (err) {
    console.log("Error filtrando el listado de preguntas frecuentes:", err);
  }
}

/**
 * Obtiene listado asuntos y razones reporte incidencia
 * @returns
 */
export const getIssueAction = () => async (dispatch, getState) => {
  try {
    const { issuesList } = getState().configReducer;
    if (issuesList.length > 0) return;

    const response = await axiosApiInstance.get(
      `${urlConfiguration}${"/menu/issue"}`,
      defaultHeaders()
    );
    const dataIssue = response.data.result.filter((c) => c.tipo === 1);
    const dataReason = response.data.result.filter((c) => c.tipo !== 1);
    dispatch({
      type: types.GET_ISSUES_LIST,
      payload: dataIssue,
    });

    dispatch({
      type: types.GET_REASON_LIST,
      payload: dataReason,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Ha ocurrido un error consultando listado asuntos.",
        error: err,
      },
    });
  }
};

/**
 * Obtiene la plantilla para cargue masivo
 */
export const getTemplateBulkUploadAction = () => async (dispatch) => {
  try {
    const url = `${urlConfiguration}${"/menu/GetTemplateLoad"}`;

    var response = await axiosApiInstance.get(url, defaultHeaders());

    if (response.status === 200) {
      dispatch({
        type: types.GET_TEMPLATE_BULK_LOAD,
        payload: response.data.result.downloadRoute,
      });
    } else {
      dispatch({
        type: types.showError,
        payload: {
          message: "Error consultando la plantilla de cargue masivo.",
          error: { response },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando la plantilla de cargue masivo.",
        error: err,
      },
    });
  }
};

export const cleanTemplateAction = () => (dispatch) => {
  dispatch({
    type: types.GET_TEMPLATE_BULK_LOAD,
    payload: null,
  });
};

/*
 * Obtiene la plantilla para cargue masivo de clientes
 */
export const getTemplateBulkUploadClientAction = (completeDonwload) => async (
  dispatch
) => {
  try {
    changeStatusLoadingGetTemplate(true, dispatch);
    const url = `${urlConfiguration}${"/Menu/GetTemplateLoadClient"}`;
    var response = await axiosApiInstance.get(url, defaultHeaders());

    if (response.status === 200) {
      dispatch({
        type: types.GET_TEMPLATE_BULK_LOAD_CLIENT,
        payload: response.data.result.downloadRoute,
      });

      if (!!completeDonwload)
        completeDonwload(response.data.result.downloadRoute);
    } else {
      dispatch({
        type: types.showError,
        payload: {
          message:
            "Error consultando la plantilla de cargue masivo de clientes.",
          error: { response },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando la plantilla de cargue masivo de clientes.",
        error: err,
      },
    });
  } finally {
    changeStatusLoadingGetTemplate(false, dispatch);
  }
};

const changeStatusLoadingGetTemplate = (status, dispatch) => {
  dispatch({
    type: types.LOADING_GET_TEMPLATE_CUSTOMER,
    payload: status,
  });
};

/**
 * Consulta tipo documento por Id,
 */
export const getDocumentTypeEntepriseByIdAction = (idDocumentType) => async (
  dispatch
) => {
  const url = `${urlConfiguration}${"/DianTipDocIdentidad/GetById"}`;
  let config = defaultHeaders();
  Object.assign(config.headers, {
    pId: idDocumentType,
  });

  try {
    var response = await axiosApiInstance.get(url, config);
    if (response.status === 200) {
      dispatch({
        type: types.GET_DOCUMENT_TYPE_INFO,
        payload: response.data,
      });
    } else {
      dispatch({
        type: types.showError,
        payload: {
          message: "No se encontró el tipo de documento",
          error: { response },
        },
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando tipo de documento.",
        error: err,
      },
    });
  }
};

/**
 * Consulta listado tipos documentos referencia
 * @returns
 */
export const getTypeDocumentReferenceAction = () => async (
  dispatch,
  getState
) => {
  try {
    const { typesDocumentReference } = getState().configReducer;
    if (typesDocumentReference.length > 0) return;

    const url = `${urlConfiguration}${"/TipoDocumentoReferencia"}`;
    const response = await axiosApiInstance.get(url, defaultHeaders());
    const typesList = response?.data?.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion,
    }));

    dispatch({
      type: types.GET_TYPE_DOCUMENT_REFERENCE,
      payload: typesList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando tipos documento referencia.",
        error: err,
      },
    });
  }
};

/**
 * Conexión de Signal R
 * @param {*} connection
 * @returns
 */
export const connectionSignalAction = (connection) => async (dispatch) => {
  dispatch({
    type: types.GET_CONNECTION_SIGNAL,
    payload: connection,
  });
};

/**
 * Cambiar estado de bandera de conexxion de Signal R
 */
export const changeStateFlagAction = (flag) => async (dispatch) => {
  dispatch({
    type: types.CHANGE_FLAG_CONNECTION,
    payload: flag,
  });
};

/**
 *
 * @returns OBtine listado valor impuestos autorizados DIAN
 */
export const getTaxDianAction = () => async (dispatch, getState) => {
  try {
    const { taxIvaList, taxIncList } = getState().configReducer;

    if (taxIvaList.length === 0 || taxIncList.length === 0) {
      const resp = await axiosApiInstance.get(
        `${urlConfiguration}${"/DianPorcentajeImpuesto"}`,
        defaultHeaders()
      );

      const listAll = resp?.data?.map((item) => {
        return {
          ...item,
          value: item.valor,
          text: item.descripcion,
        };
      });

      dispatch({
        type: types.GET_TAX_LIST,
        payload: listAll,
      });

      dispatch({
        type: types.GET_IVA_LIST,
        payload: listAll.filter((c) => c.tipo === "IVA"),
      });

      dispatch({
        type: types.GET_INC_LIST,
        payload: listAll.filter((c) => c.tipo === "INC"),
      });

      dispatch({
        type: types.GET_IBUA_LIST,
        payload: listAll.filter((c) => c.tipo === "IBUA" && c.idstate === 0),
      });

      dispatch({
        type: types.GET_ICUI_LIST,
        payload: listAll.filter((c) => c.tipo === "ICUI"),
      });

      dispatch({
        type: types.GET_ICL_LIST,
        payload: listAll.filter((c) => c.tipo === "ICL"),
      });

      dispatch({
        type: types.GET_INPP_LIST,
        payload: listAll.filter((c) => c.tipo === "INPP"),
      });
      dispatch({
        type: types.GET_ADV_LIST,
        payload: listAll.filter((c) => c.tipo === "ADV"),
      });
    }
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando listado impuestos DIAN.",
        error: err,
      },
    });
  }
};

/**
 * Consulta listado tipos prestadores de servicios de salud
 * @returns
 */
export const getTypesHealthServiceAction = () => async (dispatch) => {
  try {
    const resp = await axiosApiInstance.get(
      `${urlConfiguration}${"/TipoServicioSalud"}`,
      defaultHeaders()
    );
    dispatch({
      type: types.GET_TYPES_HEALTH_SERVICE,
      payload: resp.data ?? [],
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Error consultando listado tipos prestadores de servicios de salud",
        error: err,
      },
    });
  }
};

/**
 * Consulta catalogos para sector salud
 * @returns
 */
export const getHealthCatalogAction = () => async (dispatch, getState) => {
  try {
    const { typesCoverage } = getState().configReducer;
    if (typesCoverage.length > 0) {
      return;
    }

    const resp = await axiosApiInstance.get(
      `${urlConfiguration}${"/CatalogoSalud/GetHealthCatalog"}`,
      defaultHeaders()
    );

    let catalog = resp?.data?.result ?? [];
    catalog = catalog.map((item) => ({
      ...item,
      value: item.id,
      text: item.descripcion,
    }));

    dispatch({
      type: types.GET_TYPES_COVERAGE,
      payload: catalog.filter((c) => c.tipo === "Tipo cobertura"),
    });

    dispatch({
      type: types.GET_MODALITIES_CONTRACTING,
      payload: catalog.filter((c) => c.tipo === "Modalidad contratacion"),
    });

    dispatch({
      type: types.GET_TYPES_IDENTIFICATION_HEALTH,
      payload: catalog.filter((c) => c.tipo === "Tipo identificacion"),
    });

    dispatch({
      type: types.GET_TYPES_USER_HEALTH,
      payload: catalog.filter((c) => c.tipo === "Tipo usuario"),
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando catalogo sector salud.",
        error: err,
      },
    });
  }
};

/**
 * *Obtiene listado de Documentos  de indentificación para  nuevo DOcumento Soporte,
 * *valida si ya se ha consultado información de api, evitando reconsultas inecesarias.
 */
export const getSupportDocumentTypeAction = () => async (
  dispatch,
  getState
) => {
  try {
    if (getState().configReducer.getSupportDocumentType.length > 0) return;

    const url = `${urlConfiguration}/DianTipoDocumentoProveedor/GetTypeDocumentList`;
    const { data } = await axiosApiInstance.get(url, defaultHeaders());

    let statesList = data.result.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion.toUpperCase(),
    }));
    dispatch({
      type: types.GET_TYPES_SUPPORT_DOCUMENT,
      payload: statesList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message:
          "Error consultando tipos de documento de identificación soporte.",
        error: err,
      },
    });
  }
};

export const cleanDataConfigAction = () => (dispatch) => {
  dispatch({
    type: types.CLEAN_CODEPOSTAL_STATE,
  });

  dispatch({
    type: types.CLEAN_CITIES_STATE,
  });
};

/**
 * * Obtiene el tipo de documento equivalente.
 * @function getEquivalentDocumentTypeAction
 * @async
 * @param {Function} dispatch - La función de despacho de Redux.
 * @param {Function} getState - La función para obtener el estado actual de Redux.
 * @returns {Promise<void>}
 * @throws {Error} - Si ocurre un error al consultar los tipos de documento equivalente.
 */
export const getEquivalentDocumentTypeAction = () => async (
  dispatch,
  getState
) => {
  try {
    if (getState().configReducer.typesEquivalentDocument.length > 0) return;

    const url = `${urlConfiguration}/menu/GetTipoDocumentoEquivalente`;
    const { data } = await axiosApiInstance.get(url, defaultHeaders());

    let statesList = data.result.map((item) => ({
      ...item,
      value: item.id.toString(),
      text: item.descripcion.toUpperCase(),
    }));
    dispatch({
      type: types.TYPES_EQUIVALENT_DOCUMENT,
      payload: statesList,
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "Error consultando tipos de documento equivalente.",
        error: err,
      },
    });
  }
};

/**
 * * Obtiene el tipo de solicitud de transmisión de documento soporte.
 * @returns
 */
export const getRequestTransmissionType = () => async (dispatch) => {
  try {
    const response = await axiosApiInstance(
      `${urlConfiguration}/DianTipoTransmision`,
      defaultHeaders()
    );

    const requestTransmission = response?.data?.map((req) => {
      return {
        ...req,
        value: req.id,
        text: req.descripcion,
      };
    });

    dispatch({
      type: types.GET_REQUEST_TRANSMISSION_TYPE,
      payload: requestTransmission ?? [],
    });
  } catch (err) {
    errorToast(
      "Upss!!",
      "Ha ocurrido un error consultando los tipos de transmisión de documento soporte."
    );
    console.log("errorRequestTransmisionDS", err);
  }
};

export const getCurrencyExchangeList = () => async (dispatch) => {
  try {
    const response = await axiosApiInstance.get(
      `${urlConfiguration}/Divisas/GetDivisas`,
      defaultHeaders()
    );

    const currencyList = response?.data?.map((item) => ({
      ...item,
      value: item.codigo,
      text: item.descripion,
    }));

    dispatch({
      type: types.GET_CURRENCY_EXCHANGE_LIST,
      payload: currencyList ?? [],
    });
  } catch (err) {
    dispatch({
      type: types.showError,
      payload: {
        message: "No se ha podido consultar listado de divisas.",
        error: err,
      },
    });
  }
};
