import React from 'react';

import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import { makeStyles } from '@material-ui/core/styles';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';

import { FormatDecimal } from '../../../components/common/formatDecimal';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.11)',
    color: theme.palette.primaryColor,
    fontSize: 16,
    '& .MuiCardContent-root': {
      paddingTop: 0,
      paddingBottom: 5,
      marginBottom: 0,
      marginTop: -5,
      paddingLeft: 55,
    },
  },
  header: {
    paddingBottom: 0,
    paddingTop: 5,
    marginLeft: -5,
    '& .MuiCardHeader-action': {
      display: 'flex',
    },
    '& .MuiCardHeader-avatar': {
      margin: 'auto',
      padding: '10px 13px 9px 4px',
    },
    '& .MuiCardHeader-root': {
      padding: 10,
    },
  },
  content: {
    fontSize: '0.9rem',
  },
  title: {
    color: theme.palette.primaryColor,
    fontSize: '1rem',
    fontWeight: 700,
    lineHeight: '20.08px',
  },
  icon: {
    color: theme.palette.secundaryColor,
  },
  currencyValue: {
    color: theme.palette.primaryColor,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '17.57px',
    letterSpacing: '0px',
    paddingTop: '3px',
  },
  detailRow: {
    width: '95%',
    display: 'flex',
    padding: '8px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    borderRadius: 4,
    borderWidth: 1,
    border: '1px solid #FFFFFF',
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15.06px',
    letterSpacing: '0px',
  },
}));

const DetailRow = ({ label, value, prefix = '$', isText = false }) => {
  const classes = useStyles();

  return (
    <div className={classes.detailRow}>
      <span>{label}</span>
      <span className={classes.currencyValue}>
        {isText ? value : <FormatDecimal value={value} prefix={prefix} />}
      </span>
    </div>
  );
};

export const RefCurrencyExchangeCard = ({ currencyTotals }) => {
  const classes = useStyles();

  const detailRows = [
    {
      label: 'Valor conversión otra moneda',
      value: currencyTotals.valorConversionMonedaExtranjera,
    },
    {
      label: 'Valor conversión',
      value: `Moneda a convertir: ${currencyTotals.monedaExtranjera}`,
      isText: true,
    },
    {
      label: 'Subtotal en otra moneda',
      value: currencyTotals.subtotalConvertido,
    },
    {
      label: 'Total descuentos en otra moneda',
      value: currencyTotals.valordescuentodocumentoConvertido,
    },
    {
      label: 'Total descuentos detalle en otra moneda',
      value: currencyTotals.valordescuentoConvertido,
    },
    {
      label: 'Total cargos detalle en otra moneda',
      value: currencyTotals.valorcargpdocumentoConvertido,
    },
    {
      label: 'Total bruto',
      value: currencyTotals.totalBrutoConvertido,
    },
    {
      label: 'Total iva en otra moneda',
      value: currencyTotals.valorivarealConvertido,
    },
    {
      label: 'Total Inc en otra moneda',
      value: currencyTotals.valorIncConvertido,
    },
    {
      label: 'Total otros impuestos',
      value: currencyTotals.valorOtrosImpuestosConvertido,
    },
  ];

  return (
    <Card className={classes.root}>
      <CardHeader
        className={classes.header}
        avatar={<AccountBalanceIcon className={classes.icon} />}
        classes={{
          title: classes.title,
        }}
        title={'Resumen de totales en moneda extranjera'}
      />
      <CardContent className={classes.content}>
        {detailRows.map((row, index) => (
          <DetailRow
            key={index}
            label={row.label}
            value={row.value}
            isText={row.isText}
          />
        ))}
      </CardContent>
    </Card>
  );
};
