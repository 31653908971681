export const TOKEN_PREFIX = "";
export const QUANTITY_DATA = "20"; //Debe ser 20
export const QUANTITY_DATA_CATEGORY = "15";
export const QUANTITY_DATA_DOCUMENT = "30"; //debe ser 30
export const SIZE_IMAGE = "1024";
export const SIZE_MAX_BULKLOAD_CLIENT = "300"; //In Kb
export const SIZE_FILE_BULKLOAD = 50000;
export const EXPIRATION_DAY_BULKLOAD = 5;
export const QUANTITY_DATA_MULTIMEDIA = 30;
export const QUANTITY_DATA_CUSTOMER = 30;
export const TIME_CREATE_REGISTER = 6000; // Tiempo en cerra el modal cuando se realiza un registro --segundos
export const TIME_UPDATE_REGISTER = 4000; // Tiempo en cerra el modal cuando se realiza un registro --segundos
export const MESSAGE_DELETE_CONFIRM = "Eliminar";
export const MESSAGE_NEXT_CONFIRM = "Confirmar";

export const API_ENDPOINT = "https://precore.colfactura.com";
export const API_ENDPOINT_ARTICLE = "https://precore.colfactura.com/item";
export const API_ENDPOINT_CATALOG = "https://precore.colfactura.com";
export const API_ENDPOINT_CONFIGURATION = "https://precore.colfactura.com/conf";
export const API_ENDPOINT_ENTERPRISE = "https://precore.colfactura.com/enterprise";
export const API_ENDPOINT_CUSTOMERS = "https://precore.colfactura.com/customer";
export const API_ENDPOINT_DOCUMENT = "https://precore.colfactura.com/document";
export const API_ENDPOINT_DASHBOARD = "https://precore.colfactura.com/dashboard";
export const API_ENDPOINT_CONSULT_DOCUMENT = "https://prenet.colfactura.com";
export const API_ENDPOINT_DOCUMENT_RECEPTION = "https://prenet.colfactura.com";
export const URL_SECURITY = "https://presecurity.colfactura.com/";
export const API_ENDPOINT_DISCHARGE = "https://precorelicence.gse.com.co";
export const API_ENDPOINT_IBUHOO = "https://5sw5ah9606.execute-api.us-east-2.amazonaws.com/pre";
export const URL_IMAGES = "https://qanet.colfactura.com";
export const API_IBUHOO_IMAGEMANAGER = "https://qanet.colfactura.com";
export const CHATBOT_WOLKVOX = "https://chat01.ipdialbox.com/chat/?prodId=Z3NlLjM0Mg==";
export const API_ENDPOINT_SIGNALR = "https://precore.colfactura.com";

export const URL_LICENCE = "https://prelicence.colfactura.com";
export const CLAIM_BRANCH_OFFICES = "COLFACTURA_SUCURSALES";
export const CLAIM_USERS = "COLFACTURA_USUARIOS";
export const CLAIM_DOCUMENTS = "COLFACTURA_PROCESAMIENTOFACTURAS";

export const DEFAULTRESPONSE = {
  noResponseFromApi: {
    statusCode: "500",
    statusMessage: "No hay respuesta por parte del servidor.",
    result: "",
  },
  SuccessResponse: {
    statusCode: "200",
    statusMessage: "Transacción exitosa",
    result: "",
  },
};

export function defaultHeaders() {
  return {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${localStorage.getItem("jwtToken")}`,
    },
  };
}

export const ROL_CFCOLAB = "CFCOLAB";

// Maximum size of images in bytes
export const imageSize = 2097152;

// Size of the image cutter canvas
export const imageCutterSize = {
  width: 450,
  height: 450,
};

export const TIME_REFRESH_TOKEN = 20;

//Rango dias permitidos para fecha emsión documento
export const MIN_DAYS_DATE_DOCUMENT = 10; //Maximo 10 DIAN
export const MAX_DAYS_DATE_DOCUMENT = 10; //Maximo 10 DIAN

export const DIGITS_BEFORE_DECIMAL = 12;
export const DIGITS_AFTER_DECIMAL = 4;
export const DIGITS_AFTER_DECIMAL_DOC = 2;
export const DIGITS_AFTER_DECIMAL_EQUIVALENT_DOC = 2;

export const READING_MODE = false;
