/**
 * @description Función para formatear la información de la retencion de renta
 * @param {Object} ret Objeto de rete renta a formatear
 * @param {Object} data Objeto de opdetalledocumento
 */
export const formatRetrenta = (ret, data) => {
  return {
    ...ret,
    base: data.baseRetRenta,
    tarifa: data.tarifaRetRenta,
  };
};
