import {
  transformCurrencyTotalsToDocument,
} from '../selectors/currencyExchangeSelector';
import { formatDate } from '../utils/general';
import { roundDecimalValue } from '../utils/managementNumber';

export const generateRequestNoteDataBD = (data, observations, isApproved) => {
  let clientAddress = null;
  let clientContact = null;
  let customFields = [];
  let referenceDocuments = [];
  let detailDocument = [];
  let chargesDocument = [];
  let discountsDocument = [];

  //Dirección cliente
  let selectedAddress = data?.adclientedirecciones?.find(
    (c) => c.id === data.direccionclienteid
  );
  if (selectedAddress !== undefined) {
    clientAddress = {
      nombmun: selectedAddress.nombmun,
      nombpais: selectedAddress.nombpais,
      id: selectedAddress.id,
      direccion: selectedAddress.direccion,
      descripcion: selectedAddress.descripcion,
      clienteid: selectedAddress.clienteid,
      ciudadid: selectedAddress.ciudadid,
      idstate: selectedAddress.idstate,
      createdby: selectedAddress.createdby,
      modifiedby: selectedAddress.modifiedby,
      idbusinessunit: selectedAddress.idbusinessunit ?? 0,
      idowner: selectedAddress.idowner,
      principal: selectedAddress.principal,
    };
  }

  //Contacto adicional
  let selectedContact = data?.adclientecontactos?.find(
    (c) => c.id === data?.contactoclienteid
  );
  if (selectedContact !== undefined) {
    clientContact = {
      id: selectedContact.id,
      nombre: selectedContact.nombre,
      telefono: selectedContact.telefono,
      celular: selectedContact.celular,
      email: selectedContact.email,
      clienteid: selectedContact.clienteid,
      idstate: selectedContact.idstate,
      createdby: selectedContact.createdby,
      modifiedby: selectedContact.modifiedby,
      idbusinessunit: selectedContact.idbusinessunit,
      idowner: selectedContact.idowner,
    };
  }

  //Datos adicionales
  for (let i = 0; i < data?.datosAdicionales?.length ?? 0; i++) {
    const item = data.datosAdicionales[i];
    let newData = {
      id: 0,
      codigo: item.codigo,
      valor: item.valor,
      funcion: item.funcion,
      tamanox: item.tamanox,
      tamanoy: item.tamanoy,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
    customFields.push(newData);
  }

  //Documentos de referencia
  for (let i = 0; i < data?.documentosreferencia?.length ?? 0; i++) {
    const item = data.documentosreferencia[i];
    let newData = {
      id: 0,
      numero: item.numero,
      fecha: item.fecha,
      valorapagar: item.valorapagar,
      tipodocumentoid: item.tipodocumentoid,
      tipodocumentodescripcion: item.tipodocumentodescripcion,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
    referenceDocuments.push(newData);
  }

  //Detalle documento
  for (let i = 0; i < data?.opdetalledocumento?.length ?? 0; i++) {
    const item = data.opdetalledocumento[i];
    let newData = {
      codigo: item.codigo,
      descripcion: item.descripcion,
      cantidad: item.cantidad,
      valorunitario: item.valorunitario,
      porcentajedescuento: item.porcentajedescuento,
      valordescuento: item.totaldescuento,
      id: 0,
      dianunidadmedidaid: item.dianunidadmedidaid,
      articuloid: item.articuloid,
      totaliva: item.totaliva,
      totalinc: item.totalinc,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
      subtotal: item.subtotalini,
      tipodescuento: item.tipodescuento,
      total: item.total,
      totaldescuento: item.totaldescuento,
      valordescuentocomercial: item.valordescuentocomercial,
      prorrateo: item.prorrateo ?? 0,
      totaldescuentoreal: item.totaldescuentoreal,
      subtotalreal: item.subtotal, //Real
      totalivareal: item.totaliva, // Real
      totalincreal: item.totalinc, //Real
      TotalIcui: item.totalIcui,
      TotalAdv: item.totalAdv,
      PorcentajeIcui: item.porcentajeIcui,
      PorcentajeAdv: item.porcentajeAdv,
      TotalIbua:
        item?.totalIbua === null || item?.totalIbua === 0
          ? null
          : item.totalIbua,
      CantidadMililitros:
        item.cantidadMililitros === null || item.cantidadMililitros === 0
          ? null
          : item.cantidadMililitros,
      TarifaIbua:
        item.tarifaIbua === null || item.tarifaIbua === 0
          ? null
          : item.tarifaIbua,
      TotalIcl: item.totalIcl === null ? null : item.totalIcl,
      CantidadGrados: item.cantidadGrados === null ? null : item.cantidadGrados,
      TarifaIcl: item.tarifaIcl === null ? null : item.tarifaIcl,
      TotalInpp: item.totalInpp === null ? null : item.totalInpp,
      CantidadGramos: item.cantidadGramos === null ? null : item.cantidadGramos,
      TarifaInpp: item.tarifaInpp === null ? null : item.tarifaInpp,
      totalreal: item.totalreal,
      costo: item.costo,
      porcentajeiva: item.porcentajeiva,
      porcentajeinc: item.porcentajeinc,

      //Retenciones
      idretfte: item.idretfte,
      baseretfte: item.baseretfte,
      tarifaretfte: item.tarifaretfte,
      valorretfte: item.valorretfte,

      idretica: item.idretica,
      baseretica: item.baseretica,
      tarifaretica: item.tarifaretica,
      valorretica: item.valorretica,

      idretiva: item.idretiva,
      baseretiva: item.baseretiva,
      tarifaretiva: item.tarifaretiva,
      valorretiva: item.valorretiva,

      //Articulos regalo
      aplicaregalo: item.aplicaregalo ?? false,
      esregalo: item.esregalo ?? false,
      asumeresponsabilidad: item.asumeresponsabilidad,
      valorreferencia: item.valorreferencia,
    };

    detailDocument.push(newData);
  }

  //Cargos
  for (let i = 0; i < data?.opdocumentocargos?.length ?? 0; i++) {
    const item = data?.opdocumentocargos[i];

    let newData = {
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
      documentoid: 0,
      cargoid: item.cargoid,
      descripcion: item.descripcion,
      tipocargo: item.tipocargo,
      valor: item.valor,
      esglobal: item.esglobal,
      cantidad: item.cantidad,
      valorcalculado: item.valorcalculado,
      valorasumar: item.valorasumar,
      identificador: item.identificador,
    };
    chargesDocument.push(newData);
  }

  //Descuentos
  for (let i = 0; i < data?.opdocumentodescuentos?.length ?? 0; i++) {
    const item = data?.opdocumentodescuentos[i];
    let newData = {
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
      documentoid: 0,
      descuentoid: item.descuentoid,
      descripcion: item.descripcion,
      motivoid: item.motivoid,
      modelodescuento: item.modelodescuento,
      valor: item.valor,
      tipodescuento: item.tipodescuento,
      valoradescontar: item.valoradescontar,
      motivodescuento: item.motivodescuento,
      identificador: item.identificador,
    };
    discountsDocument.push(newData);
  }

  //Fecha de Factura
  var dateNow = new Date().setHours(0, 0, 0, 0);
  var broadcastDate = new Date(data.fecha).setHours(0, 0, 0, 0);
  var newDate = broadcastDate >= dateNow ? new Date(data.fecha) : new Date();

  //Documento
  let document = {
    opdocumento: {
      fecha: formatDate(newDate, 'yyyy-MM-dd'),
      numeracionid: data.numeracionid,
      estadodocumentoid: 0,
      tipoaceptaciondocumentoid: 1,
      tipoacusedocumentoid: 3,
      valorapagar: data.valorapagarreal,
      dianformapagoid: data.dianformapagoid,
      valordescuento: data.valordescuento,
      valorcargos: 0, // No aplica
      subtotal: data.subadicionales,
      totalBaseImponible: data.totalBaseImponible,
      valortotal: data.subadicionales, //?
      valordescuentodocumento: data.valordescuentodocumento,
      valorcargodocumento: data.valorcargodocumento,
      valoranticipo: 0,
      valorinc: data.valorinc,
      valoriva: data.valoriva,
      textoobservacion: observations ?? '',
      vendedorid: data.vendedorid,
      diantipodocumentoelectronicoid: 3, // Nota Credito
      clienteid: data.clienteid,
      dianmediopagoid: data.dianmediopagoid,
      idstate: 0,
      createdby: 1,
      modifiedby: 1,
      idbusinessunit: 1,
      idowner: 1,
      origendocumentoid: 1,
      fechapago: data.fechapago,
      favorito: false,
      empresaid: data.empresaid,
      incluirvendedor: data.incluirvendedor,
      dianconceptonotacreditoid: 2, //? anulación
      dianconceptonotadebitoid: 0,
      documentoreferenciaid: data.id,
      documentoref: data.numerodian,
      valordescuentoreal: data.valordescuentoreal,
      valorivareal: data.valoriva,
      valorincreal: data.valorinc,
      ValorAdv: data.valorAdv === null ? null : data.valorAdv,
      ValorIcui: data.valorIcui === 0 ? null : data.valorIcui,
      ValorIbua: data.valorIbua === null ? null : data.valorIbua,
      ValorIcl: data.valorIcl === null ? null : data.valorIcl,
      ValorInpp: data.valorInpp === null ? null : data.valorInpp,
      totalfacturaReal: data.totalfacturareal,
      redondeo: data?.redondeo,
      decimales: data?.decimales,
      valorretfte: data?.valorretfte,
      valorretica: data?.valorretica,
      valorretiva: data?.valorretiva,
      valorapagardian: data?.valorapagardian,
      vendedor:
        (data?.vendedor?.id ?? null) !== null
          ? {
              id: data?.vendedor?.id,
              nombres: data?.vendedor.nombres,
              apellidos: data?.vendedor.apellidos,
              idstate: data?.vendedor.idstate,
              createdby: data?.vendedor.createdby,
              modifiedby: data?.vendedor.modifiedby,
              idbusinessunit: data?.vendedor.idbusinessunit,
              idowner: data?.vendedor.idowner,
              empresaid: data?.vendedor.empresaid,
              telefono: data?.vendedor.telefono,
              email: data?.vendedor.email,
            }
          : null,
      Cliente: {
        id: data.clienteid,
        documento: data.clientedocumento,
        documentodv: data.clientedv,
        razonsocial: data.clienterazon,
        telefono: data.clientetelefono,
        correoelectronico: data.clientecorreo,
        diantipodocumentoidentidadid: data.diantipodocumentoidentidadid, //?
        diantipopersonaid: data.diantipopersonaid, // ?
        empresaid: data.empresaid, //?
        favorito: false, //?
        idstate: 0,
        createdby: 1,
        modifiedby: 1,
        idbusinessunit: 1,
        idowner: 1,
        clientedireccion: {
          id: clientAddress?.id,
          descripcion: clientAddress?.descripcion,
          clienteid: clientAddress?.clienteid,
          ciudadid: clientAddress?.ciudadid,
          idstate: clientAddress?.idstate,
          createdby: clientAddress?.createdby,
          modifiedby: clientAddress?.modifiedby,
          idbusinessunit: clientAddress?.idowner,
          idowner: clientAddress?.idowner,
          direccion: clientAddress?.direccion,
          principal: clientAddress?.principal,
        },
        contactos:
          clientContact !== null
            ? {
                Id: clientContact.id,
                Nombre: clientContact.nombre,
                Telefono: clientContact.telefono,
                Celular: clientContact.celular,
                Email: clientContact.email,
                Clienteid: clientContact.clienteid,
                Idstate: clientContact.idstate,
                Createdby: clientContact.createdby,
                Modifiedby: clientContact.modifiedby,
                Idbusinessunit: clientContact.idbusinessunit,
                Idowner: clientContact.idowner,
              }
            : null,
      },
      Numeracion: {
        id: data.numeracionid,
        resolucion: data.resolucion,
        fechainicio: data.fechainicio,
        fechafinal: data.fechafinal,
        prefijo: data.prefijo,
        consecinicial: data.consecinicial,
        consecfinal: data.consecfinal,
        consecactual: data.consecactual,
        empresaid: data.empresaid,
        diantipomodalidadid: data.diantipomodalidadid,
        sucursalempresaid: data.sucursalid, // ?
        Idstate: 0,
        Createdby: 1,
        Modifiedby: 1,
        Idbusinessunit: 1,
        Idowner: 1,
        Nombre: data.nombrenumeracion,
      },
      //<Documento aprobado>, es un valor booleano que especifica si la factura fue aprobada o no para su respectiva anulación.
      DocumentoAprobado: isApproved,
    },
    LstOpdetalledocumento: detailDocument,
    LstCargos: chargesDocument,
    LstDescuentos: discountsDocument,
    DatosAdicionales: customFields,
    DocumentosReferencia: referenceDocuments,
  };

  return document;
};

/**
 * Obtiene dirección de cliente
 * @param {*} adcliente Información cliente
 * @param {*} direccionid Identificador direccion seleccionada
 * @returns  Dirección cliente
 */
const getAddressCustomer = (adcliente, direccionid) => {
  const { adclientedireccion } = adcliente;
  const address = adclientedireccion.find((d) => d.id === direccionid);
  if (!!address) {
    return {
      id: address.id,
      descripcion: address.descripcion,
      clienteid: address.clienteid,
      ciudadid: address.ciudadid,
      direccion: address.direccion,
      principal: false,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
  } else {
    return {
      id: adcliente.direccionid,
      descripcion: 'Principal',
      clienteid: adcliente.id,
      ciudadid: adcliente.ciudadid,
      direccion: adcliente.direccion,
      principal: adcliente.principal,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
  }
};

const getContactCustomer = (adcliente, contactoclienteid) => {
  const { adcontactos } = adcliente;
  const contact = adcontactos.find((c) => c.id === contactoclienteid);
  if (!!contact) {
    return {
      id: contact.id,
      nombre: contact.nombre,
      telefono: contact.telefono,
      celular: contact.celular,
      email: contact.email,
      clienteid: contact.clienteid,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
  } else {
    return {
      id: 0,
      nombre: '',
      telefono: '',
      celular: '',
      email: '',
      clienteid: 0,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
  }
};

/**
 * Obtiene información de cliente
 * @param {*} adcliente
 * @param {*} direccionid
 * @param {*} contactoid
 * @returns
 */
const getCustomer = (adcliente, direccionid, contactoid) => {
  const cliente = {
    id: adcliente.id,
    documento: adcliente.documento,
    documentodv: adcliente.documentodv,
    razonsocial: adcliente.razonsocial,
    telefono: adcliente.telefono,
    correoelectronico: adcliente.correoelectronico,
    diantipodocumentoidentidadid: adcliente.diantipodocumentoidentidadid,
    diantipopersonaid: adcliente.diantipopersonaid,
    empresaid: adcliente.Empresaid,
    favorito: adcliente.Favorito,
    clientedireccion: getAddressCustomer(adcliente, direccionid),
    contactos: getContactCustomer(adcliente, contactoid),
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  };

  return cliente;
};

/**
 * Genera opdetalle documento
 * @param {*} items
 */
export const getDetailDocument = (items) => {
  const opdetalledocumento = items.map((item) => ({
    codigo: item.codigo,
    descripcion: item.descripcion,
    cantidad: item.cantidad,

    valorunitario: item.valorneto,
    porcentajedescuento: item.porcentajedescuento,
    valordescuento: item.valordescuento,
    id: !item.detalleid ? 0 : item.detalleid,
    dianunidadmedidaid: item.dianunidadmedidaid,
    articuloid: item.articuloid,
    totaliva: item.totaliva,
    totalinc: item.totalinc,
    total: item.total,
    subtotal: item.subtotal,
    tipodescuento: item.tipodescuento,

    totaldescuento: item.totaldescuento,
    valordescuentocomercial: item.valordescuentocomercial,
    pororrateo: item.prorrateo,
    totaldescuentoreal: item.totaldescuentoreal,
    subtotalreal: item.subtotalreal,
    totalivareal: item.totalivareal,
    totalincreal: item.totalincreal,
    TotalIcui: item.totalicuireal,
    TotalAdv: item.totaladvreal,
    PorcentajeIcui: item.porcentajeicui,
    PorcentajeAdv: item.porcentajeadv,
    TotalIbua: item.totalibuareal === null ? null : item.totalibuareal,
    CantidadMililitros:
      item.cantidadmililitros === null
        ? null
        : item.cantidadmililitros * item.cantidad,
    TarifaIbua: item.valoribua === null ? null : item.valoribua,
    TotalIcl: item.totaliclreal === null ? null : item.totaliclreal,
    CantidadGrados: item.gradosalcohol === null ? null : item.gradosalcohol,
    TarifaIcl:
      item.tipobebida === null
        ? null
        : roundDecimalValue(item.tipobebida * item.cantidad),
    TotalInpp: item.totalinppreal === null ? null : item.totalinppreal,
    CantidadGramos:
      item.pesogramos === null ? null : item.pesogramos * item.cantidad,
    TarifaInpp: item.tasauvtimp === null ? null : item.tasauvtimp,
    totalreal: item.totalreal,
    costo: item.costo ?? 0,
    porcentajeiva: item.porcentajeiva,
    porcentajeinc: item.porcentajeinc,
    idretfte: item.idretfte,
    baseretfte: item.baseretfte,
    tarifaretfte: item.tarifaretfte,
    valorretfte: item.valorretfte,
    idretica: item.idretica,
    baseretica: item.baseretica,
    tarifaretica: item.tarifaretica,
    valorretica: item.valorretica,
    idretiva: item.idretiva,
    baseretiva: item.baseretiva,
    tarifaretiva: item.tarifaretiva,
    valorretiva: item.valorretiva,
    aplicaregalo: item.aplicaregalo ?? false,
    esregalo: item.esregalo ?? false,
    asumeresponsabilidad: !item.asumeresponsabilidad
      ? null
      : item.asumeresponsabilidad,
    valorreferencia: !item.valorreferencia ? null : item.valorreferencia,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  }));

  return opdetalledocumento;
};

/**
 * Genera listado cargos
 * @param {*} chargesList
 * @returns
 */
const getCharges = (chargesList) => {
  const opdocumentocargos = chargesList.map((charge) => ({
    documentoid: 0,
    cargoid: charge.cargoid,
    descripcion: charge.descripcion,
    tipocargo: charge.tipocargo,
    valor: charge.valor,
    esglobal: charge.esglobal,
    cantidad: charge.cantidad,
    valorcalculado: charge.valorasumar,
    valorasumar: charge.valorasumar,
    identificador: charge.id,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  }));
  return opdocumentocargos;
};

/**
 * Genera listado descuentos
 * @param {*} discountList
 * @returns
 */
const getDiscounts = (discountList) => {
  const opdocumentodescuentos = discountList.map((discount) => ({
    documentoid: 0,
    descuentoid: discount.descuentoid,
    descripcion: discount.descripcion,
    motivoid: discount.motivoid,
    modelodescuento: discount.modelodescuento,
    valor: discount.valor,
    tipodescuento: discount.tipodescuento,
    valoradescontar: discount.valoradescontar,
    motivodescuento: discount.motivoDescripcion,
    identificador: discount.id,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  }));

  return opdocumentodescuentos;
};

/**
 * Genera objeto información de vendedor
 * @param {*} seller
 * @returns
 */
const getSeller = (seller) => {
  if (!seller) {
    return {
      id: 0,
      nombres: '',
      apellidos: '',
      empresaid: 0,
      telefono: '',
      email: '',
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
  }

  const advendedor = {
    id: seller.id,
    nombres: seller.nombres,
    apellidos: seller.apellidos,
    empresaid: seller.empresaid,
    telefono: seller.telefono,
    email: seller.email,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  };
  return advendedor;
};

/**
 * Genera listado datos adicionales
 * @param {*} datosadicionales
 * @param {*} addatosadicionales
 * @returns
 */
const getCustomFields = (datosadicionales, addatosadicionales) => {
  let opdatosadicionales = [];
  for (const [key, value] of Object.entries(datosadicionales ?? {})) {
    let additional = addatosadicionales.find(
      (c) =>
        c.codigo.toLowerCase().replaceAll(' ', '') ===
        key.toLowerCase().replaceAll(' ', '')
    );
    let newData = {
      id: 0,
      codigo: key,
      valor: value,
      funcion: additional?.funcion,
      tamanox: additional?.tamanox,
      tamanoy: additional?.tamanoy,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
    opdatosadicionales.push(newData);
  }

  return opdatosadicionales;
};

/**
 * Genera listado documentos de referencia
 * @param {*} documentosreferencia
 * @returns
 */
const getReferenceDocuments = (documentosreferencia) => {
  let opdocumentosreferencia = [];

  for (let d = 0; d < documentosreferencia.length; d++) {
    const item = documentosreferencia[d];
    let newData = {
      ...item,
      id: 0,
      idstate: 0,
      createdby: 1,
      createdon: formatDate(new Date(), 'yyyy-MM-dd'),
      modifiedby: 1,
      modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
      idbusinessunit: 1,
      idowner: 1,
    };
    opdocumentosreferencia.push(newData);
  }

  return opdocumentosreferencia;
};

/**
 * Genera listado pacientes asociados a documento
 * @param {*} patients
 * @returns
 */
const getPatientsDocument = (patients) => {
  let oppacientes = [];
  patients = patients ?? [];
  for (let i = 0; i < patients.length; i++) {
    const item = patients[i];
    const valorcopago = item.copago === '' ? null : parseFloat(item.copago);
    const valorcuotamod =
      item.cuotamoderadora === '' ? null : parseFloat(item.cuotamoderadora);
    const valorcuotaRec =
      item.cuotarecuperacion === '' ? null : parseFloat(item.cuotarecuperacion);
    const valorpagos =
      item.pagoscompartidos === '' ? null : parseFloat(item.pagoscompartidos);

    let newData = {
      ...item,
      tipodocumento: item.tipodocumentocodigo,
      tipousuario: item.tipousuariocodigo,
      tipomodalidad: item.tipocoberturacodigo,
      tipocobertura: item.tipocoberturacodigo,
      numeroautorizacion:
        item.numeroautorizacion === '' ? null : item.numeroautorizacion,
      numeroprescripcion:
        item.numeroprescripcion === '' ? null : item.numeroprescripcion,
      idsuministro: item.idsuministro === '' ? null : item.idsuministro,
      numerocontrato: item.numerocontrato === '' ? null : item.numerocontrato,
      numeropoliza: item.numeropoliza === '' ? null : item.numeropoliza,
      copago: valorcopago,
      cuotamoderadora: valorcuotamod,
      cuotarecuperacion: valorcuotaRec,
      pagoscompartidos: valorpagos,
    };

    oppacientes.push(newData);
  }
  return oppacientes;
};

/**
 * Genera objeto numeración
 * @param {*} numeration
 * @returns
 */
const getNumeration = (numeration) => {
  const adnumeracion = {
    id: numeration.id,
    resolucion: numeration.resolucion,
    fechainicio: numeration.fechainicio,
    fechafinal: numeration.fechafinal,
    prefijo: numeration.prefijo,
    consecinicial: numeration.consecinicial,
    consecfinal: numeration.consecfinal,
    consecactual: numeration.consecactual,
    empresaid: numeration.empresaid,
    diantipomodalidadid: numeration.diantipomodalidadid,
    sucursalempresaid: numeration.sucursalempresaid,
    nombre: numeration.nombre,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  };
  return adnumeracion;
};

const getNumerationQuotation = (sucursalid) => {
  const adnumeracion = {
    id: 0,
    resolucion: '',
    fechainicio: formatDate(new Date(), 'yyyy-MM-dd'),
    fechafinal: formatDate(new Date(), 'yyyy-MM-dd'),
    prefijo: '',
    consecinicial: '',
    consecfinal: '',
    consecactual: '',
    empresaid: 0,
    diantipomodalidadid: 0,
    sucursalempresaid: sucursalid,
    idstate: 0,
    createdby: 0,
    modifiedby: 0,
    idbusinessunit: 0,
    idowner: 0,
    nombre: '',
  };

  return adnumeracion;
};

/**
 * Genera request para registro documento
 * @param {*} documentdata
 * @param {*} totals
 * @param {*} currencyTotals - Valor de moneda extranjera
 * @returns
 */
export const generateRequestDocument = (
  documentdata,
  totals,
  currencyTotals,
  numerationselected = null
) => {
  const {
    adcliente,
    direccionclienteid,
    contactoclienteid,
    datosadicionales,
    opdocumentosreferencia,
    addatosadicionales,
    favorito,
    opdocumento,
    oppacientes,
    generardocumentopaciente,
  } = documentdata;

  const {
    dianformapagoid,
    dianmediopagoid,
    fecha,
    fechapago,
    incluirVendedor,
    numeracionid,
    numeracion,
    observaciones,
    vendedorid,
    vendedor,
    responsabilidadtributariaid,
  } = opdocumento;

  const { empresaid } = adcliente;

  let {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    valorapagar,
    valorcargos,
    valordescuento,
    valorbruto,
    valorbaseimponible,
    valorapagardian,
    valortotal,
    valoriva,
    valorinc,
    valordescuentoreal,
    valorivareal,
    valorincreal,
    valoricuireal,
    valoradvreal,
    valoribuareal,
    valoriclreal,
    valorinppreal,
    redondeo,
    valorretfte,
    valorretica,
    valorretiva,
    valorotrosdescuentos,
    totalfacturaReal,
  } = totals;

  let generateddocument = {
    Opdocumento: {
      totalesmonedaextranjera: currencyTotals
        ? transformCurrencyTotalsToDocument(currencyTotals)
        : null,
      fecha: fecha,
      numeracionid: !!numerationselected ? numerationselected.id : numeracionid,
      estadodocumentoid: 0,
      tipoaceptaciondocumentoid: 1,
      tipoacusedocumentoid: 3,
      valorapagar: valorapagar,
      dianformapagoid: dianformapagoid,
      valordescuento: valordescuento,
      valorcargos: valorcargos,
      subtotal: valorbruto,
      totalBaseImponible: valorbaseimponible,
      valortotal: valortotal,
      valordescuentodocumento: valorotrosdescuentos,
      valorcargodocumento: valorcargos,
      valoranticipo: 0,
      valorinc: valorinc,
      valoriva: valoriva,
      textoobservacion: observaciones,
      vendedorid: vendedorid,
      diantipodocumentoelectronicoid: 1,
      clienteid: adcliente.id, //proveedorid
      dianmediopagoid: dianmediopagoid,
      origendocumentoid: 1,
      dianconceptonotadebitoid: 0,
      dianconceptonotacreditoid: 0,
      fechapago: !!fechapago && dianformapagoid === 2 ? fechapago : null,
      favorito: favorito,
      empresaid: empresaid,
      incluirvendedor: !!vendedor ? incluirVendedor : false,
      valordescuentoreal: valordescuentoreal,
      valorivareal: valorivareal,
      valorincreal: valorincreal,
      ValorAdv: valoradvreal === null ? null : valoradvreal,
      ValorIcui: valoricuireal === null ? null : valoricuireal,
      ValorIbua: valoribuareal === null ? null : valoribuareal,
      ValorIcl: valoriclreal === null ? null : valoriclreal,
      ValorInpp: valorinppreal === null ? null : valorinppreal,
      totalfacturaReal: totalfacturaReal,
      redondeo: redondeo,
      decimales: 4,
      valorretfte: valorretfte,
      valorretica: valorretica,
      valorretiva: valorretiva,
      valorapagardian: valorapagardian,
      vendedor: getSeller(vendedor),
      direccionclienteid: direccionclienteid,
      contactoclienteid: contactoclienteid,
      cliente: getCustomer(adcliente, direccionclienteid, contactoclienteid),
      numeracion: !!numerationselected
        ? getNumeration(numerationselected)
        : getNumeration(numeracion),
      Generardocumentopacientes: generardocumentopaciente,
      archivovisualizar: 'INVOICE',
      ResponsabilidadTributariaId: responsabilidadtributariaid,
    },
    LstOpdetalledocumento: getDetailDocument(opdetalledocumento),
    LstCargos: getCharges(opdocumentocargos),
    LstDescuentos: getDiscounts(opdocumentodescuentos),
    DatosAdicionales: getCustomFields(datosadicionales, addatosadicionales),
    DocumentosReferencia: getReferenceDocuments(opdocumentosreferencia),
    Oppacientes: getPatientsDocument(oppacientes),
  };

  return generateddocument;
};

/**
 * Genera request para registro nota
 * @param {*} documentdata
 * @param {*} totals
 * @returns
 */
export const generateRequestNote = (
  documentdata,
  totals,
  currencyTotals,
  observacionesAnulacion,
  esAnulacion = false
) => {
  const {
    adcliente,
    direccionclienteid,
    contactoclienteid,
    opdocumentosreferencia,
    addatosadicionales,
    favorito,
    opdocumento,
  } = documentdata;

  const {
    dianformapagoid,
    dianmediopagoid,
    fecha,
    fechapago,
    incluirVendedor,
    numeracionid,
    numeracion,
    vendedorid,
    vendedor,
    documentoreferenciaid,
    documentoref,
  } = opdocumento;

  const { empresaid } = adcliente;

  let {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    valorapagar,
    valorcargos,
    valordescuento,
    valorbruto,
    valorbaseimponible,
    valorapagardian,
    valortotal,
    valoriva,
    valorinc,
    valordescuentoreal,
    valorivareal,
    valorincreal,
    valoricuireal,
    valoradvreal,
    valoribuareal,
    valoriclreal,
    valorinppreal,
    redondeo,
    valorretfte,
    valorretica,
    valorretiva,
    valorotrosdescuentos,
    totalfacturaReal,
    observacionesnota,
    motivonota,
  } = totals;

  //Fecha de Factura
  var dateNow = new Date().setHours(0, 0, 0, 0);
  var broadcastDate = new Date(fecha).setHours(0, 0, 0, 0);
  var newDate = broadcastDate >= dateNow ? new Date(fecha) : new Date();

  const tipoNota = esAnulacion ? 3 : motivonota?.tipo === 'ND' ? 2 : 3;

  const conceptodebito = esAnulacion
    ? 0
    : motivonota?.tipo === 'ND'
    ? motivonota.id
    : 0;

  const conceptocredito = esAnulacion
    ? 2
    : motivonota?.tipo === 'NC' || motivonota?.tipo === 'ANU'
    ? motivonota.id
    : 0;

  let generateddocument = {
    opdocumento: {
      totalesmonedaextranjera: currencyTotals
        ? transformCurrencyTotalsToDocument(currencyTotals)
        : null,
      fecha: formatDate(newDate, 'yyyy-MM-dd'),
      numeracionid: numeracionid,
      estadodocumentoid: 0,
      tipoaceptaciondocumentoid: 1,
      tipoacusedocumentoid: 3,
      valorapagar: valorapagar,
      dianformapagoid: dianformapagoid,
      valordescuento: valordescuento,
      valorcargos: valorcargos,
      subtotal: valorbruto,
      totalBaseImponible: valorbaseimponible,
      valortotal: valortotal,
      valordescuentodocumento: valorotrosdescuentos,
      valorcargodocumento: valorcargos,
      valoranticipo: 0,
      valorinc: valorinc,
      valoriva: valoriva,
      textoobservacion: !observacionesAnulacion
        ? observacionesnota
        : observacionesAnulacion,
      vendedorid: vendedorid,
      diantipodocumentoelectronicoid: tipoNota,
      clienteid: adcliente.id,
      dianmediopagoid: dianmediopagoid,
      origendocumentoid: 1,
      dianconceptonotadebitoid: conceptodebito,
      dianconceptonotacreditoid: conceptocredito,
      fechapago: !!fechapago ? fechapago : null,
      favorito: favorito,
      empresaid: empresaid,
      incluirvendedor: !!vendedor ? incluirVendedor : false,
      documentoreferenciaid: documentoreferenciaid,
      documentoref: documentoref,
      valordescuentoreal: valordescuentoreal,
      valorivareal: valorivareal,
      valorincreal: valorincreal,
      ValorAdv: valoradvreal === null ? null : valoradvreal,
      ValorIcui: valoricuireal === null ? null : valoricuireal,
      ValorIbua: valoribuareal === null ? null : valoribuareal,
      ValorIcl: valoriclreal === null ? null : valoriclreal,
      ValorInpp: valorinppreal === null ? null : valorinppreal,
      totalfacturaReal: totalfacturaReal,
      redondeo: redondeo,
      decimales: 4,
      valorretfte: valorretfte,
      valorretica: valorretica,
      valorretiva: valorretiva,
      valorapagardian: valorapagardian,
      vendedor: getSeller(vendedor),
      direccionclienteid: direccionclienteid,
      contactoclienteid: contactoclienteid,
      cliente: getCustomer(adcliente, direccionclienteid, contactoclienteid),
      numeracion: getNumeration(numeracion),
      Archivovisualizar: 'NOTE',
    },
    LstOpdetalledocumento: getDetailDocument(opdetalledocumento),
    LstCargos: getCharges(opdocumentocargos),
    LstDescuentos: getDiscounts(opdocumentodescuentos),
    DatosAdicionales: addatosadicionales,
    DocumentosReferencia: opdocumentosreferencia,
  };

  return generateddocument;
};

/**
 * Genera request para registro nota
 * @param {*} documentdata
 * @param {*} totals
 * @returns
 */
export const generateRequestNoteAdjusment = (
  documentdata,
  totals,
  observacionesAnulacion,
  esAnulacion = false
) => {
  const {
    fecha,
    estadodocumentoid,
    id,
    prefijo,
    consecutivo,
    codigoTipoTransmision,
  } = documentdata;

  let {
    opdetalledocumento,
    valorapagar,
    valorcargos,
    valordescuento,
    valorapagardian,
    valortotal,
    valoriva,
    valordescuentoreal,
    valorivareal,
    redondeo,
    valorretfte,
    valorretiva,
    valorbruto,
    totalfacturaReal,
    observacionesnota,
    motivonota,
    decimales,
  } = totals;

  //Fecha de Factura
  let dateNow = new Date().setHours(0, 0, 0, 0);
  let broadcastDate = new Date(fecha).setHours(0, 0, 0, 0);
  let newDate = broadcastDate >= dateNow ? new Date(fecha) : new Date();

  let generateddocument = {
    Documento: {
      Fecha: formatDate(newDate, 'yyyy-MM-dd'),
      Observacion: !esAnulacion ? observacionesnota : observacionesAnulacion,
      Favorito: false,
      CodigoUnico: null,
      ValorDescuento: valordescuento.toFixed(2),
      ValorDescuentoReal: valordescuentoreal.toFixed(2),
      ValorCargo: valorcargos.toFixed(2),
      ValorAPagar: valorapagar.toFixed(2),
      ValorAPagarDian: valorapagardian.toFixed(2),
      ValorTotal: valortotal.toFixed(2),
      ValorTotalReal: totalfacturaReal.toFixed(2),
      Subtotal: valorbruto.toFixed(2),
      ValorIva: valoriva.toFixed(2),
      ValorIvaReal: valorivareal.toFixed(2),
      ValorRetRenta: valorretfte.toFixed(2),
      ValorRetIva: valorretiva.toFixed(2),
      Decimales: decimales,
      Prefijo: prefijo,
      Consecutivo: consecutivo,
      Redondeo: redondeo,
      EmpresaId: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      EstadoDocumentoId: estadodocumentoid,
      DianTipoDocumentoElectronicoId: 6,
      DianConceptoNotaAjusteId: !esAnulacion ? motivonota.id : 2,
      DocumentoReferenciaId: id,
      codigoTipoTransmision,
    },
    DetalleDocumento: getDetailDocumentSupportNote(opdetalledocumento),
    LstCargos: [],
    LstDescuentos: [],
    TipoDocumento: '95',
    DocumentosReferencia: [],
  };

  return generateddocument;
};

/**
 * Genera request para registro documento
 * @param {*} documentdata
 * @param {*} totals
 * @returns
 */
export const generateRequestQuotation = (documentdata, totals) => {
  const {
    adcliente,
    direccionclienteid,
    contactoclienteid,
    datosadicionales,
    addatosadicionales,
    favorito,
    opdocumento,
  } = documentdata;

  const {
    id,
    dianformapagoid,
    dianmediopagoid,
    fecha,
    fechapago,
    incluirVendedor,
    observaciones,
    vendedorid,
    vendedor,
    sucursalid,
    tipoaceptacionid,
  } = opdocumento;

  const { empresaid } = adcliente;

  let {
    opdetalledocumento,
    opdocumentocargos,
    opdocumentodescuentos,
    valorapagar,
    valorcargos,
    valordescuento,
    valorbruto,
    valorapagardian,
    valortotal,
    valoriva,
    valorinc,
    valordescuentoreal,
    valorivareal,
    valoricuireal,
    valoribuareal,
    valoradvreal,
    valoriclreal,
    valorinppreal,
    redondeo,
    valorretfte,
    valorretica,
    valorretiva,
    valorotrosdescuentos,
    totalfacturaReal,
  } = totals;

  let generatedQuotation = {
    Opdocumento: {
      id: id ?? 0,
      fecha: fecha,
      numeracionid: 0,
      estadodocumentoid: 0,
      tipoaceptaciondocumentoid: tipoaceptacionid ?? 1,
      tipoacusedocumentoid: 3,
      valorapagar: valorapagar,
      dianformapagoid: dianformapagoid,
      valordescuento: valordescuento,
      valorcargos: valorcargos,
      subtotal: valorbruto,
      valortotal: valortotal,
      valordescuentodocumento: valorotrosdescuentos,
      valorcargodocumento: valorcargos,
      valoranticipo: 0,
      valorinc: valorinc,
      valoriva: valoriva,
      textoobservacion: observaciones,
      vendedorid: vendedorid,
      diantipodocumentoelectronicoid: 4,
      clienteid: adcliente.id,
      dianmediopagoid: dianmediopagoid,
      origendocumentoid: 1,
      dianconceptonotadebitoid: 0,
      dianconceptonotacreditoid: 0,
      fechapago: !!fechapago && dianformapagoid === 2 ? fechapago : null,
      fechavigencia: !!fechapago && dianformapagoid === 2 ? fechapago : null,
      favorito: favorito,
      empresaid: empresaid,
      incluirvendedor: !!vendedor ? incluirVendedor : false,
      valordescuentoreal: valordescuentoreal,
      valorivareal: valorivareal,
      ValorAdv: valoradvreal === null ? null : valoradvreal,
      ValorIcui: valoricuireal === 0 ? null : valoricuireal,
      ValorIbua: valoribuareal === null ? null : valoribuareal,
      ValorIcl: valoriclreal === null ? null : valoriclreal,
      ValorInpp: valorinppreal === null ? null : valorinppreal,
      totalfacturaReal: totalfacturaReal,
      redondeo: redondeo,
      decimales: 4,
      valorretfte: valorretfte,
      valorretica: valorretica,
      valorretiva: valorretiva,
      valorapagardian: valorapagardian,
      vendedor: !!vendedor ? getSeller(vendedor) : null,
      direccionclienteid: direccionclienteid,
      contactoclienteid: contactoclienteid,
      cliente: getCustomer(adcliente, direccionclienteid, contactoclienteid),
      numeracion: getNumerationQuotation(sucursalid),
    },
    LstOpdetalledocumento: getDetailDocument(opdetalledocumento),
    LstCargos: getCharges(opdocumentocargos),
    LstDescuentos: getDiscounts(opdocumentodescuentos),
    DatosAdicionales: getCustomFields(datosadicionales, addatosadicionales),
    DocumentosReferencia: [],
    TipoDocumento: 'quotation',
    Archivovisualizar: 'QUOTATION',
  };

  return generatedQuotation;
};

/**
 * Genera request para registro documento soporte
 * @param {*} documentdata
 * @param {*} totals
 * @returns
 */

export const generateRequestSupport = (
  totals,
  documentData,
  favorite,
  typeDocument,
  numeration,
  prefix
) => {
  const {
    fechaFactura,
    formaPago,
    paymethodid,
    fechapago,
    observaciones,
    resolucionFactura,
    tipoDeTransmision,
  } = documentData;

  const {
    opdetalledocumento,
    // opdocumentocargos,
    // opdocumentodescuentos,
    valorapagar,
    valorcargos,
    // valordescuento,
    valorbruto,
    valorapagardian,
    valortotal,
    valoriva,
    // valorinc,
    valordescuentoreal,
    valorivareal,
    // valorincreal,
    redondeo,
    valorretfte,
    // valorretica,
    valorretiva,
    // valorotrosdescuentos,
    totalfacturaReal,
    decimales,
  } = totals;

  let generateddocument = {
    Documento: {
      NumeracionId: resolucionFactura,
      Observacion: observaciones,
      DianTipoDocumentoElectronicoId: 5,
      ProveedorId: 0,
      CodigoTipoTransmision: tipoDeTransmision.toString(),
      EmpresaId: JSON.parse(localStorage.getItem('dataCompany')).companyId,
      Fecha: fechaFactura,
      EstadoDocumentoId: 0,
      ValorAPagar: valorapagar,
      DianFormaPagoId: formaPago === '1' ? 2 : 1,
      ValorDescuento: null,
      ValorCargo: valorcargos,
      Subtotal: valorbruto,
      ValorTotal: valortotal,
      ValorDescuentoDocumento: 0,
      ValorCargoDocumento: valorcargos,
      ValorIva: valoriva,
      DianMedioPagoId: paymethodid || null,
      FechaPago: !!fechapago ? fechapago : null,
      Favorito: false,
      ValorDescuentoReal: valordescuentoreal,
      ValorIvaReal: valorivareal,
      ValorTotalReal: totalfacturaReal,
      Redondeo: redondeo,
      Decimales: decimales,
      ValorRetRenta: valorretfte,
      ValorRetIva: valorretiva,
      ValorAPagarDian: valorapagardian,
      MarcaPagado: formaPago === '2' ? true : false,
      Consecutivo: numeration,
      Prefijo: prefix,
    },
    DetalleDocumento: getDetailDocumentSupport(opdetalledocumento),
    LstCargos: [],
    LstDescuentos: [],
    TipoDocumento: '05',
  };
  return generateddocument;
};

/**
 * Genera opdetalle documento soporte
 * @param {*} items
 */
const getDetailDocumentSupport = (items) => {
  const opdetalledocumento = items.map((item) => ({
    Codigo: item.codigo,
    Descripcion: item.descripcion,
    Cantidad: item.cantidad,
    ValorUnitario: item.valorneto,
    PorcentajeDescuento: item.porcentajedescuento,
    ValorDescuento: item.valordescuento,
    DianUnidadMedidaId: item.dianunidadmedidaid,
    TotalIva: item.totaliva,
    Total: item.total,
    Subtotal: item.subtotal,
    TipoDescuento: item.tipodescuento,
    TotalDescuento: item.totaldescuento,
    ValorDescuentoComercial: item.valordescuentocomercial,
    Prorrateo: item.prorrateo,
    TotalDescuentoReal: item.totaldescuentoreal,
    SubtotalReal: item.subtotalreal,
    TotalIvaReal: item.totalivareal,
    TotalReal: item.totalreal,
    PorcentajeIva: item.porcentajeiva,
    IdRetRenta: item.idretfte,
    BaseRetRenta: item.baseretfte,
    TarifaRetRenta: item.tarifaretfte,
    ValorRetRenta: item.valorretfte,
    IdRetIva: item.idretiva,
    BaseRetIva: item.baseretiva,
    TarifaRetIva: item.tarifaretiva,
    ValorRetIva: item.valorretiva,
    idstate: 0,
    createdby: 1,
    createdon: formatDate(new Date(), 'yyyy-MM-dd'),
    modifiedby: 1,
    modifiedon: formatDate(new Date(), 'yyyy-MM-dd'),
    idbusinessunit: 1,
    idowner: 1,
  }));

  return opdetalledocumento;
};
/**
 * Genera nota opdetalle documento soporte
 * @param {*} items
 */
const getDetailDocumentSupportNote = (items) => {
  const opdetalledocumento = items.map((item) => ({
    Codigo: item.codigo,
    Descripcion: item.descripcion,
    Cantidad: item.cantidad,
    ValorUnitario: item.valorneto.toFixed(2),
    PorcentajeDescuento: item.porcentajedescuento.toFixed(2),
    ValorDescuento: item.valordescuento.toFixed(2),
    DianUnidadMedidaId: item.dianunidadmedidaid,
    TotalIva: item.totaliva.toFixed(2),
    Total: item.total.toFixed(2),
    Subtotal: item.subtotal.toFixed(2),
    TipoDescuento: item.tipodescuento,
    TotalDescuento: item.totaldescuento.toFixed(2),
    ValorDescuentoComercial: item.valordescuentocomercial.toFixed(2),
    Prorrateo: item.prorrateo,
    TotalDescuentoReal: item.totaldescuentoreal.toFixed(2),
    SubtotalReal: item.subtotalreal.toFixed(2),
    TotalIvaReal: item.totalivareal.toFixed(2),
    TotalReal: item.totalreal.toFixed(2),
    PorcentajeIva: item.porcentajeiva.toFixed(2),
    IdRetRenta: item.idretfte,
    BaseRetRenta: item.baseretfte.toFixed(2),
    TarifaRetRenta: item.tarifaretfte.toFixed(2),
    ValorRetRenta: item.valorretfte.toFixed(2),
    IdRetIva: item.idretiva,
    BaseRetIva: item.baseretiva.toFixed(2),
    TarifaRetIva: item.tarifaretiva.toFixed(2),
    ValorRetIva: item.valorretiva.toFixed(2),
  }));

  return opdetalledocumento;
};
