import { ColumnsFile, Rules } from "../validations/Rules";
import { calculateTotal } from "../../../../utils/calculateInvoiceValues";
import { EXPIRATION_DAY_BULKLOAD } from "../../../../config/config";
import { formatDate } from "../../../../utils/general.js";
import { calculeInitialValuesArticle } from "../../../../actions/valuesInvoiceAction";
import { buildrequetitems } from "../helpers/build-invoice-request.helper";

const PAID_INVOICE = "Pagada";
const TO_PAY_INVOICE = "Por pagar";
const OVERDUE_INVOICE = "En mora";

/**
 * Método encargado de retornar el json de articulo con los campos necesarios para la crear una factura.
 *
 * @param {*} item Detalle de articulo
 */
const buildJsonArticle = (item) => {
  const valorUnitario = getValueItem(
    item,
    ColumnsFile.valorUnitarioColumn.name
  );
  const dateData = formatDate(new Date(), "yyyy-MM-dd");

  const articulo = getValueItem(item, ColumnsFile.refArticuloColumn.name);
  const unidadMedida = getValueItem(item, ColumnsFile.unidadMedidaColumn.name);

  const ivaValue = getValueItem(item, ColumnsFile.ivaColumn.name);
  const incValue = getValueItem(item, ColumnsFile.incColumn.name);

  const discountValue = getValueItem(
    item,
    ColumnsFile.valorDescuentoColumn.name
  );
  const discountType =
    getValueItem(item, ColumnsFile.tipoDescuentoColumn.name) === "$" ? 1 : 2;

  const reteivaResult = getValueItem(item, ColumnsFile.reteivaColumn.name);

  const objetoReteiva = {
    id: reteivaResult.id,
    descripcion: reteivaResult.descripcion,
    cantidaduvt: null,
    cantidadpesos: null,
    base: 0,
    tarifa: reteivaResult.valor,
    tiporetencionid: 3,
    tipo: reteivaResult.tipo,
    categoriaretencionid: reteivaResult.categoriaRetencionId,
    categoria: reteivaResult.categoria,
    value: reteivaResult.id,
    text: reteivaResult.descripcion,
  };

  var reteicaResult = getValueItem(item, ColumnsFile.reteicaColumn.name);

  var objetoReteica = {
    id: reteicaResult.id,
    descripcion: reteicaResult.descripcion,
    cantidaduvt: null,
    cantidadpesos: null,
    base: 0,
    tarifa: reteicaResult.valor,
    tiporetencionid: 3,
    tipo: reteicaResult.tipo,
    categoriaretencionid: reteicaResult.categoriaRetencionId,
    categoria: reteicaResult.categoria,
    value: reteicaResult.id,
    text: reteicaResult.descripcion,
  };

  var reteFuenteResult = getValueItem(item, ColumnsFile.reteFuenteColumn.name);

  var objetoRetefuente = {};

  objetoRetefuente = {
    id: reteFuenteResult.id,
    descripcion: reteFuenteResult.descripcion,
    cantidaduvt: reteFuenteResult.cantidaduvt,
    cantidadpesos: null,
    base: 0,
    tarifa: reteFuenteResult.valor,
    tiporetencionid: 3,
    tipo: reteFuenteResult.tipo,
    categoriaretencionid: reteFuenteResult.categoriaRetencionId,
    categoria: reteFuenteResult.categoria,
    value: reteFuenteResult.id,
    text: reteFuenteResult.descripcion,
  };

  return {
    codigo: !!articulo.valor ? articulo.valor : articulo,
    descripcion:
      articulo.id > 0 &&
      getValueItem(item, ColumnsFile.nombreArticuloColumn.name).length === 0
        ? articulo.nombreArticulo
        : getValueItem(item, ColumnsFile.nombreArticuloColumn.name),
    nombrearticulo:
      articulo.id > 0 &&
      getValueItem(item, ColumnsFile.nombreArticuloColumn.name).length === 0
        ? articulo.nombreArticulo
        : getValueItem(item, ColumnsFile.nombreArticuloColumn.name),
    cantidad: floatValue(getValueItem(item, ColumnsFile.cantidadColumn.name)),
    valorunitario: floatValue(valorUnitario), //[valorunit*]
    porcentajedescuento:
      discountType === 2
        ? discountValue.length === 0
          ? 0
          : floatValue(discountValue)
        : 0,
    valordescuento: discountType === 1 ? floatValue(discountValue) : 0,
    dianunidadmedidaid:
      articulo.id !== 0 ? articulo.dianUnidadMedida : unidadMedida.id,
    id: articulo.id,
    totaliva: calculateReal(floatValue(valorUnitario), floatValue(ivaValue)),
    totalinc: calculateReal(floatValue(valorUnitario), floatValue(incValue)),
    idstate: 0,
    createdby: 1,
    createdon: dateData,
    modifiedby: 1,
    modifiedon: dateData,
    idbusinessunit: 1,
    valoriva: ivaValue.length === 0 ? 0 : floatValue(ivaValue),
    valorinc: incValue.length === 0 ? 0 : floatValue(incValue),
    aplicainc: false,
    aplicaiva: false,
    idowner: 1,
    subtotal: 0, //floatValue(valorUnitario),
    tipodescuento: discountType.length === 0 ? 2 : floatValue(discountType),
    total: floatValue(valorUnitario),
    valor: floatValue(valorUnitario),
    neto: floatValue(valorUnitario),
    costo: 0,
    incluyeimpuesto: false,
    precioeditable: false,
    idretfte: reteFuenteResult.id ?? 0,
    retfte: reteFuenteResult.id !== undefined ? objetoRetefuente : null,
    idretica: reteicaResult.id ?? 0,
    retica: reteicaResult.id !== undefined ? objetoReteica : null,
    idretiva: reteivaResult.id ?? 0,
    retiva: reteivaResult.id !== undefined ? objetoReteiva : null,
  };
};

/**
 * Función para calcular los totales reales de Iva e Inc
 * @param {Valor de subtotal real} subTotReal
 * @param {Valor dlimpuesto a calcularel valor real} value
 */

function calculateReal(subTotReal, value) {
  return subTotReal * (value / 100);
}

/**
 * Metodo encargado de contruir el objeto json que se almacena en BD
 *
 * @param {*} invoiceJson  objeto con informacion cargada en el archivo excel
 * @param {*} enterpriseId id de la empresa al que pertenece el usuario
 * @param {*} userId id del usuario
 * @param {*} fileSize tamaño del archivo excel cargado
 */

export const buildJsonToPersist = (
  invoiceJson,
  enterpriseId,
  userId,
  fileSize
) => {
  return {
    empresaid: enterpriseId,
    usuarioid: userId,
    tamanioarchivoKB: fileSize,
    diavencimiento: EXPIRATION_DAY_BULKLOAD,
    factura: invoiceJson,
  };
};
/**
 * Construccion de objeto de cargos que se nevia para crear la factura si aplica
 * @param {*} data
 * @returns
 */
const buildChargue = (data) => {
  let result = [];

  data.forEach((item) => {
    let description = getValueItem(
      item,
      ColumnsFile.descripcionCargoColumn.name
    );

    let value = floatValue(
      getValueItem(item, ColumnsFile.valorCargoColumn.name)
    );

    if (description.length > 0 && value > 0) {
      let cargo = {
        cantidad: 1,
        descripcion: description,
        esglobal: false,
        id: 0,
        identificador: "",
        tipocargo: 1,
        valor: value,
        valorasumar: value,
        valorcalculado: value,
      };
      result.push(cargo);
    }
  });
  return result;
};

const buildDiscount = (data) => {
  let result = [];

  data.forEach((item) => {
    let description = getValueItem(
      item,
      ColumnsFile.descripcionDescuentoFinancieroColumn.name
    );
    let value = floatValue(
      getValueItem(item, ColumnsFile.valorDescuentoFinancieroColumn.name)
    );
    if (description.length > 0 && value > 0) {
      let descuento = {
        descripcion: description,
        id: 0,
        identificador: "",
        modelodescuento: 2,
        motivoDescripcion: "Otro descuento",
        motivoid: 12,
        tipodescuento: 1,
        valor: value,
        motivoDescuento: description,
        valoraDescontar: value,
      };
      result.push(descuento);
    }
  });

  return result;
};

/**
 * Construccion de Json de acuerdo a la estructura requerida para la generacion de la factura, tambien se le adiciona algunos datos generales d ela factura cuando llegue al segundo paso.
 * @param {*} key
 * @param {*} items
 * @param {*} errorsByInvoice
 * @param {*} enterpriseId
 * @param {*} data
 * @returns
 */
export const buildJson = (key, items, errorsByInvoice, enterpriseId, data) => {
  var charges = buildChargue(items);
  var discounts = buildDiscount(items);

  const numeracion = getFirstItem(items, ColumnsFile.resolucionColumn.name);

  /**
   * Objeto De numeracion que espera el objeto de generación de factura
   */

  const objectNumeracion = {
    id: numeracion.id,
    resolucion: numeracion.resolucion,
    fechainicio: numeracion.fechaInicio,
    fechafinal: numeracion.fechaFinal,
    prefijo: numeracion.prefijo,
    consecinicial: numeracion.consecutivoInicial,
    consecfinal: numeracion.consecutivoFinal,
    consecactual: numeracion.consecutivoActual,
    diantipomodalidadid: numeracion.dianTipoModalidadId,
    sucursalempresaid: numeracion.sucursalEmpresaId,
  };

  const vendedor = getFirstItem(items, ColumnsFile.vendedorColumn.name);
  const cliente = getFirstItem(items, ColumnsFile.clienteColumn.name);

  const objectCliente = {
    id: cliente.id,
    documento: cliente.numero,
    documentodv: cliente.digito,
    diantipodocumentoidentidadid: cliente.diantipodocumentoidentidadid,
    razonsocial: cliente.razonSocial,
    telefono: cliente.telefono,
    correoelectronico: cliente.correo,
    clientedireccion: cliente.clienteDireccion,
  };

  var objetovendedor = {
    id: vendedor.id,
    nombres: vendedor.nombres,
  };

  var round =
    getFirstItem(items, ColumnsFile.redondeoColumn.name).toUpperCase() === "SI"
      ? true
      : false;
  /**
   * Consumo de función para realizar los calculos correspondientes a la factura.
   */
  let listarticles = getAllArticles(items);

  let detaildocument = [];

  for (let i = 0; i < listarticles.length; i++) {
    const element = listarticles[i];
    let newdata = calculeInitialValuesArticle(element);
    detaildocument.push(newdata);
  }

  var totalInvoice = calculateTotal(detaildocument, charges, discounts, round);

  return {
    opdocumento: {
      fecha: convertDate(getFirstItem(items, ColumnsFile.fechaColumn.name)),
      numeracionid: numeracion?.id,
      estadodocumentoid: 0,
      tipoaceptaciondocumentoid: 1,
      tipoacusedocumentoid: 3,
      valorapagar: totalInvoice.totalapagar,
      dianformapagoid: getFirstItem(items, ColumnsFile.formaPagoColumn.name).id,
      valordescuento: totalInvoice.valordescuento,
      valorcargos: totalInvoice.valorcargos,
      subtotal: totalInvoice.valorbruto,
      totalBaseImponible: totalInvoice.valorbaseimponible,
      valortotal: totalInvoice.totalfactura,
      valordescuentodocumento: totalInvoice.valorOtrosDescuentos,
      valorcargodocumento: totalInvoice.valorcargos,
      valoranticipo: 0,
      valorinc: totalInvoice.valorinc,
      valoriva: totalInvoice.valoriva,
      textoobservacion: getFirstItem(
        items,
        ColumnsFile.observacionesColumn.name
      ),
      vendedorid: vendedor?.id,
      diantipodocumentoelectronicoid: 1,
      clienteid: cliente?.id,
      dianmediopagoid: getFirstItem(items, ColumnsFile.medioPagoColumn.name).id,
      idstate: 0,
      createdby: 1,
      modifiedby: 1,
      idbusinessunit: 1,
      idowner: 1,
      origendocumentoid: 1,
      dianconceptonotadebitoid: 0,
      dianconceptonotacreditoid: 0,
      fechapago: convertDate(
        getFirstItem(items, ColumnsFile.vencimientoColumn.name)
      ),
      favorito: false,
      empresaid: enterpriseId,
      incluirvendedor:
        getFirstItem(items, ColumnsFile.vendedorColumn.name).id !== 0,
      valordescuentoreal: totalInvoice.valordescuentoreal,
      valorivareal: totalInvoice.valorivareal,
      valorincreal: totalInvoice.valorincreal,
      totalfacturaReal: totalInvoice.totalfacturaReal,
      redondeo:
        getFirstItem(items, ColumnsFile.redondeoColumn.name).toUpperCase() ===
        "SI",
      decimales:
        getFirstItem(items, ColumnsFile.redondeoColumn.name).toUpperCase() ===
        "SI"
          ? 0
          : 2,
      valorretfte: totalInvoice.valorretfte,
      valorretica: totalInvoice.valorretica,
      valorretiva: totalInvoice.valorretiva,
      valorapagardian: totalInvoice.valorapagardian,
      vendedor: vendedor?.id > 0 ? objetovendedor : {},
      cliente: objectCliente,
      numeracion: objectNumeracion,
    },

    LstOpdetalledocumento: buildrequetitems(totalInvoice.detalleDocumento),
    lstCargos: charges,
    lstDescuentos: discounts,
    DatosAdicionales: [],

    //VALORES PARA LA INTERFAZ
    datosCargueMasivo: {
      cliente: getClienteCargueMasivo(cliente),
      fechaFactura: convertDateGeneral(
        getFirstItem(items, ColumnsFile.fechaColumn.name)
      ),
      fechaPago: convertDate(
        getFirstItem(items, ColumnsFile.vencimientoColumn.name)
      ),
      formaPago: getFirstItem(items, ColumnsFile.formaPagoColumn.name).valor,
      medioPago:
        getFirstItem(items, ColumnsFile.medioPagoColumn.name).descripcion ===
          "Acuerdo mutuo" &&
        getFirstItem(items, ColumnsFile.formaPagoColumn.name).id === 2
          ? "Crédito"
          : getFirstItem(items, ColumnsFile.medioPagoColumn.name).descripcion,
      valorTotal: totalInvoice.totalapagar,
      colorValorTotal: getColorValorTotal(
        getFirstItem(items, ColumnsFile.formaPagoColumn.name).valor,
        items
      ),
      estado: getEstado(
        getFirstItem(items, ColumnsFile.formaPagoColumn.name).valor,
        items
      ),
      colorEstado: getColorEstado(
        getFirstItem(items, ColumnsFile.formaPagoColumn.name).valor,
        items
      ),
      cantidadArticulos: getSumItem(items, ColumnsFile.cantidadColumn.name),
      erroresFactura: validateTotalInvoice(
        errorsByInvoice,
        totalInvoice.totalapagar,
        items,
        totalInvoice?.valorbase ?? 0,
        totalInvoice?.valorcargosaplicados ?? 0
      ), //errorsByInvoice
    }, //
  };
};

/*
METODOS INTERNOS
*/

const validateTotalInvoice = (
  errorsByInvoice,
  total,
  items,
  valorbase,
  valorcargosaplicados
) => {
  var errorsLoad = errorsByInvoice;
  var respuesta = {};
  if (total <= 0) {
    respuesta = {
      message: Rules.totalFactura.pattern.message,
      key: Rules.tituloTotalFacura.pattern.message,
    };

    errorsLoad.push(respuesta);
  }

  //Validar valor cargos contra base
  if (valorbase < valorcargosaplicados) {
    respuesta = {
      message: Rules.totalBaseFactura.pattern.message,
      key: ColumnsFile.valorCargoColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  if (getFirstItem(items, ColumnsFile.fechaColumn.name).length === 0) {
    respuesta = {
      message: Rules.required.required,
      key: ColumnsFile.fechaColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  if (getFirstItem(items, ColumnsFile.formaPagoColumn.name).length === 0) {
    respuesta = {
      message: Rules.required.required,
      key: ColumnsFile.formaPagoColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  if (getFirstItem(items, ColumnsFile.resolucionColumn.name).length === 0) {
    respuesta = {
      message: Rules.required.required,
      key: ColumnsFile.resolucionColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  if (getFirstItem(items, ColumnsFile.tipoDocColumn.name).length === 0) {
    respuesta = {
      message: Rules.required.required,
      key: ColumnsFile.tipoDocColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  if (getFirstItem(items, ColumnsFile.docClienteColumn.name).length === 0) {
    respuesta = {
      message: Rules.required.required,
      key: ColumnsFile.docClienteColumn.name,
      line: getFirstItem(items, "line"),
    };

    errorsLoad.push(respuesta);
  }

  return errorsLoad;
};

const getValueItem = (item, column) => {
  return item[column];
};

/**
 * Funcion para contruir objeto de cliente con información que se va a mostarr en los Cards en el paso 2 y 3
 * @param {*} cliente
 * @returns
 */
const getClienteCargueMasivo = (cliente) => {
  return {
    razonSocial: cliente.razonSocial,
    tipo: cliente.tipo,
    numero: cliente.numero,
    digito: cliente.digito,
    tipoDocumento: cliente.diantipodocumentoidentidadid,
  };
};

const getColorValorTotal = (formaPago, items) => {
  const estado = getEstado(formaPago, items);
  if (estado === PAID_INVOICE) {
    return "totalInvoiceGridPaidout";
  } else if (estado === TO_PAY_INVOICE) {
    return "totalInvoiceGridPending";
  } else if (estado === OVERDUE_INVOICE) {
    return "totalInvoiceGridExpired";
  }
  return "";
};

const getColorEstado = (formaPago, items) => {
  const estado = getEstado(formaPago, items);
  if (estado === PAID_INVOICE) {
    return "stateInvoicePaidout";
  } else if (estado === TO_PAY_INVOICE) {
    return "stateInvoicePending";
  } else if (estado === OVERDUE_INVOICE) {
    return "stateInvoiceExpired";
  }
  return "";
};

const getSumItem = (items, column) => {
  return items
    .map((item) => item[column])
    .reduce((a, b) => parseInt(a) + parseInt(b), 0);
};

const getAllArticles = (items) => {
  return items.map((item) => buildJsonArticle(item));
};

const getEstado = (formaPago, items) => {
  var dateF = new Date(new Date().toDateString());
  var fecha = getDate(getFirstItem(items, ColumnsFile.vencimientoColumn.name));

  if (formaPago === "Contado") {
    return PAID_INVOICE;
  }
  if (dateF.getTime() > fecha.getTime()) {
    return OVERDUE_INVOICE;
  } else {
    return TO_PAY_INVOICE;
  }
};

const getDate = (dateString) => {
  return new Date(dateString);
};

const getFirstItem = (items, column) => {
  return items[0][column];
};

/*const pad = (input, length, padding) => {
   var str = input + "";
   return (length <= str.length) ? str : pad(padding + str, length, padding);
}*/

const floatValue = (value) => {
  return parseFloat(value);
};
/**
 * Función para realizar el cambio correspondiente al formato de la fecha para generar la factura, en caso de que el año tenga dos dígitos se le agrega al principio el número 20.
 * @param {Fecha de facturación} date
 * @returns
 */
const convertDate = (date) => {
  var arrayDate = date.split(/\/|-/);
  var day = arrayDate[0];
  var month = arrayDate[1];
  var year = arrayDate[2];

  if (!!year && year.length === 2) {
    year = `20${year}`;
    return `${year}-${month}-${day}`;
  }
  return date
    .split("/")
    .reverse()
    .join("-");
};

/**
 * Función para realizar la trasnformacion de la fecha en caso de que se ingrese el fromato d/m/yy y convertirlo a formato d/m/yyyy
 * @param {Fcha de campo fecha} date
 * @returns
 */
const convertDateGeneral = (date) => {
  var arrayDate = date.split("/");
  var day = arrayDate[0];
  var month = arrayDate[1];
  var year = arrayDate[2];

  if (!!year && year.length === 2) {
    year = `20${year}`;
    return `${day}/${month}/${year}`;
  }

  return date
    .split("-")
    .reverse()
    .join("/");
};
