import React from 'react';

import {
  shallowEqual,
  useSelector,
} from 'react-redux';

import { Grid } from '@material-ui/core';

import { DocumentTotal } from '../documentTotal';

export const DocumentTotalSection = ({ typedocument }) => {
  const { valorapagar, totalcantidades, redondeo } = useSelector(
    (state) => state.documentCartReducer,
    shallowEqual
  );

  return (
    <Grid item lg={12} xs={12}>
      <DocumentTotal
        redondeo={redondeo}
        totalcantidades={totalcantidades}
        typedocument={typedocument}
        valorapagar={valorapagar}
      />
    </Grid>
  );
};
