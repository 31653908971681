import React, {
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import {
  Grid,
  Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  toggleCurrencyExchangeActive,
  updateCurrencyExchangeValue,
} from '../../../../actions/documentCartAction';
import ButtonSecundary
  from '../../../../components/button/buttonSecundary.component';
import InputForm from '../../../../components/input/input.component';
import SelectForm from '../../../../components/select/select.component';
import { regexDecimal } from '../../../../helpers/customRegex.hepers';
import {
  reactValidatorOptions,
} from '../../../../helpers/simpleReactValidator';
import { useFormComplete } from '../../../../hooks/useFormComplete';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    outline: 0,
    width: 400,
    borderRadius: 5,
    padding: '30px 10px 20px 10px',
  },
  title: {
    color: '#A9F5BC',
    textAlign: 'center',
    padding: '0 10px',
    display: 'block',
    marginBottom: 20,
  },
  selectContainer: {
    marginBottom: 20,
  },
  inputContainer: {
    marginBottom: 20,
  },
  confirmButton: {
    backgroundColor: '#2ecc71',
    color: 'white',
    padding: '10px',
    width: '100%',
    border: 'none',
    borderRadius: 4,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#27ae60',
    },
  },
}));

const CurrencyExchangeModal = ({ modalCurrency, onClose }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [avalableCurrency, setAvalableCurrency] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [, forceUpdate] = useState();
  const [initialValidationDone, setInitialValidationDone] = useState(false);

  const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
    .current;
  const { getCurrencyList } = useSelector((state) => state.configReducer);

  const {
    values,
    handleInputChange,
    handleUpdateForm,
    reset,
  } = useFormComplete({
    currencyValue: 0,
    currencyName: '',
  });

  const { currencyValue, currencyName } = values;

  useEffect(() => {
    if (modalCurrency) {
      setShowModal(true);
      reset();
      setAvalableCurrency(false);
      setInitialValidationDone(false);
    } else {
      setShowModal(false);
    }
  }, [modalCurrency]);

  const handleCloseModal = () => {
    setShowModal(false);
    onClose();
    reset();
  };

  const handleConfirm = () => {
    dispatch(toggleCurrencyExchangeActive(true));
    dispatch(
      updateCurrencyExchangeValue(parseFloat(currencyValue), currencyName)
    );
    handleCloseModal();
  };

  useEffect(() => {
    const hasValues =
      currencyName !== '' && currencyValue !== 0 && currencyValue !== '';

    if (hasValues) {
      const formValid = validator.allValid();

      if (!formValid) {
        validator.showMessages();
        forceUpdate(Math.random());
        setAvalableCurrency(false);
      } else {
        setAvalableCurrency(true);
      }
    } else {
      setAvalableCurrency(false);
    }

    if (!initialValidationDone) {
      setInitialValidationDone(true);
    }
  }, [currencyValue, currencyName]);

  /**
   * Limpia cero de input
   * @param {*} e
   * @param {*} name
   */
  const onClickNotZero = (e, name) => {
    let value = e.target.value;
    if (!!value) {
      value = value.replace(regexDecimal, '');
      if (value === 0 || value === '0') {
        handleUpdateForm({
          [name]: '',
        });
      }
    }
  };

  const isButtonDisabled = !avalableCurrency || !initialValidationDone;

  return (
    <Modal
      className={classes.modal}
      open={showModal}
      onClose={handleCloseModal}
    >
      <div className={classes.paper}>
        <Grid container>
          <Grid item xs={12}>
            <span className={classes.title}>
              Debes seleccionar la moneda extranjera
            </span>
          </Grid>

          <Grid item xs={12} lg={12}>
            <SelectForm
              label={'Busca la moneda'}
              name='currencyName'
              onChange={handleInputChange}
              options={getCurrencyList}
              validateOptions={'required'}
              validator={validator}
              value={currencyName}
              disabled={getCurrencyList.length === 0}
            />
          </Grid>

          {currencyName && (
            <Grid item lg={12} xs={12}>
              <InputForm
                name='currencyValue'
                value={currencyValue}
                label={'Valor de la moneda'}
                onChange={handleInputChange}
                onClick={onClickNotZero}
                validator={validator}
                validateOptions={
                  'required|noZero|noNegative|percentageTwoDecimal|currencyExchange'
                }
                format={'money'}
                adorments={currencyName}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <ButtonSecundary
              text={'Confirmar'}
              onClick={handleConfirm}
              disabled={isButtonDisabled}
            />
          </Grid>
        </Grid>
      </div>
    </Modal>
  );
};

export default CurrencyExchangeModal;
