import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import SimpleReactValidator from 'simple-react-validator';

import { Grid } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';

import ButtonSecundary
  from '../../../components/button/buttonSecundary.component';
import InputForm from '../../../components/input/input.component';
import SelectForm from '../../../components/select/select.component';
import {
  regexDecimal,
  regexOnlyNumbers,
} from '../../../helpers/customRegex.hepers';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';
import { useForm } from '../../../hooks/useForm';
import {
  limitDecimalsAndIntegers,
} from '../../../utils/limitDecimalsAndIntegers';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    outline: 0,
  },
  paper: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    outline: 0,
    width: 400,
    borderRadius: 5,
    padding: '30px 10px 20px 10px',
  },
  paperModalImage: {
    backgroundColor: theme.palette.thirdBackgroundColor,
    width: 400,

    outline: 0,
  },
  Iconclose: {
    color: theme.palette.thirdColor,
    fontSize: 15,
    padding: 0,
  },
  buttonClose: {
    textAlign: 'end',
  },
  title: {
    color: '#A9F5BC',
    textAlign: 'center',
    padding: '0 10px',
  },
  message: {
    color: '#D8D8D8',
    fontSize: 14,
    padding: '5px 10px',
    textAlign: 'justify',
  },
  endAdornment: {
    color: theme.palette.primaryColor,
  },
}));

const ModalTax = React.memo(
  ({
    open = false,
    label = 'Valor impuesto',
    options = [],
    confirmTax,
    title,
    onClose,
    addInput = [
      {
        visibleInput: false,
        labelInput: '',
        endAdornment: '',
        allowZero: false,
      },
    ],
    regexField = 'number',
    totalIntegers = 5,
    totalDecimals = 0,
  }) => {
    const classes = useStyles();
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
    const [, forceUpdate] = useState();
    const [showOtherValue, setShowOtherValue] = useState(false);
    const [optionsTax, setOptionsTax] = useState(options);
    const [idTax, setIdTax] = useState(null);
    const [formValues, handleChangeInput, reset] = useForm({
      txtTaxValue: '0',
      ddlValueTax: '',
      ...addInput.reduce(
        (acc, input) => ({ ...acc, [input.inputName]: null }),
        {}
      ),
    });

    const { txtTaxValue, ddlValueTax, ...additionalInputValues } = formValues;

    useEffect(() => {
      if (!!options && options.length > 0) {
        setOptionsTax(options);
        handleChangeInput({
          target: { name: 'ddlValueTax', value: options[0].value },
        });
        setIdTax(options[0].id);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options]);

    /**
     * * Sincroniza los cambios en un elemento de entrada (input) aplicando validaciones y límites.
     *
     * @param {Object} e - El evento de cambio (change event).
     */
    const syncChanges = (e) => {
      const regexMap = {
        number: regexOnlyNumbers,
        decimal: regexDecimal,
        // * agregar más regex según sea necesario
      };

      const selectedRegex = regexMap[regexField] || regexOnlyNumbers;

      e.target.value = e.target.value.replace(selectedRegex, '');

      e.target.value = limitDecimalsAndIntegers(
        e.target.value,
        totalDecimals,
        totalIntegers
      );

      handleChangeInput(e);
    };

    const calculateMaxLength = useMemo(
      () => (totalIntegers = 5, totalDecimals = 0) => {
        const maxLength =
          totalIntegers + totalDecimals + (totalDecimals > 0 ? 1 : 0);
        return maxLength;
      },
      []
    );

    const maxLength = useMemo(
      () => calculateMaxLength(totalIntegers, totalDecimals),
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [totalIntegers, totalDecimals]
    );

    /**
     * Valida formulario, envia información
     * @param {*} e
     */
    const handleConfirm = (e) => {
      e.preventDefault();

      if (validator.current.allValid()) {
        let value = 0;
        if (ddlValueTax.toString() !== '999')
          value = ddlValueTax !== '' ? parseFloat(ddlValueTax) : 0;
        else value = txtTaxValue !== '' ? parseFloat(txtTaxValue) : 0;

        const additionalValues = Object.values(additionalInputValues).map(
          Number
        );

        confirmTax(value, idTax, ...additionalValues);
        setIdTax(null);
        reset();
        validator.current.hideMessages();
        forceUpdate(Math.random());
      } else {
        validator.current.showMessages();
        forceUpdate(1);
      }
    };

    /**
     * Limpia valor cero por defecto de caja de texto
     * @param {} e
     */
    const onClickNotZero = (e) => {
      let value = e.target.value;

      if (value === null && value === undefined) return;

      value = value?.replace(regexDecimal, '');
      if (value === 0 || value === '0') {
        e.target.value = '';
        handleChangeInput(e);
      }
    };

    /**
     * Evento, Cambio valor impuesto
     * @param {*} e
     */
    const handleChangeOptionTax = (e) => {
      const selectedValue = parseInt(e.target.value);
      const selectedId = options.find(
        (option) => option.value === selectedValue
      ).id;
      const value = e.target.value;
      setShowOtherValue(value.toString() === '999' ? true : false);
      handleChangeInput(e);
      setIdTax(selectedId);
    };

    /**
     * Cierra modal
     */
    const handleClose = () => {
      onClose();
      setIdTax(null);
      reset();
      validator.current.hideMessages();
      forceUpdate(Math.random());
    };

    return (
      <Modal
        className={classes.modal}
        open={open}
        disableBackdropClick={false}
        onClose={handleClose}
      >
        <div className={classes.paper}>
          <Grid container spacing={1}>
            <Grid item md={12} xs={12}>
              <span className={classes.title}>{title}</span>
            </Grid>
          </Grid>
          <br />
          <Grid container spacing={1}>
            {options.length > 0 && (
              <Grid item lg={12} xs={12}>
                <SelectForm
                  label={label}
                  name='ddlValueTax'
                  value={ddlValueTax}
                  options={optionsTax}
                  validator={validator.current}
                  validateOptions={'required'}
                  onChange={handleChangeOptionTax}
                />
              </Grid>
            )}

            <div
              style={{
                display:
                  showOtherValue && ddlValueTax !== '' ? 'block' : 'none',
              }}
            >
              <Grid item lg={12} xs={12}>
                <div className={classes.message}>
                  {`Tenga en cuenta que si define un porcentaje de impuesto diferente a los establecidos por la DIAN, 
                                    usted será responsable de informar correctamente la tarifa del impuesto que reporta.`}
                </div>
              </Grid>
              <Grid item lg={12} xs={12}>
                <InputForm
                  name='txtTaxValue'
                  value={txtTaxValue}
                  label={'% de impuesto'}
                  maxLength={3}
                  onClick={onClickNotZero}
                  onChange={syncChanges}
                  validator={validator.current}
                  validateOptions={
                    showOtherValue
                      ? 'required|percentageValue|percentageTwoDecimal'
                      : 'void'
                  }
                  tooltip={true}
                  porcentage={true}
                />
              </Grid>
            </div>
            {addInput.map((input, index) => (
              <Grid item lg={12} xs={12} key={index}>
                <div
                  style={{
                    display: input.visibleInput ? 'block' : 'none',
                  }}
                >
                  <Grid item lg={12} xs={12}>
                    <InputForm
                      name={input.inputName}
                      value={additionalInputValues[input.inputName]}
                      label={input.labelInput}
                      maxLength={maxLength}
                      onClick={onClickNotZero}
                      onChange={syncChanges}
                      validator={validator.current}
                      validateOptions={
                        input.visibleInput
                          ? input.allowZero
                            ? 'required|noNegative'
                            : 'required|noZero'
                          : 'void'
                      }
                      endAdornment={
                        <span className={classes.endAdornment}>
                          {input.endAdornment}
                        </span>
                      }
                    />
                  </Grid>
                </div>
              </Grid>
            ))}
            <Grid item xs={12} lg={12}>
              <ButtonSecundary text={'Confirmar'} onClick={handleConfirm} />
            </Grid>
          </Grid>
        </div>
      </Modal>
    );
  }
);

ModalTax.propTypes = {
  options: PropTypes.array.isRequired,
  confirmTax: PropTypes.func.isRequired,
  open: PropTypes.bool,
  label: PropTypes.string,
  title: PropTypes.string,
};

export default ModalTax;
