import React, {
  useRef,
  useState,
} from 'react';

import { useSelector } from 'react-redux';
import SimpleReactValidator from 'simple-react-validator';

import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import SelectForm from '../../../components/select/select.component';
import { reactValidatorOptions } from '../../../helpers/simpleReactValidator';

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 15,
    color: 'white',
    textAlign: 'justify',
    padding: '0px 30px 25px 30px !important',
  },
  title: {
    color: '#16B1F3',
    fontSize: 25,
    fontWeight: 600,
  },
  subtitle: {
    color: '#16B1F3',
    fontSize: 18,
  },
  containerTitle: {
    textAlign: 'center',
    paddingBottom: '20px !important',
    paddingTop: '20px !important',
  },
}));

const DocSupportTypeNote = ({ onClick }) => {
  const classes = useStyles();
  const validator = useRef(new SimpleReactValidator(reactValidatorOptions));
  const [typeNote, setTypeNote] = useState('none');
  const [, forceUpdate] = useState();

  const {
    messageModalEditDocSupport,
    descripcionCorrecion,
    descripcionAnulacion,
  } = useSelector((state) => state.menuReducer.menuDocSupport);

  /**
   * * Sincroniza valor select con state
   * @param {*} e
   */
  const syncChanges = (e) => {
    setTypeNote(e.target.value);
  };

  /**
   * Valida formulario, continua proceso edición
   */
  const handleClickContinue = () => {
    const formValid = validator.current.allValid();
    if (!formValid) {
      validator.current.showMessages();
      forceUpdate(1);
    } else {
      onClick(typeNote);
    }
  };

  return (
    <Grid container spacing={1} className={classes.root}>
      <Grid item lg={12} xs={12} className={classes.containerTitle}>
        <span className={classes.title}>Editar Documento Soporte</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <div
          className={classes.subTitle}
          dangerouslySetInnerHTML={{
            __html: messageModalEditDocSupport ?? '',
          }}
        />
      </Grid>
      <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>Corrección: </span>
        <span> {descripcionCorrecion ?? ''}</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <span className={classes.subtitle}>Anulación: </span>
        <span>{descripcionAnulacion ?? ''}</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <span>¿Qué deseas hacer?</span>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems='center' justify='center'>
          <Grid item lg={6} xs={6}>
            <SelectForm
              label={''}
              name='ddlTypeNote'
              value={typeNote}
              options={[
                {
                  value: 'NC',
                  text: 'Corrección',
                },
                {
                  value: 'ANU',
                  text: 'Anulación',
                },
              ]}
              validator={validator.current}
              validateOptions={'required|notNone'}
              nameValidator={'tipoRegimen'}
              onChange={syncChanges}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12} xs={12}>
        <Grid container alignItems='center' justify='center'>
          <Grid item lg={6} xs={6}>
            <ButtonPrimary text='Continuar' onClick={handleClickContinue} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DocSupportTypeNote;
