import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Grid, makeStyles } from "@material-ui/core";
import SelectForm from "../../../components/select/select.component";
import ButtonSecundary from "../../../components/button/buttonSecundary.component";

import { formatDate } from "../../../utils/general";

const useStyles = makeStyles((theme) => ({
	containerError: {
		color: "#f44336",
		fontSize: 14,
		textAlign: "left",
		paddingTop: "2%",
	},
}));

export const NumerationInvoice = React.memo(({ handleConfirm }) => {
	const classes = useStyles();
	const [numeration, setNumeration] = useState("");
	const [errors, setErrors] = useState([]);
	const { numeraciondisponible, opdocumento } = useSelector(
		(s) => s.documentReducer
	);
	const { fecha } = opdocumento;

	const handleChangeNumeration = ({ target }) => {
		setNumeration(target.value);
	};

	/**
	 * Verifica vigencia de numeración
	 * @param {*} e
	 * @returns
	 */
	const handleConfirmNumeration = (e) => {
		e.preventDefault();

		let errorMessage = [];
		let fechadocumento = new Date(fecha).setHours(0, 0, 0, 0);
		const selectedNumeration = numeraciondisponible.find(
			(n) => n.value === numeration
		);

		let { fechainicio, fechafinal, consecactual, consecfinal } = selectedNumeration;

		fechafinal = new Date(fechafinal).setHours(0, 0, 0, 0);
		fechainicio = new Date(fechainicio).setHours(0, 0, 0, 0);

		if (!selectedNumeration) {
			errorMessage.push(
				"No se ha podido obtener información de la numeración seleccionada."
			);
		}

		if (fechadocumento > fechafinal) {
			errorMessage.push(
				`La resolución seleccionada vence el ${formatDate(
					fechafinal,
					"dd/MM/yyyy"
				)}`
			);
		}

		if (fechadocumento < fechainicio) {
			errorMessage.push(
				`La resolución seleccionada es vigente a partir del  ${formatDate(
					fechainicio,
					"dd/MM/yyyy"
				)}`
			);
		}

		if (consecactual >= consecfinal) {
			errorMessage.push(
			  `La resolución seleccionada ya no tiene rango de consecutivos disponibles.`
			);
		  }

		if (errorMessage.length === 0) {
			handleConfirm(selectedNumeration);
		} else {
			setErrors(errorMessage);
		}
	};

	return (	
		<div>
			<Grid container spacing={2} style={{width: 540}}>
				<Grid item lg={12} xs={12}>
					<SelectForm
						label={"Resolución Factura*"}
						name="numeration"
						value={numeration}
						options={numeraciondisponible}
						onChange={handleChangeNumeration}
					/>
					<div className={classes.containerError}>
						{errors.map((err, index) => (
							<div key={index}>
								<span>{`${err}.`}</span>
								<br />
							</div>
						))}
					</div>
				</Grid>

				<Grid item lg={12} xs={12}>
					{!!numeration && (
						<ButtonSecundary
							text={"Enviar Factura"}
							loading={false}
							onClick={handleConfirmNumeration}
						/>
					)}
				</Grid>
			</Grid>
		</div>
	);
});
