import React from 'react';

import { useDispatch } from 'react-redux';

import { Grid, IconButton, makeStyles } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { cleanDataConfigAction } from '../../../actions/configAction';
import { cleanDocumentCreateAction } from '../../../actions/documentAction';
// import StarBorderIcon from "@material-ui/icons/StarBorder";
// import StarIcon from "@material-ui/icons/Star";
import { cleanDocumentSupportAction } from '../../../actions/documentSupportAction';
import { cleanListReteRenta } from '../../../actions/invoiceActions';

const useStyles = makeStyles({
  button: {
    borderRadius: '15px !important',
    padding: 0,
  },
  label: {
    color: 'white',
    flexDirection: 'column',
    fontSize: 12,
  },
  labelHorizontal: {
    color: 'white',
    flexDirection: 'row',
    fontSize: 12,
  },
});

const FootPage = (
  step,
  setActiveStep,
  handleCreate,
  handleBackStep,
  handlePreviewDocument,
  edit = false
) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  // const [favorito, setFavorito] = useState(false);

  const handleCancelDocument = () => {
    if (step > 0) setActiveStep(step - 1);
    handleCreate(true, false);
    dispatch(cleanDocumentSupportAction());
    dispatch(cleanDocumentCreateAction());
    dispatch(cleanDataConfigAction());
    dispatch(cleanListReteRenta());
  };

  // const handleStandOut = () => {
  //   setFavorito(!favorito);
  //   dispatch(setFavoriteProviderAction(!favorito));
  // };

  return (
    <>
      {step === 0 ? (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <div className='bottomButtonsSupportRevision'>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  {/* <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleStandOut}
                  >
                    {favorito ? <StarIcon /> : <StarBorderIcon />}
                    <span style={{ marginTop: 5 }}>Favorito</span>
                  </IconButton> */}
                </Grid>
                <Grid item xs={6}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant='raised'
                    disableRipple={true}
                    onClick={handleCancelDocument}
                  >
                    <ClearIcon />
                    <span style={{ marginTop: 5 }}>Cancelar</span>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item lg={12} xs={12}>
            <div className='bottomButtonsSupportRevision'>
              <Grid container spacing={1}>
                <Grid item lg={3} xs={3} style={{ textAlign: 'left' }}>
                  <IconButton
                    classes={{
                      root: classes.button,
                      label: classes.labelHorizontal,
                    }}
                    variant='raised'
                    disableRipple={true}
                    onClick={() => handleBackStep()}
                    style={{ marginLeft: 10, marginTop: 10 }}
                  >
                    <ArrowBackIosIcon />
                    <span style={{ marginLeft: 5, fontSize: 14 }}>Volver</span>
                  </IconButton>
                </Grid>
                <Grid item lg={3} xs={3}>
                  {/* <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant="raised"
                    disableRipple={true}
                    onClick={handleStandOut}
                  >
                    {favorito ? <StarIcon /> : <StarBorderIcon />}
                    <span style={{ marginTop: 5 }}>Favorito</span>
                  </IconButton> */}
                </Grid>
                <Grid item lg={3} xs={3}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant='raised'
                    disableRipple={true}
                    onClick={handlePreviewDocument}
                  >
                    <VisibilityIcon />
                    <span style={{ marginTop: 5 }}>Previsualizar</span>
                  </IconButton>
                </Grid>

                <Grid item lg={3} xs={3}>
                  <IconButton
                    classes={{ root: classes.button, label: classes.label }}
                    variant='raised'
                    disableRipple={true}
                    onClick={handleCancelDocument}
                    style={{ marginRight: 10 }}
                  >
                    <ClearIcon />
                    <span style={{ marginTop: 5 }}>Cancelar</span>
                  </IconButton>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default FootPage;
