import React, { useEffect, useCallback, useState } from "react";
import { useDispatch, useSelector, shallowEqual } from "react-redux";

import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import DocumentForm from "./documentForm.view";
import { CustomersList } from "../../customers/customersList";
import { CustomerCard } from "../customer/customerCard";
import { EmptyCustomer } from "../customer/emptyCustomer";
import { CollapseFormPanel } from "../../../components/ui/collapseFormPanel";
import CustomProgress from "../../../components/Progress/progress.component";
import { CustomerForm } from "../../customers/customerForm";
import { CollapseDataPanel } from "../../../components/ui/collapseDataPanel";
import { AdditionalData } from "../common/additionalData";
import { CustomFielForm } from "../common/customFielForm";
import { CustomFieldInput } from "../common/customFieldInput";
import ButtonInvoice from "../../../components/button/buttonPrincipalInvoice.component";
import ButtonPrimary from "../../../components/button/buttonPrimary.component";

import {
  getCustomFieldListAction,
  deleteCustomFieldAction,
} from "../../../actions/customFiledAction";
import {
  loadHeadDocumentAction,
  deleteCustomerDocumentAction,
} from "../../../actions/documentAction";
import {
  addCustomerInDocument,
  loadCustomerActive,
  setCustomerIdAction,
} from "../../../actions/customerAction";
import { cleanDataAddressAction } from "../../../actions/addressCustomerAction";
import { cleanDataContactAction } from "../../../actions/contactCustomerAction";

import { ConfirmAlert } from "../../../helpers/alert.helpers";
import { formatDate } from "../../../utils/general";

import { MESSAGE_DELETE_CONFIRM } from "../../../config/config";

const useStyles = makeStyles((theme) => ({
  containerManage: {
    overflow: "auto",
    height: "73vh",
    width: "100%",
    overflowX: "hidden",
  },
  containerError: {
    color: "#f44336",
    fontSize: 14,
    textAlign: "left",
    paddingTop: "2%",
  },
}));

export const DocumentManage = ({
  handleChangeStep,
  HandleBulkUpload,
  InformationBulk,
  StateBulk,
  typedocument,
  origin,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [expandPanelCustomer, setExpandPanelCustomer] = useState(false);
  const [expandPanelCustomData, setExpandPanelCustomData] = useState(false);
  const [customfields, setCustomField] = useState({});
  const [errors, setErrors] = useState([]);

  const {
    customFieldsList,
    loadingGetFields,
    loadingDeleteField,
  } = useSelector((state) => state.customFieldReducer, shallowEqual);

  const { adnumeracion } = useSelector(
    (state) => state.invoiceReducer.aditionaldataenterprise,
    shallowEqual
  );

  const {
    loadingDetail: loadingGetCustomer,
    customerActive,
    customerId,
    loading: loadingList,
  } = useSelector((state) => state.customerReducer, shallowEqual);

  const {
    adcliente,
    //direccionclienteid,
    datosadicionales,
    opdatosadicionales,
    escontingencia,
  } = useSelector((state) => state.documentReducer);

  const { opdocumentosreferencia } = useSelector(
    (state) => state.documentReferenceReducer
  );

  //Leer dirección principal
  const principalAddress = !!adcliente
    ? {
        id: adcliente.direccionid,
        descripcion: "Principal",
        direccion: adcliente.direccion,
        ciudaddescripcion: adcliente.ciudaddescripcion,
        departamentodescripcion: adcliente.departamentodescripcion,
      }
    : null;

  /**
   * Cargue Inicial.
   */
  useEffect(() => {
    dispatch(getCustomFieldListAction(opdatosadicionales));
  }, [dispatch, opdatosadicionales]);

  /**
   * Datos almacenados en BD para empresa
   */
  useEffect(() => {
    for (let i = 0; i < customFieldsList.length; i++) {
      let item = customFieldsList[i];
      setCustomField({
        ...customfields,
        [item.codigo]: !!customfields[item.codigo]
          ? customfields[item.codigo]
          : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customFieldsList]);

  /**
   * Carga datos adicionales ya diligenciados
   */
  useEffect(() => {
    setCustomField({
      ...customfields,
      ...datosadicionales,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [datosadicionales]);

  /**
   * Carga cliente en formulario si esta abierto
   */
  useEffect(() => {
    if (!!adcliente && expandPanelCustomer === true) {
      dispatch(loadCustomerActive(adcliente));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adcliente, dispatch]);

  /**
   * Carga id cliente seleccionado
   */
  useEffect(() => {
    if (!!adcliente && !customerId) {
      dispatch(setCustomerIdAction(adcliente.id));
    }
  }, [adcliente, customerId, dispatch]);

  /**
   * Limpia formulario cuando se cierra panel
   */
  useEffect(() => {
    if (!!customerActive && !expandPanelCustomer) {
      dispatch(loadCustomerActive(null));
      dispatch(cleanDataContactAction());
      dispatch(cleanDataAddressAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, expandPanelCustomer]);

  /**
   * Carga información cliente en formulario para edición.
   * @param {*} customer
   */
  const handleEditCustomer = useCallback(
    (data) => {
      dispatch(loadCustomerActive(data));
      setExpandPanelCustomer(true);
    },
    [dispatch]
  );

  /**
   * Elimina cliente seleccionado para documento.
   */
  const handleDeleteCustomer = useCallback(() => {
    setExpandPanelCustomer(false);
    dispatch(deleteCustomerDocumentAction());
    dispatch(setCustomerIdAction(0));
  }, [dispatch]);

  /**
     * Limpia formulario al contraer collapse.
     * @param {
     } status 
     */
  const handleExpandCustomer = () => {
    setExpandPanelCustomer((prev) => !prev);
  };

  /**
   * Contrae panel nuevo cliente
   */
  const handleCollapsePanelCustomer = () => {
    setExpandPanelCustomer(false);
  };

  /**
   * Contrae expande collapse datos personalzados
   */
  const handleExpandCustomData = () => {
    setExpandPanelCustomData((prev) => !prev);
  };

  /**
   * Carga cliente en documento
   * @param {*} id
   */
  const handleSelectionCustomer = (id) => {
    dispatch(addCustomerInDocument(id));
  };

  /**
   * Sincroniza valores, informacion personalizada para facturación
   * @param {*} e  Evento
   */
  const handleFieldChange = useCallback(
    ({ target }) => {
      setCustomField((prev) => ({
        ...prev,
        [target.name]: target.value,
      }));
    },
    [setCustomField]
  );

  /**
   * Evento, Elimina nuevo campo cliente
   * @param {*} data Info campo
   */
  const handleDeleteField = ({ id, guardar }) => {
    const confirm = (confirmed) => {
      if (confirmed) dispatch(deleteCustomFieldAction(id, guardar));
    };
    ConfirmAlert(
      `¿Estás seguro de eliminar este campo?`,
      confirm,
      MESSAGE_DELETE_CONFIRM
    );
  };

  /**
   * Valida información diligenciada, continua paso 2
   * @param {*} form
   */
  const handleContinueToNext = (form) => {
    let errorMessage = [];
    let fechaInvalida = false;
    let consecutivosDisponibles = true;

    if (form === null)
      errorMessage.push("Tienes campos pendientes por diligenciar");

    if (!adcliente) errorMessage.push("Debes seleccionar un cliente");

    if (!!form && typedocument === "FA") {
      let fecha = new Date(form.fecha).setHours(0, 0, 0, 0);
      const numeracion = adnumeracion.find((n) => n.id === form.numeracionid);

      if (!!numeracion) {
        let fechafinal = new Date(numeracion.fechafinal).setHours(0, 0, 0, 0);
        let fechainicio = new Date(numeracion.fechainicio).setHours(0, 0, 0, 0);

        if (fecha > fechafinal) {
          errorMessage.push(
            `La resolución seleccionada vence el ${formatDate(
              fechafinal,
              "dd/MM/yyyy"
            )}`
          );
          fechaInvalida = true;
        }

        if (fecha < fechainicio) {
          errorMessage.push(
            `La resolución seleccionada se venció desde el ${formatDate(
              fechainicio,
              "dd/MM/yyyy"
            )}`
          );
          fechaInvalida = true;
        }
        let consecactual = parseInt(numeracion.consecactual, 10);
        let consecfinal = parseInt(numeracion.consecfinal, 10);

        // Validar si los valores son números válidos
        if (isNaN(consecactual) || isNaN(consecfinal)) {
          errorMessage.push(`Los valores de consecutivos no son válidos`);
          consecutivosDisponibles = false;
        } else if (consecactual > consecfinal) {
          errorMessage.push(
            `La resolución seleccionada ha alcanzado el límite de consecutivos`
          );
          consecutivosDisponibles = false;
        }
      } else {
        errorMessage.push(`Resolución no válida`);
      }

      if (escontingencia) {
        const contingency = opdocumentosreferencia.filter(
          (c) => c.tipodocumentoid.toString() === "9"
        );

        if (contingency.length <= 0) {
          errorMessage.push(
            `Es necesario agregar un documento de referencia tipo "Factura talonario"`
          );
        }
      }
    }

    setErrors([...errorMessage]);

    if (
      errorMessage.length === 0 &&
      !fechaInvalida &&
      consecutivosDisponibles
    ) {
      handleChangeStep(1);
      dispatch(
        loadHeadDocumentAction(
          form,
          customfields,
          customFieldsList,
          opdocumentosreferencia
        )
      );
    }
  };

  return (
    <Grid
      container
      spacing={3}
      direction="row"
      justify="center"
      alignItems="stretch"
    >
      <Grid item lg={4} xs={12}>
        <CustomersList handleSelection={handleSelectionCustomer} type={0} />
      </Grid>

      <Grid
        item
        lg={8}
        xs={12}
        className={`animate__animated animate__fadeIn animate__faster`}
      >
        <div className={classes.containerManage}>
          <Grid container spacing={2}>
            <Grid item lg={12} xs={12}>
              <CollapseFormPanel
                component={
                  <CustomerForm
                    handleCollapsePanel={handleCollapsePanelCustomer}
                    loadForDocument={true}
                  />
                }
                title={"Crear nuevo cliente"}
                titleOpen={
                  !!customerActive ? "Editar cliente" : "Nuevo cliente"
                }
                open={expandPanelCustomer}
                onClick={handleExpandCustomer}
                disabled={loadingGetCustomer || loadingList}
              />
            </Grid>
            {loadingGetCustomer ? (
              <Grid item lg={12} xs={12} style={{ textAlign: "center" }}>
                <CustomProgress size={20} />
              </Grid>
            ) : (
              <>
                {!!adcliente ? (
                  <Grid item lg={12} xs={12}>
                    <CustomerCard
                      {...adcliente}
                      handleEdit={() => handleEditCustomer(adcliente)}
                      handleDelete={handleDeleteCustomer}
                    />
                  </Grid>
                ) : (
                  <Grid item lg={12} xs={12}>
                    <EmptyCustomer />
                  </Grid>
                )}
              </>
            )}

            <Grid item lg={12} xs={12}>
              <DocumentForm
                handleContinueToNext={handleContinueToNext}
                typedocument={typedocument}
                origin={origin}
                responsabilidadtributariacliente={
                  adcliente?.responsabilidadtributariaid
                }
              />
            </Grid>

            <Grid item lg={12} xs={12}>
              <CollapseFormPanel
                component={<CustomFielForm />}
                title={"Añadir datos"}
                titleOpen={"Nuevo dato cliente"}
                open={expandPanelCustomData}
                onClick={handleExpandCustomData}
              />
            </Grid>

            <Grid item lg={12} xs={12}>
              {loadingGetFields && <CustomProgress />}
              <Grid container spacing={2}>
                {customFieldsList.map(
                  (item) =>
                    !item.funcion && (
                      <Grid key={item.id} item lg={6} xs={12}>
                        <CustomFieldInput
                          name={item.codigo}
                          label={item.codigo}
                          value={customfields[item.codigo] ?? ""}
                          onChange={handleFieldChange}
                          handleDelete={() => handleDeleteField(item)}
                          loading={item.id === loadingDeleteField}
                          format={item.tipodato}
                        />
                      </Grid>
                    )
                )}
              </Grid>
            </Grid>

            <Grid item lg={12} xs={12}>
              <CollapseDataPanel
                component={
                  <AdditionalData
                    principalAddress={principalAddress}
                    addresses={adcliente?.adclientedireccion ?? []}
                    contacts={adcliente?.adcontactos ?? []}
                    typedocument={typedocument}
                  />
                }
              />
            </Grid>
            <Grid item lg={12} xs={12}>
              <div className={classes.containerError}>
                {errors.map((err, index) => (
                  <div key={index}>
                    <span>{`${err}.`}</span>
                    <br />
                  </div>
                ))}
              </div>
            </Grid>

            <Grid item lg={12} xs={12}>
              <Grid container spacing={2}>
                <Grid item lg={6} xs={12}>
                  {typedocument === "FA" && (
                    <ButtonInvoice
                      text={"Carga masiva de facturas"}
                      icon={
                        <img
                          src={require("../../../images/icons/Upload.png")}
                          alt={"cloud"}
                        />
                      }
                      onClick={HandleBulkUpload}
                      disabled={
                        InformationBulk && StateBulk !== 0 ? true : false
                      }
                      buttonSize={"53px"}
                      margin={"-10px"}
                    />
                  )}
                </Grid>
                <Grid item lg={6} xs={12}>
                  <ButtonPrimary
                    type={"submit"}
                    text={"Continuar"}
                    form={"head-form"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </Grid>
  );
};
