import React, {
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import Grid from '@material-ui/core/Grid';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepLabel from '@material-ui/core/StepLabel';
import Stepper from '@material-ui/core/Stepper';

import { cleanDataConfigAction } from '../../../actions/configAction';
import { cleanDocumentCreateAction } from '../../../actions/documentAction';
import {
  changeShowCartAction,
  showCartDocumentSupportAction,
} from '../../../actions/documentCartAction';
import {
  cleanDocumentSupportAction,
  createNoteAdjusment,
  getDocumentSupportPreviewAction,
} from '../../../actions/documentSupportAction';
import {
  cleanLicenseBaseAction,
  cleanListReteRenta,
  updateOpenModalItem,
} from '../../../actions/invoiceActions';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import ArchiveModal from '../../../components/modal/archiveModal.component';
import ResponseModal from '../../../components/modal/responseModal.component';
import ResponseRedirectModal
  from '../../../components/modal/responseRedirectModal.component';
import SimpleModal from '../../../components/modal/simpleModal.component';
import {
  CLAIM_DOCUMENTS,
  MESSAGE_NEXT_CONFIRM,
} from '../../../config/config';
import { ConfirmAlert } from '../../../helpers/alert.helpers';
import { validateDocument } from '../../../helpers/documentValidate';
import {
  generateRequestNoteAdjusment,
} from '../../../helpers/generateRequestDocument';
import {
  errorToast,
  successToast,
  warningToast,
} from '../../../helpers/toast.helpers';
import {
  DocumentCartManage,
} from '../../document/cart/documentCartManage.view';
import FootPage from '../CreateDocumentSupport/FootPage';
import AnnulmentNoteDocSupport from './AnnulmentNoteDocSupport';
import DocSupportTypeNote from './DocSupportTypeNote';
import ReviewDocumentUpdate from './ReviewDocumentUpdate';
import {
  basePlanExpired,
  getSteps,
  initialModal,
  initialModalResponse,
  modalLoading,
  modalResponseObject,
  QontoConnector,
  QontoStepIcon,
  useStyles,
} from './StylesFunctionUpdate';
import { validateStepTwo } from './ValidateStepTwo';

const UpdateDocSupportView = ({ handleEdit }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState(1);
  const [completed, setCompleted] = useState(new Set());
  const [modalResponse, setModalResponse] = useState(initialModalResponse);
  const steps = getSteps();
  const [loadingFile, setLoadingFile] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);
  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [base64File, setBase64File] = useState(null);
  const [openModalAnnulment, setOpenModalAnnulment] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalLicenseResponse, setModalLicenseResponse] = useState(
    initialModal
  );
  const [loadingNoteAnnulment, setLoadingNoteAnnulment] = useState(false);
  const totals = useSelector((state) => state.documentCartReducer);

  const { detailDocumentSupport, documentSupportFilter } = useSelector(
    (state) => state.documentSupportReducer
  );

  const { openModalItem: modal, licenseBase } = useSelector(
    (state) => state.invoiceReducer
  );

  const {
    showCatalog,
    valorapagar,
    opdetalledocumento,
    observacionesnota,
    motivonota,
  } = totals;

  /**
   * *Cargue incial
   */
  useEffect(() => {
    // dispatch(getReasonNotes());
    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < 1; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
  }, []);

  /**
   * *Activa y desctiva el carrito  de documento soporte
   */
  useEffect(() => {
    if (activeStep === 1) {
      dispatch(changeShowCartAction(false));
      dispatch(showCartDocumentSupportAction(true));
      return;
    }
    dispatch(changeShowCartAction(true));
    dispatch(showCartDocumentSupportAction(false));
  }, [activeStep, dispatch]);

  useEffect(() => {
    if (loading) {
      setModalResponse(modalLoading);
    }
  }, [loading]);

  useEffect(() => {
    if (licenseBase?.result && !licenseBase.result.licenseActiveBase) {
      setModalLicenseResponse(basePlanExpired);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [licenseBase]);

  /**
   * *Marca paso a completo
   * @param {*} step
   * @returns
   */
  const isStepComplete = (step) => {
    return completed.has(step);
  };

  const handleBackStep = () => {
    if (activeStep !== 1) {
      setActiveStep(activeStep - 1);
    } else {
      handleEdit(true, false);
      dispatch(cleanDocumentSupportAction());
      dispatch(cleanDocumentCreateAction());
    }
  };

  /**
   * * Lee respuesta, consulta PDF documento
   * @param {*} response
   */
  const readResponseGetFile = (response) => {
    setBase64File(response);
    setLoadingFile(false);
  };

  //  * Genera previsualización de documento
  //  * @param {*} e
  //  */
  const handlePreviewDocument = (e) => {
    e.preventDefault();
    if (validateDocument(totals, totals?.cartDocumentSupport)) {
      if (
        validateStepTwo(
          opdetalledocumento,
          motivonota,
          observacionesnota,
          valorapagar,
          detailDocumentSupport
        )
      ) {
        setOpenModalFile(true);
        setLoadingFile(true);
        const docSupport = documentSupportFilter.find(
          (item) => item.id === detailDocumentSupport.id
        );

        docSupport.codigoUnico = detailDocumentSupport.codigoUnico;
        docSupport.codigoTipoTransmision =
          detailDocumentSupport.codigoTipoTransmision;
        const note = generateRequestNoteAdjusment(docSupport, totals);

        dispatch(getDocumentSupportPreviewAction(note, readResponseGetFile));
      }
    }
  };

  /**
   * * Evento click abrir modal confirmación tipo nota
   */
  const handleOpenModalAnnulment = () => {
    setOpenModalAnnulment(true);
  };

  /**
   * * Evento click cerrar modal anular Documento Soporte
   */
  const handleCloseModalAnnulment = () => {
    setOpenModalAnnulment(false);
  };

  /**
   * * Confirmar anulación factura
   */
  const confirmAnnulmentDocSupport = (observations) => {
    setLoadingNoteAnnulment(true);
    const docSupport = documentSupportFilter.find(
      (item) => item.id === detailDocumentSupport.id
    );
    const note = generateRequestNoteAdjusment(
      docSupport,
      totals,
      observations,
      true
    );
    dispatch(
      createNoteAdjusment(note, confirmSendOkAnnulment, confirmErrorAnnulment)
    );
  };

  /**
   *  * Lee response exitoso anulación Documento Soporte
   * @param {*} response
   */
  const confirmSendOkAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response.data.statusCode === '201')
      successToast(
        '',
        `El Documento Soporte ${detailDocumentSupport.prefijo}-${detailDocumentSupport.consecutivo} fue anulada de manera exitosa. Sigamos facturando`,
        'top-center'
      );
    else
      warningToast(
        'Ocurrió algo inesperado',
        `El Documento Soporte  ${detailDocumentSupport.prefijo}-${detailDocumentSupport.consecutivo} fue anulada de manera exitosa, ${response.data.result}. ${response.data.statusMessage}`
      );

    handleEdit(true, false);
  };

  /**
   * * Lee response con error anulación Documento Soporte
   * @param {*} response
   */
  const confirmErrorAnnulment = (response) => {
    setLoadingNoteAnnulment(false);
    setOpenModalAnnulment(false);
    if (response?.data?.statusMessage !== 'Unauthorized, no quota') {
      errorToast(
        'Ocurrió algo inesperado',
        `${JSON.stringify(
          response?.data?.statusMessage ??
            'Error inesperado, no se ha podido anular el Documeto Soporte'
        )}`
      );
    }
    handleEdit(true, false);
  };

  /**
   * * Envia documento soporte para registro como nota ajuste
   * @param {*} e
   */
  const handleSaveNote = (e) => {
    e.preventDefault();

    if (
      validateStepTwo(
        opdetalledocumento,
        motivonota,
        observacionesnota,
        valorapagar,
        detailDocumentSupport
      )
    ) {
      const senddocument = (confirmed) => {
        if (confirmed) {
          setLoading(true);
          const docSupport = documentSupportFilter.find(
            (item) => item.id === detailDocumentSupport.id
          );
          const note = generateRequestNoteAdjusment(docSupport, totals);
          dispatch(
            createNoteAdjusment(note, readResponseSaveNote, readErrorSaveNote)
          );

          dispatch(cleanListReteRenta());
        }
      };
      ConfirmAlert(
        'Al editar el Documento Soporte se generará una Nota de Ajuste ' +
          ' ¿Está seguro que desea hacerlo?',
        senddocument,
        MESSAGE_NEXT_CONFIRM
      );
    }
  };

  const readResponseSaveNote = (response) => {
    setLoading(false);
    const { prefijo, consecutivo } = documentSupportFilter.find(
      (item) => item.id === detailDocumentSupport.id
    );
    const responseModal = modalResponseObject(
      response,
      `${prefijo}-${consecutivo}`
    );
    setModalResponse(responseModal);
  };

  const readErrorSaveNote = (response) => {
    setLoading(false);
    const statusCode = response.data.statusCode;
    const statusMessage = response.data.statusMessage;
    if (statusCode === '404' && statusMessage === 'Unauthorized, no quota') {
      setModalResponse(initialModalResponse);
    }
  };

  /**
   * * Avanza paso siguiente
   */
  const handleNextStep = (step) => {
    if (step === 2) {
      if (
        !validateStepTwo(
          opdetalledocumento,
          motivonota,
          observacionesnota,
          valorapagar,
          detailDocumentSupport
        )
      )
        return;
    }
    //* Marca paso 1 como completo
    let newCompleted;
    let i = 0;
    newCompleted = new Set();
    for (i === 0; i < step; i++) {
      newCompleted.add(i);
    }
    setCompleted(newCompleted);
    setActiveStep(step);
  };

  /**
   * * Cierra modal mensaje envio documento
   * @param {*} rta
   */
  const handleResponseModal = (rta) => {
    if (rta === 1) {
      handleEdit(true, false);
      dispatch(cleanDocumentSupportAction());
      dispatch(cleanDocumentCreateAction());
      dispatch(cleanDataConfigAction());
    }
    setModalResponse(initialModalResponse);
  };

  /**
   * * Cerrar modal licencia vencida
   */
  const handleCloseResponseModal = () => {
    setModalLicenseResponse({
      ...modalLicenseResponse,
      open: false,
    });
    dispatch(cleanLicenseBaseAction());
  };

  /**
   *  * Cierra modal, mensaje plan sin cupo
   * @param {*} e
   */
  const onCloseModalItem = (e) => {
    dispatch(updateOpenModalItem(false));
  };

  /**
   * * Evento click cerrar modal confirmación tipo nota
   */
  const handleCloseModalEdit = () => {
    setOpenModalEdit(false);
  };

  /**
   * * Cierra modal previsualizació documento
   */
  const handleCloseFileModal = () => {
    setOpenModalFile(false);
    setBase64File(null);
  };

  /**
   * Confirmar tipo nota a generar
   * @param {*} type Tipo nota
   */
  const confirmEditInvoice = (type) => {
    if (type === 'ANU') {
      setOpenModalEdit(false);
      setOpenModalAnnulment(true);
    } else {
      setOpenModalEdit(false);
    }
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <div></div>;
      case 1:
        return (
          <>
            <DocumentCartManage typedocument={'DS_NOTE'} />
            {!showCatalog && (
              <Grid container style={{ marginTop: 20 }}>
                <Grid item xl={11} lg={12} sm={12} xs={12}>
                  <Grid container spacing={1}>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type='button'
                        onClick={handleOpenModalAnnulment}
                        text={'Anular Documento Soporte'}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type='button'
                        onClick={() => handleNextStep(2)}
                        text={'Revisar Nota'}
                      />
                    </Grid>
                    <Grid item lg={4} xs={12}>
                      <ButtonPrimary
                        type='button'
                        onClick={handleSaveNote}
                        text={'Enviar Nota'}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            {FootPage(
              1,
              setActiveStep,
              handleEdit,
              handleBackStep,
              handlePreviewDocument,
              true
            )}
          </>
        );
      case 2:
        return (
          <>
            <ReviewDocumentUpdate />
            <Grid container style={{ marginTop: 20 }}>
              <Grid item xl={12} lg={12} sm={12} xs={12}>
                <Grid container spacing={1} justify='center'>
                  <Grid item lg={4} xs={12}>
                    <ButtonPrimary
                      type='button'
                      onClick={handleSaveNote}
                      text={'Enviar Nota'}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {FootPage(
              2,
              setActiveStep,
              handleEdit,
              handleBackStep,
              handlePreviewDocument,
              true
            )}
          </>
        );
      default:
        return 'Unknown step';
    }
  };
  return (
    <div className='container-form' xs={12} lg={12}>
      <Stepper
        alternativeLabel
        nonLinear
        activeStep={activeStep}
        connector={<QontoConnector />}
        className={classes.root}
      >
        {steps.map((label, index) => {
          const stepProps = {};
          const buttonProps = {};
          return (
            <Step
              key={label}
              {...stepProps}
              className={activeStep === index ? classes.activeButton : ''}
            >
              <StepButton completed={isStepComplete(index)} {...buttonProps}>
                <StepLabel StepIconComponent={QontoStepIcon}>
                  <label className='stepper-title'>{label}</label>
                </StepLabel>
              </StepButton>
            </Step>
          );
        })}
      </Stepper>
      <div>{getStepContent(activeStep)}</div>
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={() => handleResponseModal(modalResponse.onCloseElement)}
        closeFromModalBody={modalResponse.closeFromModalBody}
      />
      <SimpleModal
        onClose={handleCloseModalEdit}
        open={openModalEdit}
        title={''}
        width={800}
        noPadding={true}
        component={() => <DocSupportTypeNote onClick={confirmEditInvoice} />}
      />
      <SimpleModal
        onClose={handleCloseModalAnnulment}
        open={openModalAnnulment}
        title={''}
        width={800}
        noPadding={true}
        disabled={loadingNoteAnnulment}
        disableBackdropClick={loadingNoteAnnulment}
        component={() => (
          <AnnulmentNoteDocSupport
            onClick={confirmAnnulmentDocSupport}
            number={
              `${detailDocumentSupport.prefijo}${detailDocumentSupport.consecutivo}` ??
              ''
            }
            loading={loadingNoteAnnulment}
          />
        )}
      />
      <ResponseRedirectModal
        modalType={'success'}
        title={'Upss...!!!'}
        subtitle={
          'Ya usaste todos los documentos disponibles de tu plan. \n No te preocupes, consigue todos los que necesites'
        }
        body={''}
        modalImage={'package'}
        open={modal}
        closeElement={'Skip'}
        onCloseElement={onCloseModalItem}
        claim={CLAIM_DOCUMENTS}
      />
      <ResponseModal
        modalType={modalLicenseResponse.modalType}
        title={modalLicenseResponse.title}
        body={modalLicenseResponse.body}
        modalImage={modalLicenseResponse.modalImage}
        open={modalLicenseResponse.open}
        textButton={modalLicenseResponse?.textButton}
        closeElement={modalLicenseResponse.closeElement}
        onCloseElement={handleCloseResponseModal}
        closeFromModalBody={modalLicenseResponse.closeFromModalBody}
      />

      <ArchiveModal
        loading={loadingFile}
        open={openModalFile}
        pdfBase64={base64File}
        handleClose={handleCloseFileModal}
      />
    </div>
  );
};

export default UpdateDocSupportView;
