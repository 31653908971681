import { DIGITS_AFTER_DECIMAL } from '../config/config';
import { types } from '../types/types';

const initialState = {
  showCatalog: true,
  cartDocumentSupport: false,
  opdetalledocumento: [],
  opdocumentocargos: [],
  opdocumentodescuentos: [],
  monedaExtranjera: {
    activo: false,
    valorMoneda: 0,
    codigoMoneda: '',
  },
  retiva: null,
  retica: null,
  valorapagar: 0,
  totalcantidades: 0,
  valorcargos: 0,
  valordescuento: 0,
  valorbruto: 0,
  valorapagardian: 0,
  valortotal: 0,
  valoriva: 0,
  valorinc: 0,
  valordescuentoreal: 0,
  valorivareal: 0,
  valorincreal: 0,
  totalfacturaReal: 0,
  redondeo: true,
  decimales: 0,
  valorretfte: 0,
  valorretica: 0,
  valorretiva: 0,
  valorotrosdescuentos: 0,
  valorcargosaplicados: 0,
  valorbase: 0,
  motivonota: null,
  observacionesnota: '',
  digitsAfterDecimalDoc: DIGITS_AFTER_DECIMAL,
};

export const documentCartReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SHOW_CATALOG:
      return {
        ...state,
        showCatalog: action.payload,
      };
    case types.UPDATE_TOTALS_DOCUMENT:
      return {
        ...state,
        ...action.payload,
      };
    case types.UPDATE_ITEM_DOCUMENT:
      return {
        ...state,
        opdetalledocumento: state.opdetalledocumento.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case types.ADD_RETE_IVA_DOCUMENT:
      return {
        ...state,
        retiva: action.payload,
      };
    case types.ADD_RETE_ICA_DOCUMENT:
      return {
        ...state,
        retica: action.payload,
      };
    case types.UPDATE_ROUND_DOCUMENT:
      return {
        ...state,
        redondeo: action.payload,
      };
    case types.ADD_OBSERVATIONS_NOTE:
      return {
        ...state,
        observacionesnota: action.payload ?? '',
      };
    case types.ADD_REASON_NOTE:
      return {
        ...state,
        motivonota: action.payload,
      };
    case types.CLEAN_DOCUMENT_CART:
      return {
        ...initialState,
      };
    case types.SHOW_CART_DOCUMENT_SUPPORT:
      return {
        ...state,
        cartDocumentSupport: action.payload,
      };
    case types.DIGITS_AFTER_DECIMAL_TYPE_DOC:
      return {
        ...state,
        digitsAfterDecimalDoc: action.payload,
      };
    case types.TOGGLE_CURRENCY_ACTIVE:
      return {
        ...state,
        monedaExtranjera: {
          ...state.monedaExtranjera,
          activo: action.payload,
        },
      };
    case types.UPDATE_CURRENCY_EXCHANGE_VALUE:
      return {
        ...state,
        monedaExtranjera: {
          ...state.monedaExtranjera,
          valorMoneda: action.payload.valorMoneda,
          codigoMoneda: action.payload.codigoMoneda,
        },
      };
    default:
      return state;
  }
};
