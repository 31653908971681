import { createSelector } from 'reselect';

const CURRENCY_FIELD_MAPPING = {
  valorConversionMonedaExtranjera: 'valorconversionmonedaextranjera',
  monedaExtranjera: 'monedaextranjera',
  subtotalConvertido: 'subtotal',
  valordescuentoConvertido: 'valordescuento',
  valorivarealConvertido: 'valorivareal',
  valorIncConvertido: 'valorincreal',
  valorIbuaConvertido: 'Valoribua',
  valorIcuiConvertido: 'Valoricui',
  valorAdvConvertido: 'Valoradv',
  valorInpConvertido: 'Valorinpp',
  valorIclConvertido: 'Valoricl',
  totalfacturarealConvertido: 'totalfacturareal',
  valordescuentodocumentoConvertido: 'valordescuentodocumento',
  valorcargpdocumentoConvertido: 'valorcargodocumento',
  valoranticipoConvertido: 'valoranticipo',
  valorapagardianConvertido: 'valorapagardian',
  valorretfteConvertido: 'valorretfte',
  valorreticaConvertido: 'valorretica',
  valorretivaConvertido: 'valorretiva',
  valorOtrosImpuestosConvertido: 'valorOtrosImpuestos',
};

const getMonedaExtranjera = (state) =>
  state.documentCartReducer.monedaExtranjera;
const getDocumentCartValues = (state) => state.documentCartReducer;

const calculateOtherTaxes = (ibua, icui, adv, inp, icl) => {
  return [ibua, icui, adv, inp, icl].reduce(
    (sum, tax) => sum + (Number(tax) || 0),
    0
  );
};

export const currencyExchangeSelector = createSelector(
  [getMonedaExtranjera, getDocumentCartValues],
  (monedaExtranjera, documentCart) => {
    const defaultValues = Object.keys(CURRENCY_FIELD_MAPPING).reduce(
      (acc, key) => {
        acc[key] = null;
        return acc;
      },
      {}
    );

    if (!monedaExtranjera?.activo || !monedaExtranjera?.valorMoneda) {
      return defaultValues;
    }

    const transformedValues = {
      valorConversionMonedaExtranjera: monedaExtranjera.valorMoneda,
      monedaExtranjera: monedaExtranjera.codigoMoneda,
      subtotalConvertido: documentCart.valorbruto,
      totalBrutoConvertido: documentCart.valorbaseimponible,
      valordescuentoConvertido: documentCart.valordescuento,
      valorivarealConvertido: documentCart.valorivareal,
      valorIbuaConvertido: documentCart.valoribuareal,
      valorIcuiConvertido: documentCart.valoricuireal,
      valorAdvConvertido: documentCart.valoradvreal,
      valorInpConvertido: documentCart.valorinppreal,
      valorIclConvertido: documentCart.valoriclreal,
      valorIncConvertido: documentCart.valorincreal,
      totalfacturarealConvertido: documentCart.totalfacturaReal,
      valordescuentodocumentoConvertido: documentCart.valorotrosdescuentos,
      valorcargpdocumentoConvertido: documentCart.valorcargos,
      valoranticipoConvertido: documentCart.valoranticipo,
      valorapagardianConvertido: documentCart.valorapagardian,
      valorretfteConvertido: documentCart.valorretfte,
      valorreticaConvertido: documentCart.valorretica,
      valorretivaConvertido: documentCart.valorretiva,
      valorOtrosImpuestosConvertido: calculateOtherTaxes(
        documentCart.valoribuareal,
        documentCart.valoricuireal,
        documentCart.valoradvreal,
        documentCart.valorinppreal,
        documentCart.valoriclreal
      ),
    };

    return Object.entries(transformedValues).reduce((acc, [key, value]) => {
      if (
        key === 'monedaExtranjera' ||
        key === 'valorConversionMonedaExtranjera'
      ) {
        acc[key] = value;
      } else {
        const numberValue =
          value !== undefined && value !== null ? Number(value) : 0;
        acc[key] = parseFloat(
          (numberValue / monedaExtranjera.valorMoneda).toFixed(4)
        );
      }
      return acc;
    }, {});
  }
);

export const transformCurrencyTotalsToDocument = (currencyTotals) => {
  if (!currencyTotals.monedaExtranjera) {
    return null;
  }

  const documentFields = Object.entries(CURRENCY_FIELD_MAPPING)
    .filter(([_, documentKey]) => documentKey !== 'valorOtrosImpuestos')
    .reduce((acc, [selectorKey, documentKey]) => {
      acc[documentKey] = currencyTotals[selectorKey];
      return acc;
    }, {});

  return documentFields;
};
