import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import { Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Radio from '@material-ui/core/Radio';
import {
  makeStyles,
  withStyles,
} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import {
  addCustomReteRenta,
  getListReteFuenteAction,
  getListReteRentaAction,
} from '../../../actions/invoiceActions';
import ButtonPrimary from '../../../components/button/buttonPrimary.component';
import InputIcon from '../../../components/input/inputIcon.component';
import {
  withHoldingListSelector,
} from '../../../selectors/Invoice/withHoldingListSelector';
import ModalTax from '../../catalogCategory/article/modalTax.component';

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    backgroundColor: 'rgba(255,255,255,0.11)',
    maxHeight: 400,
  },
  table: {
    minWidth: 650,
    maxHeight: 200,
    '& .MuiTableCell-head': {
      color: '#F5D13D',
      fontSize: 13,
    },
    '& .MuiTableCell-body': {
      color: 'white',
      fontSize: 12,
    },
    '& .MuiTableCell-root': {
      borderBottom: '0.5px solid gray',
    },
  },
  searchPanel: {
    marginTop: 10,
    marginBottom: 30,
  },
  noResult: {
    color: theme.palette.primaryColor,
    padding: 5,
  },
}));

const CustomRadio = withStyles((theme) => ({
  root: {
    color: 'gray',
    '&$checked': {
      color: theme.palette.secundaryColor,
    },
    '& .MuiFormControlLabel-labelPlacementStart': {
      marginLeft: 0,
      marginRight: 5,
    },
  },
  checked: {},
}))((props) => <Radio {...props} />);

export const ReteFuenteTable = React.memo(
  ({ onClickApplyToOne, onClickApplyToAll, activeRteFuente }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [selectedValue, setSelectedValue] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [filter, setFilter] = React.useState('');
    const [currentList, setCurrentList] = useState([]);
    const [selectedTax, setSelectedTax] = useState(null);

    const { listReteFuente, listReteRenta } = useSelector(
      withHoldingListSelector
    );

    const { cartDocumentSupport } = useSelector(
      (state) => state.documentCartReducer
    );

    /**
     * Cargue inicial
     */
    useEffect(() => {
      dispatch(getListReteFuenteAction());
      dispatch(getListReteRentaAction());
    }, [dispatch]);

    useEffect(() => {
      const list = !cartDocumentSupport
        ? listReteFuente
        : listReteRenta.filter((c) => c.estarifadian || c.isCustom === true);

      setCurrentList(list);
    }, [listReteFuente, cartDocumentSupport, listReteRenta]);

    useEffect(() => {
      if (!!activeRteFuente) {
        setSelectedValue(activeRteFuente.id);
        setSelectedTax(activeRteFuente);
      }
    }, [activeRteFuente]);

    /**
     * Sincroniza nuevo valor del filtos con state,
     * filtra listado
     * @param{*} e Evento
     */
    const handleChangeFilter = ({ target }) => {
      setFilter(target.value);
      let newList = handleFilterList(target.value);
      setCurrentList(newList);
    };

    /**
     * Marca seleccion de retención
     * @param {*} tax
     */
    const handleSelectedTax = (tax) => {
      setSelectedValue(tax.id);
      setSelectedTax(tax);
    };

    const prepareSelectedTax = () => {
      const retRentaObj = listReteRenta.find(
        (c) => !c.estarifadian && !c.isCustom
      );
      const { id } = retRentaObj;

      return selectedTax.isCustom ? { ...selectedTax, id } : selectedTax;
    };

    /**
     * Evento, click aplicar retencion a todos los articulos
     */
    const handleClickApplyToAll = () => {
      const taxToApply = prepareSelectedTax();
      onClickApplyToAll(taxToApply);
    };

    /**
     * Evento, click aplicar retencion a articulo actual
     * @param {*} e Evento
     */
    const handleClickApplyToOne = () => {
      const taxToApply = prepareSelectedTax();
      onClickApplyToOne(taxToApply);
    };

    /**
     * Filtra lista de acuerdo a parametros
     * @param {*} filter cadena para filtro
     */
    const handleFilterList = (filter) => {
      const listRet = !cartDocumentSupport ? listReteFuente : listReteRenta;
      if (!!filter && filter !== '') {
        let newList = listRet.filter(
          (c) =>
            c.categoria
              ?.toLowerCase()
              .match(new RegExp(filter.toLowerCase() + '.*')) ||
            c.descripcion
              ?.toLowerCase()
              .match(new RegExp(filter.toLowerCase() + '.*')) ||
            c.cantidaduvt
              ?.toLowerCase()
              .match(new RegExp(filter.toLowerCase() + '.*')) ||
            c.cantidadpesos
              ?.toLowerCase()
              .match(new RegExp(filter.toLowerCase() + '.*')) ||
            c.tarifa.toString().match(new RegExp(filter.toLowerCase() + '.*'))
        );
        return newList;
      }
      return listRet;
    };

    const handleCloseModal = useCallback(() => {
      setOpenModal(false);
    }, []);

    const handleAddReteRenta = useCallback(
      (...arg) => {
        const valueReteRenta = parseFloat(arg[2]);
        const existingCustomTax = listReteRenta.find(
          (c) => c.isCustom === true && c.tarifa === valueReteRenta
        );

        if (!existingCustomTax) {
          const templateRentaData = listReteRenta.find((c) => !c.estarifadian);
          const newCustomTaxId = `ReteRenta_${Date.now()}`;

          const newCustomTax = {
            ...templateRentaData,
            id: newCustomTaxId,
            tarifa: valueReteRenta,
            isCustom: true,
          };

          dispatch(addCustomReteRenta(newCustomTax));
        }

        handleCloseModal();
      },
      [listReteRenta, dispatch, handleCloseModal]
    );

    return (
      <Grid container>
        <Grid item lg={6} sm={6} xs={12} className={classes.searchPanel}>
          <InputIcon
            name={'filter'}
            label={''}
            maxLength={100}
            value={filter}
            onChange={handleChangeFilter}
          />
        </Grid>
        {cartDocumentSupport && (
          <Grid item lg={6} sm={6} xs={12} className={classes.searchPanel}>
            <ButtonPrimary
              text={'Agregar otra Rete Renta'}
              type={'button'}
              onClick={() => setOpenModal(true)}
              small={false}
            />
          </Grid>
        )}
        <br />
        <Grid item lg={12} xs={12}>
          <TableContainer
            component={Paper}
            className={`${classes.tableContainer} animate__animated animate__fadeIn animate__faster`}
          >
            <Table className={classes.table} aria-label='simple table'>
              <TableHead>
                <TableRow>
                  <TableCell>Concepto</TableCell>
                  <TableCell>Descripción</TableCell>
                  <TableCell>Cuantía mínima en UVT</TableCell>
                  <TableCell>Cuantía mínima en pesos</TableCell>
                  <TableCell>Tarifa</TableCell>
                  <TableCell>Seleccionar</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {currentList.map((tax) => (
                  <TableRow key={tax.id}>
                    <TableCell>{tax.categoria}</TableCell>
                    <TableCell>{tax.descripcion}</TableCell>
                    <TableCell>{tax.cantidaduvt}</TableCell>
                    <TableCell>{tax.cantidadpesos}</TableCell>
                    <TableCell>{`${tax.tarifa}%`}</TableCell>
                    <TableCell>
                      <CustomRadio
                        checked={selectedValue === tax.id}
                        onChange={() => handleSelectedTax(tax)}
                        value={tax.id}
                        name='radio-button'
                      />
                    </TableCell>
                  </TableRow>
                ))}

                {currentList.length === 0 && (
                  <span
                    className={`${classes.noResult} animate__animated animate__fadeIn animate__faster`}
                  >
                    No se han encontrado resultados...
                  </span>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
        <Grid lg={12} xs={12}>
          <Grid container>
            <Grid item lg={6} xs={12}>
              <ButtonPrimary
                text={'Aplicar a todos los artículos'}
                type={'button'}
                onClick={handleClickApplyToAll}
                small={true}
                disabled={selectedValue === null}
              />
            </Grid>
            <Grid lg={6} xs={12}>
              <ButtonPrimary
                text={'Aplicar a este artículo'}
                type={'button'}
                onClick={handleClickApplyToOne}
                small={true}
                disabled={selectedValue === null}
              />
            </Grid>
          </Grid>
        </Grid>
        <ModalTax
          open={openModal}
          confirmTax={handleAddReteRenta}
          title={'Agregar Rete Renta'}
          onClose={handleCloseModal}
          addInput={[
            {
              visibleInput: true,
              labelInput: 'Porcentaje de Rete Renta',
              endAdornment: '%',
              inputName: 'reteRentaValue',
              allowZero: true,
            },
          ]}
          regexField={'decimal'}
          totalIntegers={2}
          totalDecimals={2}
        />
      </Grid>
    );
  }
);
