import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import ClearIcon from "@material-ui/icons/Clear";
import infografiaCargaMasiva from "../../../../images/infografia/cargaMasiva.png";
import * as XLSX from "xlsx";
import ValidateField from "../validations/ValidateField";
import { Rules, ColumnsFile } from "../validations/Rules";
import { blue } from "@material-ui/core/colors";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FlareComponent from "flare-react";
import LoadingAnimation from "../../../../images/animations/cargando1.flr";
import { getComponentName } from "../../../../utils/general.js";
import { convertBase64ToExcel } from "../helpers/excel.helpers";
import {
  getFirstPositionSplit,
  removeSpaces,
  getErrorsbyInvoice,
  groupByLine,
  groupByInvoice,
  getFirstPositionSplitMedioPago,
} from "../helpers/util-functions.helpers";
import { addBackValidationsGeneral } from "../helpers/back-validations.helpers";
import { buildJsonToPersist, buildJson } from "../helpers/json-bulk-load.helpers";

import {
  saveUploadedInvoiceAction,
  checkValidationsAction,
  changeStepAction,
  cleanValidationAction,
  getUploadedInvoiceAction,
  cleanStateGetBulkLoadAction,
  cleanStatusAction,
} from "../../../../actions/invoiceActions";
import { getTemplateBulkUploadAction, cleanTemplateAction } from "../../../../actions/configAction";

import ResponseModal from "../../../../components/modal/responseModal.component";

import { enterpriseIdHeader } from "../../../../actions/authActions";
import isEmpty from "../../../../utils/isEmpty";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 5,
    height: 90,
  },
  input: {
    display: "none",
  },
  infografia: {
    width: "100%",
  },
  btnInfografia: {
    width: "100%",
  },
  content: {
    paddingTop: 0,
    paddingLeft: 58,
  },
  dialog: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    "& .MuiPaper-root": {
      backgroundColor: "#393939",
      color: theme.palette.primaryColor,
      height: "464px",
      width: "1000px",
      borderRadius: "5px",
    },
  },
  backgroundContent: {
    color: theme.palette.primaryColor,
  },
  subTitle: {
    color: theme.palette.primaryColor,
    fontSize: 18,
    textAlign: "center",
  },
  title: {
    color: theme.palette.thirdColor,
    fontSize: 25,
    fontWeight: "bold",
    textAlign: "center",
  },
  loading: {
    verticalAlign: "middle",
    padding: "50px",
    justifyContent: "center",
    display: "flex",
  },
  btnEnviar: {
    position: "absolute",
    top: "0px",
    left: "0px",
    right: "0px",
    bottom: "0px",
    width: "100%",
    height: "100%",
    opacity: 0,
  },
  divFile: {
    position: "relative",
    width: "100%",
    height: "54px",
    borderRadius: "4px",
    backgroundColor: "#16B1F3",
    border: "#16B1F3",
    color: "#FFFFFF",
    fontSize: "17px",
    fontWeight: 600,
    letterSpacing: "0",
    textAlign: "center",
    fontFamily: "Muli",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: blue[800],
    },
    padding: "1px",
    margin: "-3px,",
  },
  buttonSuccess: {
    height: "54px",
    width: "100%",
    borderRadius: "4px",
    backgroundColor: "#16B1F3",
    border: "#16B1F3",
    color: "#FFFFFF",
    fontSize: "17px",
    fontWeight: 600,
    letterSpacing: "0",
    textAlign: "center",
    fontFamily: "Muli",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: blue[800],
    },
  },
  cancelOption: {
    color: "#FFFFFF",
  },
}));

const InfoUpload = (props) => {
  const classes = useStyles();

  const {
    components,
    enterpriseId,
    userId,
    cancelOption,
    validate,
    statusCodeRegister,
    getTemplate,
    stepChange,
    statusGetBulk,
    countInvoice,
  } = props;

  const [openmodalinformation, setopenmodalinformation] = useState(false);
  const [contentDialog, setContentDialog] = useState("");
  const [initValidation, setInitValidation] = useState("");
  const [process, setProcess] = useState({});
  const [jsonPer, setJsonPer] = useState(null);
  const [modalResponse, setModalResponse] = useState({
    type: "",
    title: "",
    subtitle: "",
    body: "",
    modalImage: "",
    open: false,
    closeElement: "",
    onCloseElement: 0,
  });
  //const [headerReference, setHeadersReference] = useState();
  //const [dataStringLiness,setdataStringLiness] = useState();

  var fileSize;
  const fileHeader = [
    ColumnsFile.agrupacionColumn.name,
    ColumnsFile.redondeoColumn.name,
    ColumnsFile.fechaColumn.name,
    ColumnsFile.formaPagoColumn.name,
    ColumnsFile.vencimientoColumn.name,
    ColumnsFile.medioPagoColumn.name,
    ColumnsFile.vendedorColumn.name,
    ColumnsFile.resolucionColumn.name,
    ColumnsFile.observacionesColumn.name,
    ColumnsFile.tipoDocColumn.name,
    ColumnsFile.docClienteColumn.name,
    ColumnsFile.clienteColumn.name,
    ColumnsFile.reteicaColumn.name,
    ColumnsFile.reteivaColumn.name,
    ColumnsFile.descripcionCargoColumn.name,
    ColumnsFile.valorCargoColumn.name,
    ColumnsFile.descripcionDescuentoFinancieroColumn.name,
    ColumnsFile.valorDescuentoFinancieroColumn.name,
    ColumnsFile.totalFilasColumn.name,
    ColumnsFile.refArticuloColumn.name,
    ColumnsFile.nombreArticuloColumn.name,
    ColumnsFile.cantidadColumn.name,
    ColumnsFile.unidadMedidaColumn.name,
    ColumnsFile.valorUnitarioColumn.name,
    ColumnsFile.tipoDescuentoColumn.name,
    ColumnsFile.valorDescuentoColumn.name,
    ColumnsFile.ivaColumn.name,
    ColumnsFile.incColumn.name,
    ColumnsFile.reteFuenteColumn.name,
  ];

  const inputValidations = [
    Rules.agrupacionColumn,
    Rules.siNoOption,
    Rules.fechaColumn,
    Rules.noRequired,
    Rules.vencimientoColumn,
    Rules.medioColumn,
    Rules.vendedorColumn,
    Rules.resolucionColumn,
    Rules.observacionesColumn,
    Rules.noRequired,
    Rules.noRequired,
    Rules.noValidations,
    Rules.dosDecimales,
    Rules.dosDecimales,
    Rules.descripcionColumn,
    Rules.decimalesCargo,
    Rules.descripcionColumn,
    Rules.decimalesCargo,
    Rules.totalFilasColumn,
    Rules.referenciaArticuloColumn,
    Rules.nombreArticuloColumn,
    Rules.cantidadColumn,
    Rules.unidadMedidaColumn,
    Rules.valorUnitColumn,
    Rules.tipoDescuentoColumn,
    Rules.dosDecimales,
    Rules.dosDecimales,
    Rules.dosDecimales,
    Rules.dosDecimales,
  ];

  const showValidationErrors = (body) => {
    setModalResponse({
      type: "warning",
      title: "Upss...!!!",
      subtitle: "Ocurrió algo inesperado",
      body: <div>{body}</div>,
      modalImage: "warning",
      open: true,
      closeElement: "Saltar",
      onCloseElement: 1,
    });
  };

  /**
   * Validaciones pre-cargue y cargue de columnas y validaciones
   * de tipo de datos del excel
   * @param {*} e
   */
  const initialValidations = (e) => {
    setopenmodalinformation(true);
    e.preventDefault();
    if (!!e.target) {
      const file = e.target.files[0];
      //Extensiones admitidas
      const admitedExtentions = ["xls", "xlsx", "xlsm", "csv"];
      //Tamaño Màximo permitido del archivo
      const sizeMax = 500;
      //Validacion de formatos
      if (!validateExtention(file, admitedExtentions)) {
        showValidationErrors("solo se aceptan los siguientes formatos " + admitedExtentions);
        e.target.value = "";
        return;
      } else if (!validateSize(file, sizeMax)) {
        //validacion de Peso Màximo
        showValidationErrors("Recuerda que el tamaño del archivo no puede superar los" + sizeMax + "Megabytes");
        e.target.value = "";
        return;
      }

      const reader = new FileReader();
      reader.onload = (evt) => {
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];

        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws, {
          header: 1,
          blankrows: false,
          RS: "#COL2021FR\n",
        });

        setInitValidation(data);
      };

      reader.readAsBinaryString(file);
      e.target.value = "";
    }
  };

  /**
   * Validación de la extensión
   *
   * @param {*} file
   * @param {*} admitedExtentions
   */
  function validateExtention(file, admitedExtentions) {
    var ext = file.name.split(".");
    var extention = ext[ext.length - 1];
    return admitedExtentions.includes(extention);
  }

  /**
   * Validación del tamaño del archivo
   *
   * @param {*} file
   * @param {*} sizeMax
   */
  function validateSize(file, sizeMax) {
    //var fileSize = file.size / 1024 / 1024; // in MB
    fileSize = file.size / 1024; // in KB
    return fileSize <= sizeMax;
  }

  /**
   * Valdiación  del tamaño y nombre de columnas del archivo
   *
   * @param {*} header
   * @param {*} fileHeader
   */
  function validateHeader(header, fileHeader) {
    if (header.length === fileHeader.length) {
      for (let index = 0; index < header.length; index++) {
        if (header[index] !== fileHeader[index]) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  const readLine = (line) => {
    if (line.slice(-1) === ",") {
      return line.slice(0, -1);
    }
    return line;
  };

  /**
   * Función para conocer la cantidad de facturas disponibles a emitir.
   */
  function invoiceLicence() {
    let invoiceLicence;
    if (!!countInvoice)
      countInvoice.map((billigs) => {
        invoiceLicence = billigs.billings;
        return invoiceLicence;
      });

    return invoiceLicence;
  }

  /**
   *  process CSV data, Leer información de excel
   * @param {archivo en Strin} dataString
   * @param {cabecera} fileHeader
   * @param {Validaciones} inputValidations
   */
  const processData = (dataString) => {
    const dataStringLinesTmp = dataString.split(/#COL2021FR\n/);

    const dataStringLines = [];

    for (let i = 0; i < dataStringLinesTmp.length; i++) {
      if (dataStringLinesTmp[i] !== ",,,,,,,,,,,,,,,,,,,,,,,,,,,,,,," && dataStringLinesTmp[i] !== "") {
        dataStringLines.push(dataStringLinesTmp[i]);
      }
    }

    const headersTmp = readLine(dataStringLines[0]);
    const headers = headersTmp.replace(",,", "").split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

    //Se valida que la cabecera tenga las columnas definidas en la plantilla
    if (!validateHeader(readLine(headers), fileHeader)) {
      showValidationErrors("El archivo que estas cargando no corresponde a la plantilla, Colfactura no puede interpretarlo.");
      setopenmodalinformation(false);
      return;
    }

    const list = [];
    const results = [];
    const validation = new ValidateField(inputValidations);

    if (dataStringLines.length === 1) {
      showValidationErrors("El archivo debe tener por lo menos un registro.");
      setopenmodalinformation(false);
      return;
    }

    // Agrupa y cuenta cantidad de documentos a procesar
    const groupInvoice = new Set(
      dataStringLines.map((item) => {
        return item.split(",")[0];
      })
    );
    const invoicesCount = groupInvoice.size - 1;

    // Valida disponibilidad con licenciamiento
    if (invoicesCount > invoiceLicence()) {
      showValidationErrors(`No tiene cupo para emitir facturas, actualmente cuenta con ${invoiceLicence()} factura(s) disponible(s)`);
      setopenmodalinformation(false);
      return;
    }

    for (let i = 1; i < dataStringLines.length; i++) {
      const rowTmp = readLine(dataStringLines[i]).split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

      if (rowTmp[28] === undefined) {
        rowTmp[28] = "";
      }

      //Obtiene información de fila, igualando con encabezados
      const row = rowTmp.slice(0, headers.length);

      if (headers && row.length === headers.length) {
        const obj = {};

        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] === '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] === '"') d = d.substring(d.length - 2, 1);
          }

          d = readLine(d);
          d = removeUnwantedCharacters(d, headers[j]);
          if (headers[j]) {
            obj[headers[j]] = d;
          }

          const result = validation.validate(d, j, i, headers[j]);
          if (result) {
            results.push(result);
          }
        }

        if (Object.values(obj).filter((x) => x).length > 0) {
          list.push(obj);
        }
      }
    }

    const medioPagoList = list.map((item) => getFirstPositionSplitMedioPago(item[ColumnsFile.medioPagoColumn.name], "-"));

    const medioPago = [...new Set(medioPagoList)].map((medio) => {
      return { valor: medio };
    });

    const formaPagoList = list.map((item) => item[ColumnsFile.formaPagoColumn.name]);

    const formaPago = [...new Set(formaPagoList)].map((forma) => {
      return { valor: forma };
    });

    const reteIvaList = list.map((item) => item[ColumnsFile.reteivaColumn.name]);

    const reteIva = [...new Set(reteIvaList)].map((iva) => {
      return { valor: parseFloat(iva).toString() };
    });

    const reteIcaList = list.map((item) => item[ColumnsFile.reteicaColumn.name]);

    const reteIca = [...new Set(reteIcaList)].map((iva) => {
      return { valor: parseFloat(iva).toString() };
    });

    const reteFuenteList = list.map((item) => item[ColumnsFile.reteFuenteColumn.name]);

    const reteFuente = [...new Set(reteFuenteList)].map((iva) => {
      return { valor: parseFloat(iva).toString() };
    });

    const clienteList = list.map(
      (item) => getFirstPositionSplit(item[ColumnsFile.tipoDocColumn.name], "-") + "-" + item[ColumnsFile.docClienteColumn.name]
    );
    const cliente = [...new Set(clienteList)].map((cliente) => {
      const tipoNumero = cliente.trim().split("-");
      return { tipo: tipoNumero[0], numero: tipoNumero[1] };
    });

    const vendedorList = list.map((item) => removeSpaces(item[ColumnsFile.vendedorColumn.name]));

    const vendedor = [...new Set(vendedorList)].map((vend) => {
      return { valor: vend };
    });

    const prefijoList = list.map((item) => item[ColumnsFile.resolucionColumn.name]);
    const prefijo = [...new Set(prefijoList)].map((art) => {
      return { valor: art };
    });

    const articuloList = list.map((item) => item[ColumnsFile.refArticuloColumn.name]);
    const articulo = [...new Set(articuloList)].map((art) => {
      return { valor: art };
    });

    const unidadMedidaList = list.map((item) => getFirstPositionSplit(item[ColumnsFile.unidadMedidaColumn.name], "-"));
    const unidadMedida = [...new Set(unidadMedidaList)].map((unidad) => {
      return { valor: unidad };
    });

    const fechaList = list.map((item) => item[ColumnsFile.fechaColumn.name]);

    const inputList = {
      empresaId: enterpriseIdHeader(),
      medioPago: medioPago,
      formaPago: formaPago,
      cliente: cliente,
      vendedor: vendedor,
      prefijo: prefijo,
      articulo: articulo,
      unidadMedida: unidadMedida,
      reteIva: reteIva,
      reteIca: reteIca,
      reteFuente: reteFuente,
    };

    var objeto = {
      list: list,
      inputList: inputList,
      medioPagoList: medioPagoList,
      formaPagoList: formaPagoList,
      clienteList: clienteList,
      vendedorList: vendedorList,
      prefijoList: prefijoList,
      articuloList: articuloList,
      unidadMedidaList: unidadMedidaList,
      reteIvaList: reteIvaList,
      reteIcaList: reteIcaList,
      reteFuenteList: reteFuenteList,
      fechaList: fechaList,
      results: results,
    };
    setProcess(objeto);
  };

  const ObtenerCabeceras = (lista, nombreColumna) => {
    const agrupados = lista.reduce((acc, item) => {
      const clave = item[ColumnsFile.agrupacionColumn.name];
      if (!acc[clave]) {
        acc[clave] = [];
      }
      acc[clave].items.push({ ...item, resolucion: acc[clave].resolucion });
      return acc;
    }, {});

    const cabeceras = Object.keys(agrupados).map((clave) => agrupados[clave][0]);
    return cabeceras.map((item) => {
      return { valor: item[nombreColumna] };
    });
  };

  /**
   * Función para validar las respuestas del back, y validar cada una de las las celdas para agregar su respectiva validacion si aplica
   * @param {Objeto de respuesta de validacion de campos} data
   * @param {Arreglo con los datos que se enviaron para realizar su respectiva validación.} objectResponse
   */
  const validarFront = (data) => {
    try {
      addBackValidationsGeneral(data, process, process.results, process.list);
    } catch (error) {
      console.log("Error validación -->", error);
    }

    let errorsByLine = groupByLine(process.results);

    process.list.forEach((item, index) => {
      const errors = errorsByLine[index + 1];
      if (errors) {
        item.errors = errors;
      }
    });

    let groupByInvoiceMap = groupByInvoice(process.list);
    var invoiceJson = [];

    Object.entries(groupByInvoiceMap).forEach(([key, value]) => {
      const errorsByInvoice = getErrorsbyInvoice(value);
      invoiceJson.push({
        valido: errorsByInvoice && errorsByInvoice.length === 0,
        objeto: JSON.stringify(buildJson(key, value, errorsByInvoice, enterpriseId)),
      });
    });

    const jsonToPersist = buildJsonToPersist(invoiceJson, enterpriseId, userId, 0);

    setJsonPer(jsonToPersist);
  };

  /**
   * Elimina caracteres que no se desean en determinadas columnas
   *
   * @param {} value
   * @param {*} header
   */
  const removeUnwantedCharacters = (value, header) => {
    if (
      (value && header === ColumnsFile.valorUnitarioColumn.name) ||
      (value && header === ColumnsFile.valorCargoColumn.name) ||
      (value && header === ColumnsFile.valorDescuentoFinancieroColumn.name)
    ) {
      value = value
        .replace("$", "")
        .replace(/,/g, "")
        .replace(" ", "");
      return value;
    }
    return value;
  };

  /*const handleClose = () => {
    setopenmodalinformation(false);
  };*/

  useEffect(
    () => {
      if (getTemplate !== null) {
        convertBase64ToExcel(getTemplate);
        props.cleanTemplateAction();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getTemplate]
  );

  useEffect(
    () => {
      if (initValidation.length > 0) {
        processData(initValidation);
        setContentDialog("Validando Cliente(s)");

        setInitValidation("");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [initValidation]
  );

  useEffect(
    () => {
      if (process.inputList !== null || process.inputList !== undefined) {
        props.checkValidationsAction(process.inputList);
        setContentDialog("Validando Medios de pago");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [process]
  );

  useEffect(
    () => {
      props.cleanValidationAction();
      if (!!validate) {
        if (validate.statusCode === "200" && !!validate.result) {
          validarFront(validate.result, process);
          props.cleanValidationAction();
        }

        if (validate.statusCode === "400" && !validate.result) {
          setopenmodalinformation(false);
          showValidationErrors("No se diligenció la totalidad de la información en el archivo de cargue. Por favor verifica la información");
          props.cleanValidationAction();
          setInitValidation("");
        }
      }

      setContentDialog("Validando Articulo(s)");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [validate]
  );

  useEffect(
    () => {
      if (jsonPer !== null) {
        props.saveUploadedInvoiceAction(jsonPer);
        setContentDialog("Validando Prefijo(s) y Resolución");
      }
      setContentDialog("Validando Formas de pago");
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [jsonPer]
  );

  useEffect(
    () => {
      if (statusCodeRegister === "201") {
        props.getUploadedInvoiceAction(1);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [statusCodeRegister]
  );

  useEffect(() => {
    if (statusGetBulk === "200" && stepChange === 0) {
      props.changeStepAction(1);
      props.cleanStatusAction();
    }
  });

  const handleResponseModal = () => {
    setModalResponse({
      open: false,
    });
  };

  const dialogUploadinRegister = () => {
    return (
      <Dialog open={openmodalinformation} onClose={false} aria-labelledby="form-dialog-title" className={classes.dialog}>
        <DialogTitle id="form-dialog-title">
          <center>
            <span className={classes.title}>Carga masiva de facturas</span>
          </center>
        </DialogTitle>
        <DialogContent className={classes.backgroundContent}>
          <DialogContentText>
            <div className={classes.loading}>{<FlareComponent width={200} height={200} animationName="music_walk" file={LoadingAnimation} />}</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className="backgroundContent">
          <Grid container>
            <Grid item lg={12} xs={12}>
              <div className={classes.subTitle}>{contentDialog}</div>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <Box>
      <div className={classes.cancelOption}>
        <div className="spacingInvoice"></div>
        <Grid container spacing={1}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <center>
              <span className={classes.title}>{getComponentName(components, 108, 198, "Carga masiva de facturas")}</span>
            </center>
            <br></br>
            <center>
              <img className={classes.infografia} src={infografiaCargaMasiva} alt={"electronicInvoiceIcon"} />
            </center>
          </Grid>
          <Grid container>
            <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
            <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
              <div className={classes.btnInfografia}>
                <button className={classes.buttonSuccess} type="submit" onClick={() => props.getTemplateBulkUploadAction()}>
                  Descargar Plantilla
                </button>
              </div>
            </Grid>
            <Grid item lg={2} md={2} sm={2} xs={2} xl={2} />
            <br />
            <Grid item lg={4} md={4} sm={12} xs={12} xl={4}>
              <center>
                <button className={classes.divFile}>
                  <p id="texto">Cargar Plantilla Masiva</p>
                  <input type="file" className={classes.btnEnviar} accept=".csv,.xlsx,.xls,.xlsm" onChange={initialValidations} />
                </button>
              </center>
            </Grid>

            <Grid item lg={1} md={1} sm={1} xs={1} xl={1} />
          </Grid>
        </Grid>
        <br></br>

        <div className="bottomButtonsInvoiceRevision">
          <Grid container spacing={1}>
            <Grid item lg={12} sm={12}>
              <ClearIcon className="buttonInvoiceBehavior" onClick={() => cancelOption()}></ClearIcon>
              <br></br>
              <div className="textButtonsInvoice" style={{ textAlign: "center" }}>
                Cancelar
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
      {dialogUploadinRegister()}
      <ResponseModal
        modalType={modalResponse.modalType}
        title={modalResponse.title}
        subtitle={modalResponse.subtitle}
        body={modalResponse.body}
        modalImage={modalResponse.modalImage}
        open={modalResponse.open}
        closeElement={modalResponse.closeElement}
        onCloseElement={handleResponseModal}
      ></ResponseModal>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  statusCodeRegister: state.invoiceReducer.saveRegister,
  validate: state.invoiceReducer.validateInformation,
  getTemplate: state.configReducer.getTemplate,
  stepChange: state.invoiceReducer.stepChange,
  statusGetBulk: state.invoiceReducer.statusGetBulk,
  countInvoice: state.dashboardReducer.getBillClient,
});

const mapDispatchToProps = {
  saveUploadedInvoiceAction,
  checkValidationsAction,
  getTemplateBulkUploadAction,
  cleanTemplateAction,
  changeStepAction,
  cleanValidationAction,
  getUploadedInvoiceAction,
  cleanStateGetBulkLoadAction,
  cleanStatusAction,
};
export default connect(mapStateToProps, mapDispatchToProps)(InfoUpload);
