import React, { useRef, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import SimpleReactValidator from "simple-react-validator";
import { reactValidatorOptions } from "../../helpers/simpleReactValidator";

import { Grid } from "@material-ui/core";
import InputForm from "../../components/input/input.component";
import SwitchForm from "../../components/switch/switch.component.js";
import SelectForm from "../../components/select/select.component";
import CustomAutoComplete from "../../components/autocomplete/autocomplete.component";
import ToggleButtons from "../../components/toggle/toggle.component";
import ButtonPrimary from "../../components/button/buttonPrimary.component";
import ResponseModal from "../../components/modal/responseModal.component";
import CustomProgress from "../../components/Progress/progress.component";
import TooltipMessage from "../../components/tooltip/tootltip-message.component";
import { CollapseDataPanel } from "../../components/ui/collapseDataPanel";
import { AdditionalDataCustomer } from "./additionalDataCustomer";

import { useFormComplete } from "../../hooks/useFormComplete";
import calculateDV from "../../utils/calculateDV";

import {
  getStatesAction,
  getDocumentTypesAction,
  getRegimeTypesAction,
  getAllCitiesAction,
  getTaxResponsibilityAction,
  getFiscalResponsibilityAction,
} from "../../actions/configAction";

import {
  getCustomerForForm,
  updateCustomerAction,
  saveCustomerAction,
  getCustomersAction,
  getCustomerDetailAction,
} from "../../actions/customerAction";

import {
  cleanDataContactAction,
  loadListContactsAction,
} from "../../actions/contactCustomerAction";

import {
  cleanDataAddressAction,
  loadListAddressesAction,
} from "../../actions/addressCustomerAction";

import { StartLoadModal } from "./bulkLoad/startLoadModal";
import { processDataCSV } from "./bulkLoad/helpers/processFile";
import { LoadRecordsModal } from "./bulkLoad/loadRecordsModal";
import { ResultLoadModal } from "./bulkLoad/resultLoadModal";
import MultiSelectForm from "../../components/multiselect/multiselect.component";

export const CustomerForm = React.memo(
  ({ handleCollapsePanel, loadForDocument = false, type = 0 }) => {
    const validator = useRef(new SimpleReactValidator(reactValidatorOptions))
      .current;

    const [, forceUpdate] = useState();
    const dispatch = useDispatch();
    const {
      values,
      handleInputChange,
      handleUpdateForm,
      handleCheckChange,
      handleAutocompleteChange,
      reset,
    } = useFormComplete({
      tipodocumento: "",
      numerodocumento: "",
      DV: "",
      tipopersona: "",
      nombre: "",
      tiporegimen: "",
      tiporesponsabilidadtributaria: "",
      fiscalResponsibility: [],
      email: "",
      telefono: "",
      direccion: "",
      departamento: "",
      municipio: "",
      favorito: false,
    });
    const {
      tipodocumento,
      numerodocumento,
      DV,
      tipopersona,
      nombre,
      tiporegimen,
      email,
      telefono,
      direccion,
      departamento,
      municipio,
      favorito,
      tiporesponsabilidadtributaria,
      fiscalResponsibility,
    } = values;
    const [stateCities, setStateCities] = useState([]);
    const [expandAdditionalPanel, setExpandAdditionalPanel] = useState(false);
    const [openBulkLoad, setOpenBulkLoad] = useState(false);
    const [openModalLoading, setOpenModalLoading] = useState(false);
    const [numberCustomerProcess, setNumberCustomerProcess] = useState(0);
    const [openResultLoad, setOpenResultLoad] = useState(false);
    const [numberCustomerSaved, setNUmberCustomerSaved] = useState(0);
    const [customerErrors, setCustomerErrors] = useState([]);
    const [recordsError, setRecordErrors] = useState(0);
    const [recordsOk, setRecordOk] = useState(0);
    const [multiDataSet, setMultiDataSet] = useState([]);
    const [modalResponse, setModalResponse] = useState({
      modalType: "",
      title: "",
      subtitle: "",
      body: "",
      modalImage: "",
      open: false,
      closeElement: "",
      onCloseElement: 0,
    });

    const {
      personTypeTitle,
      personTypeMessage,
      personTypeButton,
      personTypeHref,
    } = useSelector((state) => state.menuReducer.menuCustomer);
    const {
      getDocumentTypesResponse: typesDocument,
      getRegimeTypesResponse: typesRegime,
      getStatesResponse: statesList,
      allCitiesCountry: citiesList,
      getTaxResponsibility: taxResponsibilityList,
      getFiscalResponsibility: fiscalResponsibilityList,
    } = useSelector((state) => state.configReducer);
    const {
      customersList,
      customerActive,
      loadingActive,
      loadingSave,
    } = useSelector((state) => state.customerReducer);

    const { addresses } = useSelector((state) => state.addressCustomerReducer);
    const { contacts } = useSelector((state) => state.contactCustomerReducer);

    /**
     * Cargue Inicial
     */
    useEffect(() => {
      dispatch(getStatesAction());
      dispatch(getAllCitiesAction());
      dispatch(getDocumentTypesAction());
      dispatch(getRegimeTypesAction());
      dispatch(getTaxResponsibilityAction());
      dispatch(getFiscalResponsibilityAction());
    }, [dispatch]);

    /**
     * Carga informacion de cliente en formulario
     */
    useEffect(() => {
      if (!!customerActive) {
        loadDataInForm(customerActive);
      } else {
        reset();
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerActive]);

    /**
     * Sincroniza nuevo valor tipo documento con state
     * @param {*} param0
     */
    const handleChangeTypeDocument = ({ target }) => {
      const typeDocument = target.value;
      handleUpdateForm({
        tipodocumento: typeDocument,
        numerodocumento: "",
        DV: "",
        tipopersona: typeDocument !== "6" ? "2" : "1", // 2 persona natural
        // tiporegimen: typeDocument === "6" ? "4" : "3", // debe tener validacion para 4 0 3
      });
    };

    /**
     * Sincroniza valor numero documento con state
     * @param {*} e
     */
    const handleChangeDocument = (e) => {
      if (tipodocumento === "7") {
        handleInputChange(e, "alphanumeric");
      } else {
        handleInputChange(e, "number");
      }
    };

    const handleOnBlurDocument = ({ target }) => {
      let dv = 0;
      if (tipodocumento === "6" && target.value.length <= 15) {
        dv = calculateDV(target.value);
      }

      handleUpdateForm({
        DV: dv,
      });

      //Cargar cliente existente
      const existCustomer = customersList.find(
        (c) =>
          c?.tipodocumento?.toString() === tipodocumento &&
          c.documento === numerodocumento
      );
      if (!!existCustomer) {
        dispatch(getCustomerForForm(existCustomer.id));
      }
    };

    /**
     * Sincroniza tipo persona con state
     * @param {*} e
     */
    const handleChangeTypePerson = (e, value) => {
      handleUpdateForm({
        tipopersona: value,
      });
    };

    /**
     * Sincroniza nuevo valor "departamento" con state
     */
    const handleChangeState = (e, value, name) => {
      let newCities = [];
      if (!!value)
        newCities = citiesList.filter(
          (c) => c.departamentoid === parseInt(value.value)
        );

      handleUpdateForm({
        municipio: null,
        departamento: value,
      });
      setStateCities(newCities);
    };

    /**
     * Validadores depues de OnBlur en caja de texto
     * @param {object} e Evento
     * @param {string} name Nombre validador
     */
    const validateFieldsOnBlur = (name) => {
      if (validator.fieldValid(name) === false) {
        validator.showMessageFor(name);
        forceUpdate(Math.random());
      }
    };

    /**
     * Guarda o actualiza información de cliente
     * @param {*} e Evento
     */
    const handleSubmit = (e) => {
      e.preventDefault();

      if (!validator.allValid()) {
        validator.showMessages();
        forceUpdate(Math.random());
        return;
      }

      let direcciones = addresses.map((item) => ({
        Id: Number.isInteger(item.id) ? item.id : 0,
        Descripcion: item.descripcion,
        Direccion: item.direccion,
        CiudadId: item.ciudadid,
      }));

      let contactos = contacts.map((item) => ({
        Id: Number.isInteger(item.id) ? item.id : 0,
        Nombre: item.nombre,
        Telefono: item.telefono,
        Celular: item.telefono,
        Email: item.email,
        Activo: true,
      }));
      let dv = customerActive ? customerActive.documentodv : DV;
      let customer = {
        Id: !!customerActive ? customerActive.id : 0,
        Documento: !!customerActive
          ? customerActive.documento
          : numerodocumento,
        Documentodv: dv !== null && dv !== "" ? dv : "0",
        RazonSocial: nombre,
        Telefono: telefono,
        CorreoElectronico: email,
        DianTipoDocumentoIdentidadId: !!customerActive
          ? customerActive.diantipodocumentoidentidadid
          : parseInt(tipodocumento),
        DianTipoPersonaId: tipopersona === "1" ? 1 : 2,
        Diantiporegimenid: tiporegimen === "" ? 5 : tiporegimen,
        responsabilidadFiscal: fiscalResponsibility.join("") || 6,
        responsabilidadTributariaId: Number(tiporesponsabilidadtributaria) || 5,
        Favorito: favorito,
        Direccionid: !!customerActive ? customerActive.direccionid : 0,
        Direccion: direccion,
        CiudadId: parseInt(municipio?.value) || null,
        Adclientedireccion: direcciones,
        Adcontactos: contactos,
      };

      if (!!customerActive) {
        dispatch(
          updateCustomerAction(customer, completeSave, loadForDocument, type)
        );
      } else {
        dispatch(
          saveCustomerAction(customer, completeSave, loadForDocument, type)
        );
      }
    };

    const completeSave = () => {
      reset();
      handleCollapsePanel();
      dispatch(cleanDataContactAction());
      dispatch(cleanDataAddressAction());
      dispatch(getCustomerForForm(null));
      validator.hideMessages();
    };

    /**
     * Sincroniza componente multiple select con state
     * @param {*} event
     */
    const syncMultipleChange = (event) => {
      const options = event.target;
      const value = [];
      for (let index = 0; index < options.value.length; index++) {
        value.push(options.value[index]);
      }

      handleUpdateForm({
        [options.name]: value,
      });
    };

    /**
     * Carga información de usuario registrado en formulario
     */
    const loadDataInForm = (customer) => {
      let customercity = citiesList.find((c) => c.id === customer.ciudadid);
      let stateid = customercity?.departamentoid ?? 0;
      let state = statesList.find((s) => s.id === stateid);
      let cities = citiesList.filter((c) => c.departamentoid === stateid);

      setStateCities(cities);
      handleUpdateForm({
        tipodocumento: customer.diantipodocumentoidentidadid.toString(),
        numerodocumento: customer.documento,
        DV: customer.documentodv,
        tipopersona: customer.diantipopersonaid === 1 ? "1" : "2",
        nombre: customer.razonsocial,
        tiporegimen:
          customer.diantiporegimenid === 5 ? "" : customer.diantiporegimenid,
        email: customer.correoelectronico,
        telefono: customer.telefono,
        direccion: customer.direccion ?? "",
        municipio: customercity ?? null,
        departamento: state ?? null,
        favorito: customer.favorito,
        tiporesponsabilidadtributaria:
          customer.responsabilidadtributariaid === 5
            ? ""
            : customer.responsabilidadtributariaid,
        fiscalResponsibility:
          customer.responsabilidadfiscal === "6"
            ? []
            : customer.responsabilidadfiscal?.split("").map(Number) ?? [5],
      });

      const addressList = customer.adclientedireccion ?? [];
      const contactList = customer.adcontactos ?? [];
      setExpandAdditionalPanel(true);
      dispatch(loadListAddressesAction(addressList));
      dispatch(loadListContactsAction(contactList));
    };

    /**
     * Abre modal empieza cargue masivo
     */
    const handleStartBulkLoad = () => {
      setOpenBulkLoad(true);
    };

    /**
     * Cierra modal estado  valdacion cargue masivo
     */
    const handleCloseResponseModal = () => {
      setModalResponse({
        modalType: "",
        title: "",
        subtitle: "",
        body: "",
        modalImage: "",
        open: false,
        closeElement: "",
      });
    };

    /**
     * Muestra mensajes de error en cargue plantilla
     * @param {*} message
     */
    const handleShowErrors = (message) => {
      setModalResponse({
        type: "warning",
        title: "Upss...!!!",
        subtitle: "Ocurrió algo inesperado",
        body: <div>{message}</div>,
        modalImage: "warning",
        open: true,
        closeElement: "Saltar",
        onCloseElement: 0,
      });
    };

    /**
     * Abre o cierra modal procesamiento cargue masivo
     * @param {*} status Estado
     * @param {*} quantity Cantidad items a procesar
     */
    const handleOpenModalLoading = (status, quantity) => {
      setOpenModalLoading(status);
      setNumberCustomerProcess(quantity);
    };

    /**
     * Inicia procesamiento información, carue masivo
     * @param {*} data
     */
    const handleProcessData = (data) => {
      processDataCSV(
        data,
        handleShowErrors,
        setCustomerErrors,
        handleOpenModalLoading,
        handleOpenResultModal
      );
    };

    /**
     * Abre o cierra modal resultado cargue masivo
     * @param {*} status
     * @param {*} message
     */
    const handleOpenResultModal = (status, quantity, result = null) => {
      setOpenResultLoad(status);
      setNUmberCustomerSaved(quantity);
      setRecordErrors(result?.recordsError ?? 0);
      setRecordOk(result?.recordsOk ?? 0);
      setMultiDataSet(result?.multiDataSet ?? []);

      dispatch(getCustomersAction(true, type, true));
      dispatch(getCustomerDetailAction(null));
      handleCollapsePanel(false);
    };

    const handleCloseResultModal = () => {
      setOpenResultLoad(false);
    };

    let fiscalResponsibilityOption = [];
    if (!!fiscalResponsibilityList) {
      fiscalResponsibilityOption = fiscalResponsibilityList
        .filter((item) => item.codigo !== "O-48" && item.codigo !== "O-49")
        .map((item) => {
          return {
            ...item,
            value: item.id,
            text: `${item.codigo} ${item.descripcion}`,
          };
        });
    }

    return (
      <>
        <form
          noValidate
          autoComplete="off"
          id={"customer-form"}
          onSubmit={handleSubmit}
        >
          <Grid container spacing={3}>
            <Grid item lg={4} xs={12}>
              <SelectForm
                label={"Tipo de documento *"}
                name="tipodocumento"
                value={tipodocumento}
                options={typesDocument}
                disabled={loadingActive || !!customerActive}
                validator={validator}
                validateOptions={"required"}
                nameValidator={"tipoDocumento"}
                onChange={handleChangeTypeDocument}
              />
            </Grid>

            <Grid item lg={tipodocumento === "6" ? 4 : 8} xs={12}>
              <Grid container>
                <Grid item lg={11} xs={11}>
                  <InputForm
                    label={"Número de documento *"}
                    name={"numerodocumento"}
                    maxLength={tipodocumento === "6" ? 15 : 20}
                    value={numerodocumento}
                    disabled={
                      tipodocumento === "" || loadingActive || !!customerActive
                    }
                    onChange={handleChangeDocument}
                    nameValidator={"Número Documento"}
                    onBlur={(e) => {
                      handleOnBlurDocument(e);
                      validateFieldsOnBlur("Número Documento");
                    }}
                    validator={validator}
                    validateOptions={`required|min:3|${
                      tipodocumento === "6" ? "max:15" : "max:20"
                    }`}
                  />
                </Grid>
                <Grid item lg={1} xs={1}>
                  {loadingActive && (
                    <div style={{ padding: "20% 0%" }}>
                      <CustomProgress size={20} />
                    </div>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {tipodocumento === "6" && (
              <Grid item lg={4} xs={12}>
                <InputForm
                  name={"DV"}
                  label={"Dígito de verificación"}
                  maxLength={1}
                  value={DV}
                  disabled={true}
                  onChange={handleInputChange}
                />
              </Grid>
            )}
          </Grid>

          <Grid container spacing={3}>
            {tipodocumento === "6" && (
              <Grid item lg={4} xs={12}>
                <ToggleButtons
                  label={"Tipo de persona"}
                  name={"tipopersona"}
                  value={tipopersona}
                  titleOne={"Jurídica"}
                  titleTwo={"Natural"}
                  onChange={handleChangeTypePerson}
                  txtTooltip={
                    <TooltipMessage
                      title={personTypeTitle}
                      message={personTypeMessage}
                      botton={personTypeButton}
                      href={personTypeHref}
                    />
                  }
                />
              </Grid>
            )}
            <Grid item lg={tipodocumento === "6" ? 8 : 12} xs={12}>
              <InputForm
                name={"nombre"}
                label={
                  tipopersona === "1" ? "Razón Social *" : "Nombre y Apellido *"
                }
                maxLength={450}
                value={nombre}
                disabled={loadingActive}
                onChange={handleInputChange}
                nameValidator={"Nombre Cliente"}
                onBlur={(e) => validateFieldsOnBlur("Nombre Cliente")}
                validator={validator}
                validateOptions={"required|min:3|max:450"}
              />
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item lg={4} xs={12}>
              <SelectForm
                label={"Régimen"}
                name="tiporegimen"
                value={tiporegimen}
                options={typesRegime}
                disabled={loadingActive}
                // validator={validator}
                // validateOptions={"required"}
                // nameValidator={"tipoRegimen"}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SelectForm
                label={"Responsabilidad Tributaria"}
                name="tiporesponsabilidadtributaria"
                value={tiporesponsabilidadtributaria}
                options={taxResponsibilityList}
                disabled={loadingActive}
                // validator={validator}
                // validateOptions={"required"}
                // nameValidator={"tiporesponsabilidadtributaria"}
                onChange={handleInputChange}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <MultiSelectForm
                label={"Responsabilidad Fiscal"}
                name="fiscalResponsibility"
                value={fiscalResponsibility}
                options={fiscalResponsibilityOption}
                onChange={syncMultipleChange}
                // validator={validator}
                // validateOptions={"required"}
                // nameValidator={"fiscalResponsibility"}
                renderValue={(selected) =>
                  fiscalResponsibility
                    .map((value) => {
                      let name = fiscalResponsibilityOption.filter(
                        (fiscal) => fiscal.id === value
                      );
                      return name[0]?.descripcion;
                    })
                    .join(",")
                }
                placeholder={"Seleccione..."}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputForm
                name={"email"}
                label={"Correo electrónico *"}
                maxLength={200}
                value={email}
                disabled={loadingActive}
                onChange={(e) => handleInputChange(e, "email")}
                nameValidator={"correoElectronico"}
                onBlur={(e) => validateFieldsOnBlur("correoElectronico")}
                validator={validator}
                validateOptions={"required|email|min:10|max:200"}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputForm
                name={"telefono"}
                label={"Teléfono"}
                maxLength={15}
                value={telefono}
                onChange={(e) => handleInputChange(e, "phone")}
                disabled={loadingActive}
                nameValidator={"teléfono"}
                onBlur={() => validateFieldsOnBlur("teléfono")}
                validator={validator}
                validateOptions={"min:7|max:15"}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <InputForm
                name={"direccion"}
                label={"Dirección"}
                maxLength={100}
                value={direccion}
                disabled={loadingActive}
                onChange={handleInputChange}
                nameValidator={"dirección"}
                onBlur={() => validateFieldsOnBlur("dirección")}
                // validator={validator}
                // validateOptions={"required|min:3|max:100"}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <CustomAutoComplete
                name="departamento"
                label="Departamento"
                value={departamento}
                options={statesList}
                disabled={loadingActive}
                textNoOption="No se encontraron resultados."
                onChange={handleChangeState}
                // validator={validator}
                // validateOptions={"required"}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <CustomAutoComplete
                name="municipio"
                label="Municipio"
                value={municipio}
                disabled={loadingActive}
                options={stateCities}
                textNoOption="No se encontraron resultados."
                onChange={handleAutocompleteChange}
                // nameValidator={"municipio"}
                // validator={validator}
                // validateOptions={"required"}
              />
            </Grid>
            <Grid item lg={4} xs={12}>
              <SwitchForm
                name="favorito"
                titleOn="Marcar como favorito"
                checked={favorito}
                disabled={loadingActive}
                onChange={handleCheckChange}
              />
            </Grid>
          </Grid>

          <Grid container style={{ margin: "20px auto" }}>
            <Grid item lg={12} xs={12}>
              <CollapseDataPanel
                component={<AdditionalDataCustomer />}
                open={expandAdditionalPanel}
                backgroundColor={"#4A4A4A"}
              />
            </Grid>
          </Grid>

          <Grid container justify={"space-between"}>
            <Grid item lg={4} xs={12}>
              <ButtonPrimary
                text={"Cargue Masivo"}
                type={"button"}
                onClick={handleStartBulkLoad}
                disabled={loadingActive || loadingSave}
              />
            </Grid>
            <Grid item lg={4} md={4} sm={12} xs={12}>
              <ButtonPrimary
                text={"Listo"}
                type={"submit"}
                loading={loadingSave}
                form={"customer-form"}
                disabled={loadingActive || loadingSave}
              />
            </Grid>
          </Grid>
        </form>
        <StartLoadModal
          open={openBulkLoad}
          handleClose={() => setOpenBulkLoad(false)}
          handleShowErrors={handleShowErrors}
          handleProcessData={handleProcessData}
        />
        <LoadRecordsModal
          open={openModalLoading}
          quantityProcess={numberCustomerProcess}
        />
        <ResultLoadModal
          open={openResultLoad}
          handleClose={handleCloseResultModal}
          errorList={customerErrors}
          recordsError={recordsError}
          recordsOk={recordsOk}
          multiDataSet={multiDataSet}
          messageLoadingBulkLoad={numberCustomerProcess}
          quantityCustomer={numberCustomerSaved}
        />
        <ResponseModal
          modalType={modalResponse.modalType}
          title={modalResponse.title}
          subtitle={modalResponse.subtitle}
          body={modalResponse.body}
          modalImage={modalResponse.modalImage}
          open={modalResponse.open}
          closeElement={modalResponse.closeElement}
          onCloseElement={handleCloseResponseModal}
        />
      </>
    );
  }
);
